var __hasOwnProp = Object.prototype.hasOwnProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __assign = Object.assign;
var __rest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};

// src/Popover/PopoverArrow.tsx
import {
  createElement as createElement7,
  useMemo as useMemo8
} from "react";

// ../../../node_modules/reakit-system/es/createComponent.js
import {forwardRef as forwardRef$1, memo as memo$1} from "react";

// ../../../node_modules/reakit-system/es/SystemContext.js
import {createContext} from "react";
var SystemContext = /* @__PURE__ */ createContext({});

// ../../../node_modules/reakit-system/es/_rollupPluginBabelHelpers-0c84a174.js
function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }
  return obj;
}
function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);
  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly)
      symbols = symbols.filter(function(sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread2(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    if (i % 2) {
      ownKeys(Object(source), true).forEach(function(key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function(key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }
  return target;
}
function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null)
    return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;
  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0)
      continue;
    target[key] = source[key];
  }
  return target;
}
function _unsupportedIterableToArray(o, minLen) {
  if (!o)
    return;
  if (typeof o === "string")
    return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor)
    n = o.constructor.name;
  if (n === "Map" || n === "Set")
    return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n))
    return _arrayLikeToArray(o, minLen);
}
function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length)
    len = arr.length;
  for (var i = 0, arr2 = new Array(len); i < len; i++)
    arr2[i] = arr[i];
  return arr2;
}
function _createForOfIteratorHelperLoose(o, allowArrayLike) {
  var it;
  if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) {
    if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") {
      if (it)
        o = it;
      var i = 0;
      return function() {
        if (i >= o.length)
          return {
            done: true
          };
        return {
          done: false,
          value: o[i++]
        };
      };
    }
    throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
  }
  it = o[Symbol.iterator]();
  return it.next.bind(it);
}

// ../../../node_modules/reakit-system/es/useCreateElement.js
import {useContext, createElement} from "react";
function isRenderProp(children) {
  return typeof children === "function";
}
var useCreateElement = function useCreateElement2(type, props, children) {
  if (children === void 0) {
    children = props.children;
  }
  var context = useContext(SystemContext);
  if (context.useCreateElement) {
    return context.useCreateElement(type, props, children);
  }
  if (typeof type === "string" && isRenderProp(children)) {
    var _ = props.children, rest = _objectWithoutPropertiesLoose(props, ["children"]);
    return children(rest);
  }
  return /* @__PURE__ */ createElement(type, props, children);
};

// ../../../node_modules/reakit-utils/es/_rollupPluginBabelHelpers-1f0bf8c2.js
function _defineProperty2(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }
  return obj;
}
function ownKeys2(object, enumerableOnly) {
  var keys = Object.keys(object);
  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly)
      symbols = symbols.filter(function(sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread22(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    if (i % 2) {
      ownKeys2(Object(source), true).forEach(function(key) {
        _defineProperty2(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys2(Object(source)).forEach(function(key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }
  return target;
}
function _objectWithoutPropertiesLoose2(source, excluded) {
  if (source == null)
    return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;
  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0)
      continue;
    target[key] = source[key];
  }
  return target;
}
function _unsupportedIterableToArray2(o, minLen) {
  if (!o)
    return;
  if (typeof o === "string")
    return _arrayLikeToArray2(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor)
    n = o.constructor.name;
  if (n === "Map" || n === "Set")
    return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n))
    return _arrayLikeToArray2(o, minLen);
}
function _arrayLikeToArray2(arr, len) {
  if (len == null || len > arr.length)
    len = arr.length;
  for (var i = 0, arr2 = new Array(len); i < len; i++)
    arr2[i] = arr[i];
  return arr2;
}
function _createForOfIteratorHelperLoose2(o, allowArrayLike) {
  var it;
  if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) {
    if (Array.isArray(o) || (it = _unsupportedIterableToArray2(o)) || allowArrayLike && o && typeof o.length === "number") {
      if (it)
        o = it;
      var i = 0;
      return function() {
        if (i >= o.length)
          return {
            done: true
          };
        return {
          done: false,
          value: o[i++]
        };
      };
    }
    throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
  }
  it = o[Symbol.iterator]();
  return it.next.bind(it);
}

// ../../../node_modules/reakit-utils/es/isObject.js
function isObject(arg) {
  return typeof arg === "object" && arg != null;
}

// ../../../node_modules/reakit-utils/es/isPlainObject.js
function isPlainObject(arg) {
  var _proto$constructor;
  if (!isObject(arg))
    return false;
  var proto = Object.getPrototypeOf(arg);
  if (proto == null)
    return true;
  return ((_proto$constructor = proto.constructor) === null || _proto$constructor === void 0 ? void 0 : _proto$constructor.toString()) === Object.toString();
}

// ../../../node_modules/reakit-utils/es/splitProps.js
function __deprecatedSplitProps(props, keys) {
  var propsKeys = Object.keys(props);
  var picked = {};
  var omitted = {};
  for (var _i = 0, _propsKeys = propsKeys; _i < _propsKeys.length; _i++) {
    var key = _propsKeys[_i];
    if (keys.indexOf(key) >= 0) {
      picked[key] = props[key];
    } else {
      omitted[key] = props[key];
    }
  }
  return [picked, omitted];
}
function splitProps(props, keys) {
  if (keys === void 0) {
    keys = [];
  }
  if (!isPlainObject(props.state)) {
    return __deprecatedSplitProps(props, keys);
  }
  var _deprecatedSplitProp = __deprecatedSplitProps(props, [].concat(keys, ["state"])), picked = _deprecatedSplitProp[0], omitted = _deprecatedSplitProp[1];
  var state = picked.state, restPicked = _objectWithoutPropertiesLoose2(picked, ["state"]);
  return [_objectSpread22(_objectSpread22({}, state), restPicked), omitted];
}

// ../../../node_modules/reakit-utils/es/shallowEqual.js
function shallowEqual(objA, objB) {
  if (objA === objB)
    return true;
  if (!objA)
    return false;
  if (!objB)
    return false;
  if (typeof objA !== "object")
    return false;
  if (typeof objB !== "object")
    return false;
  var aKeys = Object.keys(objA);
  var bKeys = Object.keys(objB);
  var length = aKeys.length;
  if (bKeys.length !== length)
    return false;
  for (var _i = 0, _aKeys = aKeys; _i < _aKeys.length; _i++) {
    var key = _aKeys[_i];
    if (objA[key] !== objB[key]) {
      return false;
    }
  }
  return true;
}

// ../../../node_modules/reakit-utils/es/normalizePropsAreEqual.js
function normalizePropsAreEqual(propsAreEqual9) {
  if (propsAreEqual9.name === "normalizePropsAreEqualInner") {
    return propsAreEqual9;
  }
  return function normalizePropsAreEqualInner(prev, next) {
    if (!isPlainObject(prev.state) || !isPlainObject(next.state)) {
      return propsAreEqual9(prev, next);
    }
    return propsAreEqual9(_objectSpread22(_objectSpread22({}, prev.state), prev), _objectSpread22(_objectSpread22({}, next.state), next));
  };
}

// ../../../node_modules/reakit-system/es/createComponent.js
function forwardRef(component) {
  return /* @__PURE__ */ forwardRef$1(component);
}
function memo(component, propsAreEqual9) {
  return /* @__PURE__ */ memo$1(component, propsAreEqual9);
}
function createComponent(_ref) {
  var type = _ref.as, useHook = _ref.useHook, shouldMemo = _ref.memo, _ref$propsAreEqual = _ref.propsAreEqual, propsAreEqual9 = _ref$propsAreEqual === void 0 ? useHook === null || useHook === void 0 ? void 0 : useHook.unstable_propsAreEqual : _ref$propsAreEqual, _ref$keys = _ref.keys, keys = _ref$keys === void 0 ? (useHook === null || useHook === void 0 ? void 0 : useHook.__keys) || [] : _ref$keys, _ref$useCreateElement = _ref.useCreateElement, useCreateElement$17 = _ref$useCreateElement === void 0 ? useCreateElement : _ref$useCreateElement;
  var Comp = function Comp2(_ref2, ref) {
    var _ref2$as = _ref2.as, as = _ref2$as === void 0 ? type : _ref2$as, props = _objectWithoutPropertiesLoose(_ref2, ["as"]);
    if (useHook) {
      var _as$render;
      var _splitProps = splitProps(props, keys), _options = _splitProps[0], htmlProps = _splitProps[1];
      var _useHook = useHook(_options, _objectSpread2({
        ref
      }, htmlProps)), wrapElement = _useHook.wrapElement, elementProps = _objectWithoutPropertiesLoose(_useHook, ["wrapElement"]);
      var asKeys = ((_as$render = as.render) === null || _as$render === void 0 ? void 0 : _as$render.__keys) || as.__keys;
      var asOptions = asKeys && splitProps(props, asKeys)[0];
      var allProps = asOptions ? _objectSpread2(_objectSpread2({}, elementProps), asOptions) : elementProps;
      var _element = useCreateElement$17(as, allProps);
      if (wrapElement) {
        return wrapElement(_element);
      }
      return _element;
    }
    return useCreateElement$17(as, _objectSpread2({
      ref
    }, props));
  };
  if (useHook) {
    Comp.displayName = useHook.name.replace(/^(unstable_)?use/, "");
  }
  Comp = forwardRef(Comp);
  if (shouldMemo) {
    Comp = memo(Comp, propsAreEqual9 && normalizePropsAreEqual(propsAreEqual9));
  }
  Comp.__keys = keys;
  Comp.unstable_propsAreEqual = normalizePropsAreEqual(propsAreEqual9 || shallowEqual);
  return Comp;
}

// ../../../node_modules/reakit-system/es/createHook.js
import "react";

// ../../../node_modules/reakit-system/es/useToken.js
import {useDebugValue, useContext as useContext2} from "react";
function useToken(token, defaultValue) {
  useDebugValue(token);
  var context = useContext2(SystemContext);
  return context[token] != null ? context[token] : defaultValue;
}

// ../../../node_modules/reakit-system/es/useProps.js
import {useDebugValue as useDebugValue2} from "react";
function useProps(name, options, htmlProps) {
  if (options === void 0) {
    options = {};
  }
  if (htmlProps === void 0) {
    htmlProps = {};
  }
  var hookName = "use" + name + "Props";
  useDebugValue2(hookName);
  var useHook = useToken(hookName);
  if (useHook) {
    return useHook(options, htmlProps);
  }
  return htmlProps;
}

// ../../../node_modules/reakit-system/es/useOptions.js
import {useDebugValue as useDebugValue3} from "react";
function useOptions(name, options, htmlProps) {
  if (options === void 0) {
    options = {};
  }
  if (htmlProps === void 0) {
    htmlProps = {};
  }
  var hookName = "use" + name + "Options";
  useDebugValue3(hookName);
  var useHook = useToken(hookName);
  if (useHook) {
    return _objectSpread2(_objectSpread2({}, options), useHook(options, htmlProps));
  }
  return options;
}

// ../../../node_modules/reakit-utils/es/toArray.js
function toArray(arg) {
  if (Array.isArray(arg)) {
    return arg;
  }
  return typeof arg !== "undefined" ? [arg] : [];
}

// ../../../node_modules/reakit-system/es/createHook.js
function createHook(options) {
  var _options$useState, _composedHooks$;
  var composedHooks = toArray(options.compose);
  var __useOptions = function __useOptions2(hookOptions, htmlProps) {
    if (options.useOptions) {
      hookOptions = options.useOptions(hookOptions, htmlProps);
    }
    if (options.name) {
      hookOptions = useOptions(options.name, hookOptions, htmlProps);
    }
    if (options.compose) {
      for (var _iterator = _createForOfIteratorHelperLoose(composedHooks), _step; !(_step = _iterator()).done; ) {
        var hook = _step.value;
        hookOptions = hook.__useOptions(hookOptions, htmlProps);
      }
    }
    return hookOptions;
  };
  var useHook = function useHook2(hookOptions, htmlProps, unstable_ignoreUseOptions) {
    if (hookOptions === void 0) {
      hookOptions = {};
    }
    if (htmlProps === void 0) {
      htmlProps = {};
    }
    if (unstable_ignoreUseOptions === void 0) {
      unstable_ignoreUseOptions = false;
    }
    if (!unstable_ignoreUseOptions) {
      hookOptions = __useOptions(hookOptions, htmlProps);
    }
    if (options.useProps) {
      htmlProps = options.useProps(hookOptions, htmlProps);
    }
    if (options.name) {
      htmlProps = useProps(options.name, hookOptions, htmlProps);
    }
    if (options.compose) {
      if (options.useComposeOptions) {
        hookOptions = options.useComposeOptions(hookOptions, htmlProps);
      }
      if (options.useComposeProps) {
        htmlProps = options.useComposeProps(hookOptions, htmlProps);
      } else {
        for (var _iterator2 = _createForOfIteratorHelperLoose(composedHooks), _step2; !(_step2 = _iterator2()).done; ) {
          var hook = _step2.value;
          htmlProps = hook(hookOptions, htmlProps, true);
        }
      }
    }
    var finalHTMLProps = {};
    var definedHTMLProps = htmlProps || {};
    for (var prop in definedHTMLProps) {
      if (definedHTMLProps[prop] !== void 0) {
        finalHTMLProps[prop] = definedHTMLProps[prop];
      }
    }
    return finalHTMLProps;
  };
  useHook.__useOptions = __useOptions;
  var composedKeys = composedHooks.reduce(function(keys, hook) {
    keys.push.apply(keys, hook.__keys || []);
    return keys;
  }, []);
  useHook.__keys = [].concat(composedKeys, ((_options$useState = options.useState) === null || _options$useState === void 0 ? void 0 : _options$useState.__keys) || [], options.keys || []);
  useHook.unstable_propsAreEqual = options.propsAreEqual || ((_composedHooks$ = composedHooks[0]) === null || _composedHooks$ === void 0 ? void 0 : _composedHooks$.unstable_propsAreEqual) || shallowEqual;
  if (options.name) {
    Object.defineProperty(useHook, "name", {
      value: "use" + options.name
    });
  }
  return useHook;
}

// ../../../node_modules/reakit-utils/es/useForkRef.js
import {useMemo} from "react";
function setRef(ref, value) {
  if (value === void 0) {
    value = null;
  }
  if (!ref)
    return;
  if (typeof ref === "function") {
    ref(value);
  } else {
    ref.current = value;
  }
}
function useForkRef(refA, refB) {
  return useMemo(function() {
    if (refA == null && refB == null) {
      return null;
    }
    return function(value) {
      setRef(refA, value);
      setRef(refB, value);
    };
  }, [refA, refB]);
}

// ../../../node_modules/reakit/es/_rollupPluginBabelHelpers-1f0bf8c2.js
function _defineProperty3(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }
  return obj;
}
function ownKeys3(object, enumerableOnly) {
  var keys = Object.keys(object);
  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly)
      symbols = symbols.filter(function(sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread23(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    if (i % 2) {
      ownKeys3(Object(source), true).forEach(function(key) {
        _defineProperty3(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys3(Object(source)).forEach(function(key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }
  return target;
}
function _objectWithoutPropertiesLoose3(source, excluded) {
  if (source == null)
    return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;
  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0)
      continue;
    target[key] = source[key];
  }
  return target;
}
function _unsupportedIterableToArray3(o, minLen) {
  if (!o)
    return;
  if (typeof o === "string")
    return _arrayLikeToArray3(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor)
    n = o.constructor.name;
  if (n === "Map" || n === "Set")
    return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n))
    return _arrayLikeToArray3(o, minLen);
}
function _arrayLikeToArray3(arr, len) {
  if (len == null || len > arr.length)
    len = arr.length;
  for (var i = 0, arr2 = new Array(len); i < len; i++)
    arr2[i] = arr[i];
  return arr2;
}
function _createForOfIteratorHelperLoose3(o, allowArrayLike) {
  var it;
  if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) {
    if (Array.isArray(o) || (it = _unsupportedIterableToArray3(o)) || allowArrayLike && o && typeof o.length === "number") {
      if (it)
        o = it;
      var i = 0;
      return function() {
        if (i >= o.length)
          return {
            done: true
          };
        return {
          done: false,
          value: o[i++]
        };
      };
    }
    throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
  }
  it = o[Symbol.iterator]();
  return it.next.bind(it);
}

// ../../../node_modules/reakit/es/Box/Box.js
var BOX_KEYS = ["unstable_system"];
var useBox = createHook({
  name: "Box",
  keys: BOX_KEYS,
  propsAreEqual: function propsAreEqual(prev, next) {
    var prevSystem = prev.unstable_system, prevProps = _objectWithoutPropertiesLoose3(prev, ["unstable_system"]);
    var nextSystem = next.unstable_system, nextProps = _objectWithoutPropertiesLoose3(next, ["unstable_system"]);
    if (prevSystem !== nextSystem && !shallowEqual(prevSystem, nextSystem)) {
      return false;
    }
    return shallowEqual(prevProps, nextProps);
  }
});
var Box = createComponent({
  as: "div",
  useHook: useBox
});

// ../../../node_modules/reakit-utils/es/isButton.js
var buttonInputTypes = ["button", "color", "file", "image", "reset", "submit"];
function isButton(element) {
  if (element.tagName === "BUTTON")
    return true;
  if (element.tagName === "INPUT") {
    var input = element;
    return buttonInputTypes.indexOf(input.type) !== -1;
  }
  return false;
}

// ../../../node_modules/reakit-utils/es/flatten.js
function flatten(array) {
  var flat = [];
  for (var _iterator = _createForOfIteratorHelperLoose2(array), _step; !(_step = _iterator()).done; ) {
    var maybeArray = _step.value;
    if (Array.isArray(maybeArray)) {
      flat.push.apply(flat, flatten(maybeArray));
    } else {
      flat.push(maybeArray);
    }
  }
  return flat;
}

// ../../../node_modules/reakit-warning/es/warning.js
function warning(condition) {
  if (true) {
    var _console;
    if (!condition)
      return;
    for (var _len = arguments.length, messages = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      messages[_key - 1] = arguments[_key];
    }
    var warns = flatten(messages.map(function(message) {
      return [message, "\n"];
    }));
    (_console = console).warn.apply(_console, warns.slice(0, -1));
    try {
      throw Error(warns.join(""));
    } catch (x) {
    }
  }
}

// ../../../node_modules/reakit-warning/es/index.js
import "react";

// ../../../node_modules/reakit-warning/es/useWarning.js
import {useEffect} from "react";
function isRefObject(ref) {
  return isObject(ref) && "current" in ref;
}
function useWarning(condition) {
  for (var _len = arguments.length, messages = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    messages[_key - 1] = arguments[_key];
  }
  if (true) {
    useEffect(function() {
      warning.apply(void 0, [condition].concat(messages.map(function(message) {
        return isRefObject(message) ? message.current : message;
      })));
    }, [condition]);
  }
}

// ../../../node_modules/reakit-utils/es/useLiveRef.js
import {useRef} from "react";

// ../../../node_modules/reakit-utils/es/getDocument.js
function getDocument(element) {
  return element ? element.ownerDocument || element : document;
}

// ../../../node_modules/reakit-utils/es/getWindow.js
var _window;
try {
  _window = window;
} catch (e) {
}
function getWindow(element) {
  if (!element) {
    return _window;
  }
  return getDocument(element).defaultView || _window;
}

// ../../../node_modules/reakit-utils/es/canUseDOM.js
function checkIsBrowser() {
  var _window2 = getWindow();
  return Boolean(typeof _window2 !== "undefined" && _window2.document && _window2.document.createElement);
}
var canUseDOM = checkIsBrowser();

// ../../../node_modules/reakit-utils/es/useIsomorphicEffect.js
import {useEffect as useEffect2, useLayoutEffect} from "react";
var useIsomorphicEffect = !canUseDOM ? useEffect2 : useLayoutEffect;

// ../../../node_modules/reakit-utils/es/useLiveRef.js
function useLiveRef(value) {
  var ref = useRef(value);
  useIsomorphicEffect(function() {
    ref.current = value;
  });
  return ref;
}

// ../../../node_modules/reakit-utils/es/isSelfTarget.js
function isSelfTarget(event) {
  return event.target === event.currentTarget;
}

// ../../../node_modules/reakit-utils/es/getActiveElement.js
function getActiveElement(element) {
  var _getDocument = getDocument(element), activeElement = _getDocument.activeElement;
  if (!(activeElement !== null && activeElement !== void 0 && activeElement.nodeName)) {
    return null;
  }
  return activeElement;
}

// ../../../node_modules/reakit-utils/es/contains.js
function contains(parent, child) {
  return parent === child || parent.contains(child);
}

// ../../../node_modules/reakit-utils/es/hasFocusWithin.js
function hasFocusWithin(element) {
  var activeElement = getActiveElement(element);
  if (!activeElement)
    return false;
  if (contains(element, activeElement))
    return true;
  var activeDescendant = activeElement.getAttribute("aria-activedescendant");
  if (!activeDescendant)
    return false;
  if (activeDescendant === element.id)
    return true;
  return !!element.querySelector("#" + activeDescendant);
}

// ../../../node_modules/reakit-utils/es/isPortalEvent.js
function isPortalEvent(event) {
  return !contains(event.currentTarget, event.target);
}

// ../../../node_modules/reakit-utils/es/dom.js
function isUA(string) {
  if (!canUseDOM)
    return false;
  return window.navigator.userAgent.indexOf(string) !== -1;
}

// ../../../node_modules/reakit-utils/es/matches.js
function matches(element, selectors) {
  if ("matches" in element) {
    return element.matches(selectors);
  }
  if ("msMatchesSelector" in element) {
    return element.msMatchesSelector(selectors);
  }
  return element.webkitMatchesSelector(selectors);
}

// ../../../node_modules/reakit-utils/es/closest.js
function closest(element, selectors) {
  if ("closest" in element)
    return element.closest(selectors);
  do {
    if (matches(element, selectors))
      return element;
    element = element.parentElement || element.parentNode;
  } while (element !== null && element.nodeType === 1);
  return null;
}

// ../../../node_modules/reakit-utils/es/tabbable.js
var selector = "input:not([type='hidden']):not([disabled]), select:not([disabled]), textarea:not([disabled]), a[href], button:not([disabled]), [tabindex], iframe, object, embed, area[href], audio[controls], video[controls], [contenteditable]:not([contenteditable='false'])";
function isVisible(element) {
  var htmlElement = element;
  return htmlElement.offsetWidth > 0 || htmlElement.offsetHeight > 0 || element.getClientRects().length > 0;
}
function hasNegativeTabIndex(element) {
  var tabIndex = parseInt(element.getAttribute("tabindex") || "0", 10);
  return tabIndex < 0;
}
function isFocusable(element) {
  return matches(element, selector) && isVisible(element);
}
function isTabbable(element) {
  return isFocusable(element) && !hasNegativeTabIndex(element);
}
function getAllTabbableIn(container, fallbackToFocusable) {
  var allFocusable = Array.from(container.querySelectorAll(selector));
  var allTabbable = allFocusable.filter(isTabbable);
  if (isTabbable(container)) {
    allTabbable.unshift(container);
  }
  if (!allTabbable.length && fallbackToFocusable) {
    return allFocusable;
  }
  return allTabbable;
}
function getFirstTabbableIn(container, fallbackToFocusable) {
  var _getAllTabbableIn = getAllTabbableIn(container, fallbackToFocusable), first = _getAllTabbableIn[0];
  return first || null;
}
function getLastTabbableIn(container, fallbackToFocusable) {
  var allTabbable = getAllTabbableIn(container, fallbackToFocusable);
  return allTabbable[allTabbable.length - 1] || null;
}
function getClosestFocusable(element) {
  while (element && !isFocusable(element)) {
    element = closest(element, selector);
  }
  return element;
}

// ../../../node_modules/reakit/es/Role/Role.js
var ROLE_KEYS = ["unstable_system"];
var useRole = createHook({
  name: "Role",
  keys: ROLE_KEYS,
  propsAreEqual: function propsAreEqual2(prev, next) {
    var prevSystem = prev.unstable_system, prevProps = _objectWithoutPropertiesLoose3(prev, ["unstable_system"]);
    var nextSystem = next.unstable_system, nextProps = _objectWithoutPropertiesLoose3(next, ["unstable_system"]);
    if (prevSystem !== nextSystem && !shallowEqual(prevSystem, nextSystem)) {
      return false;
    }
    return shallowEqual(prevProps, nextProps);
  }
});
var Role = createComponent({
  as: "div",
  useHook: useRole
});

// ../../../node_modules/reakit/es/Tabbable/Tabbable.js
import {useRef as useRef2, useState, useCallback, useEffect as useEffect3} from "react";
var TABBABLE_KEYS = ["disabled", "focusable"];
var isSafariOrFirefoxOnMac = isUA("Mac") && !isUA("Chrome") && (isUA("Safari") || isUA("Firefox"));
function focusIfNeeded(element) {
  if (!hasFocusWithin(element)) {
    element.focus();
  }
}
function useFocusOnMouseDown() {
  if (!isSafariOrFirefoxOnMac)
    return void 0;
  var _React$useState = useState(null), tabbable = _React$useState[0], scheduleFocus = _React$useState[1];
  useEffect3(function() {
    if (!tabbable)
      return;
    focusIfNeeded(tabbable);
    scheduleFocus(null);
  }, [tabbable]);
  var onMouseDown = useCallback(function(event) {
    var element = event.currentTarget;
    if (isPortalEvent(event))
      return;
    if (!isButton(element))
      return;
    var activeElement = getActiveElement(element);
    if (!activeElement)
      return;
    var activeElementIsBody = activeElement.tagName === "BODY";
    var focusableAncestor = getClosestFocusable(element.parentElement);
    if (activeElement === focusableAncestor || activeElementIsBody && !focusableAncestor) {
      scheduleFocus(element);
    } else if (focusableAncestor) {
      var onFocus = function onFocus2() {
        return scheduleFocus(element);
      };
      focusableAncestor.addEventListener("focusin", onFocus, {
        once: true
      });
    } else {
      var onBlur = function onBlur2() {
        return focusIfNeeded(element);
      };
      activeElement.addEventListener("blur", onBlur, {
        once: true
      });
    }
  }, []);
  return onMouseDown;
}
function isNativeTabbable(element) {
  return element.tagName === "BUTTON" || element.tagName === "INPUT" || element.tagName === "SELECT" || element.tagName === "TEXTAREA" || element.tagName === "A";
}
function supportsDisabledAttribute(element) {
  return element.tagName === "BUTTON" || element.tagName === "INPUT" || element.tagName === "SELECT" || element.tagName === "TEXTAREA";
}
function getTabIndex(trulyDisabled, nativeTabbable, supportsDisabled, htmlTabIndex) {
  if (trulyDisabled) {
    if (nativeTabbable && !supportsDisabled) {
      return -1;
    }
    return void 0;
  }
  if (nativeTabbable) {
    return htmlTabIndex;
  }
  return htmlTabIndex || 0;
}
function useDisableEvent(htmlEventRef, disabled) {
  return useCallback(function(event) {
    var _htmlEventRef$current;
    (_htmlEventRef$current = htmlEventRef.current) === null || _htmlEventRef$current === void 0 ? void 0 : _htmlEventRef$current.call(htmlEventRef, event);
    if (event.defaultPrevented)
      return;
    if (disabled) {
      event.stopPropagation();
      event.preventDefault();
    }
  }, [htmlEventRef, disabled]);
}
var useTabbable = createHook({
  name: "Tabbable",
  compose: useRole,
  keys: TABBABLE_KEYS,
  useOptions: function useOptions2(options, _ref) {
    var disabled = _ref.disabled;
    return _objectSpread23({
      disabled
    }, options);
  },
  useProps: function useProps2(options, _ref2) {
    var htmlRef = _ref2.ref, htmlTabIndex = _ref2.tabIndex, htmlOnClickCapture = _ref2.onClickCapture, htmlOnMouseDownCapture = _ref2.onMouseDownCapture, htmlOnMouseDown = _ref2.onMouseDown, htmlOnKeyPressCapture = _ref2.onKeyPressCapture, htmlStyle = _ref2.style, htmlProps = _objectWithoutPropertiesLoose3(_ref2, ["ref", "tabIndex", "onClickCapture", "onMouseDownCapture", "onMouseDown", "onKeyPressCapture", "style"]);
    var ref = useRef2(null);
    var onClickCaptureRef = useLiveRef(htmlOnClickCapture);
    var onMouseDownCaptureRef = useLiveRef(htmlOnMouseDownCapture);
    var onMouseDownRef = useLiveRef(htmlOnMouseDown);
    var onKeyPressCaptureRef = useLiveRef(htmlOnKeyPressCapture);
    var trulyDisabled = !!options.disabled && !options.focusable;
    var _React$useState2 = useState(true), nativeTabbable = _React$useState2[0], setNativeTabbable = _React$useState2[1];
    var _React$useState3 = useState(true), supportsDisabled = _React$useState3[0], setSupportsDisabled = _React$useState3[1];
    var style = options.disabled ? _objectSpread23({
      pointerEvents: "none"
    }, htmlStyle) : htmlStyle;
    var focusOnMouseDown = useFocusOnMouseDown();
    useIsomorphicEffect(function() {
      var tabbable = ref.current;
      if (!tabbable) {
        warning(true, "Can't determine if the element is a native tabbable element because `ref` wasn't passed to the component.", "See https://reakit.io/docs/tabbable");
        return;
      }
      if (!isNativeTabbable(tabbable)) {
        setNativeTabbable(false);
      }
      if (!supportsDisabledAttribute(tabbable)) {
        setSupportsDisabled(false);
      }
    }, []);
    var onClickCapture = useDisableEvent(onClickCaptureRef, options.disabled);
    var onMouseDownCapture = useDisableEvent(onMouseDownCaptureRef, options.disabled);
    var onKeyPressCapture = useDisableEvent(onKeyPressCaptureRef, options.disabled);
    var onMouseDown = useCallback(function(event) {
      var _onMouseDownRef$curre;
      (_onMouseDownRef$curre = onMouseDownRef.current) === null || _onMouseDownRef$curre === void 0 ? void 0 : _onMouseDownRef$curre.call(onMouseDownRef, event);
      if (event.defaultPrevented)
        return;
      focusOnMouseDown === null || focusOnMouseDown === void 0 ? void 0 : focusOnMouseDown(event);
    }, [options.disabled, focusOnMouseDown]);
    return _objectSpread23({
      ref: useForkRef(ref, htmlRef),
      style,
      tabIndex: getTabIndex(trulyDisabled, nativeTabbable, supportsDisabled, htmlTabIndex),
      disabled: trulyDisabled && supportsDisabled ? true : void 0,
      "aria-disabled": options.disabled ? true : void 0,
      onClickCapture,
      onMouseDownCapture,
      onMouseDown,
      onKeyPressCapture
    }, htmlProps);
  }
});
var Tabbable = createComponent({
  as: "div",
  useHook: useTabbable
});

// ../../../node_modules/reakit/es/Clickable/Clickable.js
import {useState as useState2, useCallback as useCallback2} from "react";
var CLICKABLE_KEYS = ["unstable_clickOnEnter", "unstable_clickOnSpace"];
function isNativeClick(event) {
  var element = event.currentTarget;
  if (!event.isTrusted)
    return false;
  return isButton(element) || element.tagName === "INPUT" || element.tagName === "TEXTAREA" || element.tagName === "A" || element.tagName === "SELECT";
}
var useClickable = createHook({
  name: "Clickable",
  compose: useTabbable,
  keys: CLICKABLE_KEYS,
  useOptions: function useOptions3(_ref) {
    var _ref$unstable_clickOn = _ref.unstable_clickOnEnter, unstable_clickOnEnter = _ref$unstable_clickOn === void 0 ? true : _ref$unstable_clickOn, _ref$unstable_clickOn2 = _ref.unstable_clickOnSpace, unstable_clickOnSpace = _ref$unstable_clickOn2 === void 0 ? true : _ref$unstable_clickOn2, options = _objectWithoutPropertiesLoose3(_ref, ["unstable_clickOnEnter", "unstable_clickOnSpace"]);
    return _objectSpread23({
      unstable_clickOnEnter,
      unstable_clickOnSpace
    }, options);
  },
  useProps: function useProps3(options, _ref2) {
    var htmlOnKeyDown = _ref2.onKeyDown, htmlOnKeyUp = _ref2.onKeyUp, htmlProps = _objectWithoutPropertiesLoose3(_ref2, ["onKeyDown", "onKeyUp"]);
    var _React$useState = useState2(false), active = _React$useState[0], setActive = _React$useState[1];
    var onKeyDownRef = useLiveRef(htmlOnKeyDown);
    var onKeyUpRef = useLiveRef(htmlOnKeyUp);
    var onKeyDown = useCallback2(function(event) {
      var _onKeyDownRef$current;
      (_onKeyDownRef$current = onKeyDownRef.current) === null || _onKeyDownRef$current === void 0 ? void 0 : _onKeyDownRef$current.call(onKeyDownRef, event);
      if (event.defaultPrevented)
        return;
      if (options.disabled)
        return;
      if (event.metaKey)
        return;
      if (!isSelfTarget(event))
        return;
      var isEnter = options.unstable_clickOnEnter && event.key === "Enter";
      var isSpace = options.unstable_clickOnSpace && event.key === " ";
      if (isEnter || isSpace) {
        if (isNativeClick(event))
          return;
        event.preventDefault();
        if (isEnter) {
          event.currentTarget.click();
        } else if (isSpace) {
          setActive(true);
        }
      }
    }, [options.disabled, options.unstable_clickOnEnter, options.unstable_clickOnSpace]);
    var onKeyUp = useCallback2(function(event) {
      var _onKeyUpRef$current;
      (_onKeyUpRef$current = onKeyUpRef.current) === null || _onKeyUpRef$current === void 0 ? void 0 : _onKeyUpRef$current.call(onKeyUpRef, event);
      if (event.defaultPrevented)
        return;
      if (options.disabled)
        return;
      if (event.metaKey)
        return;
      var isSpace = options.unstable_clickOnSpace && event.key === " ";
      if (active && isSpace) {
        setActive(false);
        event.currentTarget.click();
      }
    }, [options.disabled, options.unstable_clickOnSpace, active]);
    return _objectSpread23({
      "data-active": active || void 0,
      onKeyDown,
      onKeyUp
    }, htmlProps);
  }
});
var Clickable = createComponent({
  as: "button",
  memo: true,
  useHook: useClickable
});

// ../../../node_modules/reakit/es/Button/Button.js
import {useRef as useRef3, useState as useState3, useEffect as useEffect4} from "react";
var BUTTON_KEYS = [];
var useButton = createHook({
  name: "Button",
  compose: useClickable,
  keys: BUTTON_KEYS,
  useProps: function useProps4(_, _ref) {
    var htmlRef = _ref.ref, htmlProps = _objectWithoutPropertiesLoose3(_ref, ["ref"]);
    var ref = useRef3(null);
    var _React$useState = useState3(void 0), role = _React$useState[0], setRole = _React$useState[1];
    var _React$useState2 = useState3("button"), type = _React$useState2[0], setType = _React$useState2[1];
    useEffect4(function() {
      var element = ref.current;
      if (!element) {
        warning(true, "Can't determine whether the element is a native button because `ref` wasn't passed to the component", "See https://reakit.io/docs/button");
        return;
      }
      if (!isButton(element)) {
        if (element.tagName !== "A") {
          setRole("button");
        }
        setType(void 0);
      }
    }, []);
    return _objectSpread23({
      ref: useForkRef(ref, htmlRef),
      role,
      type
    }, htmlProps);
  }
});
var Button = createComponent({
  as: "button",
  memo: true,
  useHook: useButton
});

// ../../../node_modules/reakit-utils/es/removeIndexFromArray.js
function removeIndexFromArray(array, index) {
  if (index === -1)
    return array;
  return [].concat(array.slice(0, index), array.slice(index + 1));
}

// ../../../node_modules/reakit-utils/es/createEvent.js
function createEvent(element, type, eventInit) {
  if (typeof Event === "function") {
    return new Event(type, eventInit);
  }
  var event = getDocument(element).createEvent("Event");
  event.initEvent(type, eventInit === null || eventInit === void 0 ? void 0 : eventInit.bubbles, eventInit === null || eventInit === void 0 ? void 0 : eventInit.cancelable);
  return event;
}

// ../../../node_modules/reakit/es/Checkbox/Checkbox.js
import {useRef as useRef4, useState as useState4, useEffect as useEffect5, useCallback as useCallback3} from "react";
var CHECKBOX_STATE_KEYS = ["state", "setState"];
var CHECKBOX_KEYS = [].concat(CHECKBOX_STATE_KEYS, ["value", "checked"]);
function getChecked(options) {
  if (typeof options.checked !== "undefined") {
    return options.checked;
  }
  if (typeof options.value === "undefined") {
    return !!options.state;
  }
  var state = Array.isArray(options.state) ? options.state : [];
  return state.indexOf(options.value) !== -1;
}
function fireChange(element, onChange) {
  var event = createEvent(element, "change");
  Object.defineProperties(event, {
    type: {
      value: "change"
    },
    target: {
      value: element
    },
    currentTarget: {
      value: element
    }
  });
  onChange === null || onChange === void 0 ? void 0 : onChange(event);
}
function useIndeterminateState(ref, options) {
  useEffect5(function() {
    var element = ref.current;
    if (!element) {
      warning(options.state === "indeterminate", "Can't set indeterminate state because `ref` wasn't passed to component.", "See https://reakit.io/docs/checkbox/#indeterminate-state");
      return;
    }
    if (options.state === "indeterminate") {
      element.indeterminate = true;
    } else if (element.indeterminate) {
      element.indeterminate = false;
    }
  }, [options.state, ref]);
}
var useCheckbox = createHook({
  name: "Checkbox",
  compose: useClickable,
  keys: CHECKBOX_KEYS,
  useOptions: function useOptions4(_ref, _ref2) {
    var value = _ref2.value, checked = _ref2.checked;
    var _ref$unstable_clickOn = _ref.unstable_clickOnEnter, unstable_clickOnEnter = _ref$unstable_clickOn === void 0 ? false : _ref$unstable_clickOn, options = _objectWithoutPropertiesLoose3(_ref, ["unstable_clickOnEnter"]);
    return _objectSpread23({
      unstable_clickOnEnter,
      value,
      checked: getChecked(_objectSpread23({
        checked
      }, options))
    }, options);
  },
  useProps: function useProps5(options, _ref3) {
    var htmlRef = _ref3.ref, htmlOnChange = _ref3.onChange, htmlOnClick = _ref3.onClick, htmlProps = _objectWithoutPropertiesLoose3(_ref3, ["ref", "onChange", "onClick"]);
    var ref = useRef4(null);
    var _React$useState = useState4(true), isNativeCheckbox = _React$useState[0], setIsNativeCheckbox = _React$useState[1];
    var onChangeRef = useLiveRef(htmlOnChange);
    var onClickRef = useLiveRef(htmlOnClick);
    useEffect5(function() {
      var element = ref.current;
      if (!element) {
        warning(true, "Can't determine whether the element is a native checkbox because `ref` wasn't passed to the component", "See https://reakit.io/docs/checkbox");
        return;
      }
      if (element.tagName !== "INPUT" || element.type !== "checkbox") {
        setIsNativeCheckbox(false);
      }
    }, []);
    useIndeterminateState(ref, options);
    var onChange = useCallback3(function(event) {
      var element = event.currentTarget;
      if (options.disabled) {
        event.stopPropagation();
        event.preventDefault();
        return;
      }
      if (onChangeRef.current) {
        if (!isNativeCheckbox) {
          element.checked = !element.checked;
        }
        onChangeRef.current(event);
      }
      if (!options.setState)
        return;
      if (typeof options.value === "undefined") {
        options.setState(!options.checked);
      } else {
        var state = Array.isArray(options.state) ? options.state : [];
        var index = state.indexOf(options.value);
        if (index === -1) {
          options.setState([].concat(state, [options.value]));
        } else {
          options.setState(removeIndexFromArray(state, index));
        }
      }
    }, [options.disabled, isNativeCheckbox, options.setState, options.value, options.checked, options.state]);
    var onClick = useCallback3(function(event) {
      var _onClickRef$current;
      (_onClickRef$current = onClickRef.current) === null || _onClickRef$current === void 0 ? void 0 : _onClickRef$current.call(onClickRef, event);
      if (event.defaultPrevented)
        return;
      if (isNativeCheckbox)
        return;
      fireChange(event.currentTarget, onChange);
    }, [isNativeCheckbox, onChange]);
    return _objectSpread23({
      ref: useForkRef(ref, htmlRef),
      role: !isNativeCheckbox ? "checkbox" : void 0,
      type: isNativeCheckbox ? "checkbox" : void 0,
      value: isNativeCheckbox ? options.value : void 0,
      checked: options.checked,
      "aria-checked": options.state === "indeterminate" ? "mixed" : options.checked,
      onChange,
      onClick
    }, htmlProps);
  }
});
var Checkbox = createComponent({
  as: "input",
  memo: true,
  useHook: useCheckbox
});

// ../../../node_modules/reakit-utils/es/useSealedState.js
import {useState as useState5} from "react";
function useSealedState(initialState) {
  var _React$useState = useState5(initialState), sealed = _React$useState[0];
  return sealed;
}

// ../../../node_modules/reakit-utils/es/useUpdateEffect.js
import {useRef as useRef5, useEffect as useEffect6} from "react";
function useUpdateEffect(effect4, deps) {
  var mounted = useRef5(false);
  useEffect6(function() {
    if (mounted.current) {
      return effect4();
    }
    mounted.current = true;
    return void 0;
  }, deps);
}

// ../../../node_modules/reakit-utils/es/fireBlurEvent.js
function createFocusEvent(element, type, eventInit) {
  if (eventInit === void 0) {
    eventInit = {};
  }
  if (typeof FocusEvent === "function") {
    return new FocusEvent(type, eventInit);
  }
  return createEvent(element, type, eventInit);
}
function fireBlurEvent(element, eventInit) {
  var event = createFocusEvent(element, "blur", eventInit);
  var defaultAllowed = element.dispatchEvent(event);
  var bubbleInit = _objectSpread22(_objectSpread22({}, eventInit), {}, {
    bubbles: true
  });
  element.dispatchEvent(createFocusEvent(element, "focusout", bubbleInit));
  return defaultAllowed;
}

// ../../../node_modules/reakit-utils/es/fireKeyboardEvent.js
function createKeyboardEvent(element, type, eventInit) {
  if (eventInit === void 0) {
    eventInit = {};
  }
  if (typeof KeyboardEvent === "function") {
    return new KeyboardEvent(type, eventInit);
  }
  var event = getDocument(element).createEvent("KeyboardEvent");
  event.initKeyboardEvent(type, eventInit.bubbles, eventInit.cancelable, getWindow(element), eventInit.key, eventInit.location, eventInit.ctrlKey, eventInit.altKey, eventInit.shiftKey, eventInit.metaKey);
  return event;
}
function fireKeyboardEvent(element, type, eventInit) {
  return element.dispatchEvent(createKeyboardEvent(element, type, eventInit));
}

// ../../../node_modules/reakit-utils/es/getNextActiveElementOnBlur.js
var isIE11 = canUseDOM && "msCrypto" in window;
function getNextActiveElementOnBlur(event) {
  if (isIE11) {
    var activeElement = getActiveElement(event.currentTarget);
    return activeElement;
  }
  return event.relatedTarget;
}

// ../../../node_modules/reakit/es/reverse-30eaa122.js
function groupItems(items) {
  var groups = [[]];
  var _loop = function _loop2() {
    var item = _step.value;
    var group = groups.find(function(g) {
      return !g[0] || g[0].groupId === item.groupId;
    });
    if (group) {
      group.push(item);
    } else {
      groups.push([item]);
    }
  };
  for (var _iterator = _createForOfIteratorHelperLoose3(items), _step; !(_step = _iterator()).done; ) {
    _loop();
  }
  return groups;
}
function flatten2(grid) {
  var flattened = [];
  for (var _iterator = _createForOfIteratorHelperLoose3(grid), _step; !(_step = _iterator()).done; ) {
    var row = _step.value;
    flattened.push.apply(flattened, row);
  }
  return flattened;
}
function reverse(array) {
  return array.slice().reverse();
}

// ../../../node_modules/reakit/es/getCurrentId-5aa9849e.js
function findFirstEnabledItem(items, excludeId) {
  if (excludeId) {
    return items.find(function(item) {
      return !item.disabled && item.id !== excludeId;
    });
  }
  return items.find(function(item) {
    return !item.disabled;
  });
}
function getCurrentId(options, passedId) {
  var _findFirstEnabledItem;
  if (passedId || passedId === null) {
    return passedId;
  }
  if (options.currentId || options.currentId === null) {
    return options.currentId;
  }
  return (_findFirstEnabledItem = findFirstEnabledItem(options.items || [])) === null || _findFirstEnabledItem === void 0 ? void 0 : _findFirstEnabledItem.id;
}

// ../../../node_modules/reakit/es/findEnabledItemById-8ddca752.js
function findEnabledItemById(items, id) {
  if (!id)
    return void 0;
  return items === null || items === void 0 ? void 0 : items.find(function(item) {
    return item.id === id && !item.disabled;
  });
}

// ../../../node_modules/reakit/es/__keys-6742f591.js
var COMPOSITE_STATE_KEYS = ["baseId", "unstable_idCountRef", "setBaseId", "unstable_virtual", "rtl", "orientation", "items", "groups", "currentId", "loop", "wrap", "shift", "unstable_moves", "unstable_hasActiveWidget", "unstable_includesBaseElement", "registerItem", "unregisterItem", "registerGroup", "unregisterGroup", "move", "next", "previous", "up", "down", "first", "last", "sort", "unstable_setVirtual", "setRTL", "setOrientation", "setCurrentId", "setLoop", "setWrap", "setShift", "reset", "unstable_setIncludesBaseElement", "unstable_setHasActiveWidget"];
var COMPOSITE_KEYS = COMPOSITE_STATE_KEYS;
var COMPOSITE_GROUP_KEYS = COMPOSITE_KEYS;
var COMPOSITE_ITEM_KEYS = COMPOSITE_GROUP_KEYS;

// ../../../node_modules/reakit/es/userFocus-e16425e3.js
function userFocus(element) {
  element.userFocus = true;
  element.focus();
  element.userFocus = false;
}
function hasUserFocus(element) {
  return !!element.userFocus;
}
function setUserFocus(element, value) {
  element.userFocus = value;
}

// ../../../node_modules/reakit/es/Composite/Composite.js
import {useRef as useRef6, useEffect as useEffect7, useCallback as useCallback4, useReducer} from "react";
var isIE112 = canUseDOM && "msCrypto" in window;
function canProxyKeyboardEvent(event) {
  if (!isSelfTarget(event))
    return false;
  if (event.metaKey)
    return false;
  if (event.key === "Tab")
    return false;
  return true;
}
function useKeyboardEventProxy(virtual, currentItem, htmlEventHandler) {
  var eventHandlerRef = useLiveRef(htmlEventHandler);
  return useCallback4(function(event) {
    var _eventHandlerRef$curr;
    (_eventHandlerRef$curr = eventHandlerRef.current) === null || _eventHandlerRef$curr === void 0 ? void 0 : _eventHandlerRef$curr.call(eventHandlerRef, event);
    if (event.defaultPrevented)
      return;
    if (virtual && canProxyKeyboardEvent(event)) {
      var currentElement = currentItem === null || currentItem === void 0 ? void 0 : currentItem.ref.current;
      if (currentElement) {
        if (!fireKeyboardEvent(currentElement, event.type, event)) {
          event.preventDefault();
        }
        if (event.currentTarget.contains(currentElement)) {
          event.stopPropagation();
        }
      }
    }
  }, [virtual, currentItem]);
}
function useActiveElementRef(elementRef) {
  var activeElementRef = useRef6(null);
  useEffect7(function() {
    var document2 = getDocument(elementRef.current);
    var onFocus = function onFocus2(event) {
      var target = event.target;
      activeElementRef.current = target;
    };
    document2.addEventListener("focus", onFocus, true);
    return function() {
      document2.removeEventListener("focus", onFocus, true);
    };
  }, []);
  return activeElementRef;
}
function findFirstEnabledItemInTheLastRow(items) {
  return findFirstEnabledItem(flatten2(reverse(groupItems(items))));
}
function isItem(items, element) {
  return items === null || items === void 0 ? void 0 : items.some(function(item) {
    return !!element && item.ref.current === element;
  });
}
function useScheduleUserFocus(currentItem) {
  var currentItemRef = useLiveRef(currentItem);
  var _React$useReducer = useReducer(function(n) {
    return n + 1;
  }, 0), scheduled = _React$useReducer[0], schedule = _React$useReducer[1];
  useEffect7(function() {
    var _currentItemRef$curre;
    var currentElement = (_currentItemRef$curre = currentItemRef.current) === null || _currentItemRef$curre === void 0 ? void 0 : _currentItemRef$curre.ref.current;
    if (scheduled && currentElement) {
      userFocus(currentElement);
    }
  }, [scheduled]);
  return schedule;
}
var useComposite = createHook({
  name: "Composite",
  compose: [useTabbable],
  keys: COMPOSITE_KEYS,
  useOptions: function useOptions5(options) {
    return _objectSpread23(_objectSpread23({}, options), {}, {
      currentId: getCurrentId(options)
    });
  },
  useProps: function useProps6(options, _ref) {
    var htmlRef = _ref.ref, htmlOnFocusCapture = _ref.onFocusCapture, htmlOnFocus = _ref.onFocus, htmlOnBlurCapture = _ref.onBlurCapture, htmlOnKeyDown = _ref.onKeyDown, htmlOnKeyDownCapture = _ref.onKeyDownCapture, htmlOnKeyUpCapture = _ref.onKeyUpCapture, htmlProps = _objectWithoutPropertiesLoose3(_ref, ["ref", "onFocusCapture", "onFocus", "onBlurCapture", "onKeyDown", "onKeyDownCapture", "onKeyUpCapture"]);
    var ref = useRef6(null);
    var currentItem = findEnabledItemById(options.items, options.currentId);
    var previousElementRef = useRef6(null);
    var onFocusCaptureRef = useLiveRef(htmlOnFocusCapture);
    var onFocusRef = useLiveRef(htmlOnFocus);
    var onBlurCaptureRef = useLiveRef(htmlOnBlurCapture);
    var onKeyDownRef = useLiveRef(htmlOnKeyDown);
    var scheduleUserFocus = useScheduleUserFocus(currentItem);
    var activeElementRef = isIE112 ? useActiveElementRef(ref) : void 0;
    useEffect7(function() {
      var element = ref.current;
      if (options.unstable_moves && !currentItem) {
        warning(!element, "Can't focus composite component because `ref` wasn't passed to component.", "See https://reakit.io/docs/composite");
        element === null || element === void 0 ? void 0 : element.focus();
      }
    }, [options.unstable_moves, currentItem]);
    var onKeyDownCapture = useKeyboardEventProxy(options.unstable_virtual, currentItem, htmlOnKeyDownCapture);
    var onKeyUpCapture = useKeyboardEventProxy(options.unstable_virtual, currentItem, htmlOnKeyUpCapture);
    var onFocusCapture = useCallback4(function(event) {
      var _onFocusCaptureRef$cu;
      (_onFocusCaptureRef$cu = onFocusCaptureRef.current) === null || _onFocusCaptureRef$cu === void 0 ? void 0 : _onFocusCaptureRef$cu.call(onFocusCaptureRef, event);
      if (event.defaultPrevented)
        return;
      if (!options.unstable_virtual)
        return;
      var previousActiveElement = (activeElementRef === null || activeElementRef === void 0 ? void 0 : activeElementRef.current) || event.relatedTarget;
      var previousActiveElementWasItem = isItem(options.items, previousActiveElement);
      if (isSelfTarget(event) && previousActiveElementWasItem) {
        event.stopPropagation();
      }
    }, [options.unstable_virtual, options.items]);
    var onFocus = useCallback4(function(event) {
      var _onFocusRef$current;
      (_onFocusRef$current = onFocusRef.current) === null || _onFocusRef$current === void 0 ? void 0 : _onFocusRef$current.call(onFocusRef, event);
      if (event.defaultPrevented)
        return;
      if (options.unstable_virtual) {
        if (isSelfTarget(event)) {
          scheduleUserFocus();
        }
      } else if (isSelfTarget(event)) {
        var _options$setCurrentId;
        (_options$setCurrentId = options.setCurrentId) === null || _options$setCurrentId === void 0 ? void 0 : _options$setCurrentId.call(options, null);
      }
    }, [options.unstable_virtual, options.setCurrentId]);
    var onBlurCapture = useCallback4(function(event) {
      var _onBlurCaptureRef$cur;
      (_onBlurCaptureRef$cur = onBlurCaptureRef.current) === null || _onBlurCaptureRef$cur === void 0 ? void 0 : _onBlurCaptureRef$cur.call(onBlurCaptureRef, event);
      if (event.defaultPrevented)
        return;
      if (!options.unstable_virtual)
        return;
      var currentElement = (currentItem === null || currentItem === void 0 ? void 0 : currentItem.ref.current) || null;
      var nextActiveElement = getNextActiveElementOnBlur(event);
      var nextActiveElementIsItem = isItem(options.items, nextActiveElement);
      if (isSelfTarget(event) && nextActiveElementIsItem) {
        if (nextActiveElement === currentElement) {
          if (previousElementRef.current && previousElementRef.current !== nextActiveElement) {
            fireBlurEvent(previousElementRef.current, event);
          }
          previousElementRef.current = currentElement;
        } else if (currentElement) {
          fireBlurEvent(currentElement, event);
          previousElementRef.current = nextActiveElement;
        }
        event.stopPropagation();
      } else {
        var targetIsItem = isItem(options.items, event.target);
        if (!targetIsItem && currentElement) {
          fireBlurEvent(currentElement, event);
        }
      }
    }, [options.unstable_virtual, options.items, currentItem]);
    var onKeyDown = useCallback4(function(event) {
      var _onKeyDownRef$current, _options$groups;
      (_onKeyDownRef$current = onKeyDownRef.current) === null || _onKeyDownRef$current === void 0 ? void 0 : _onKeyDownRef$current.call(onKeyDownRef, event);
      if (event.defaultPrevented)
        return;
      if (options.currentId !== null)
        return;
      if (!isSelfTarget(event))
        return;
      var isVertical = options.orientation !== "horizontal";
      var isHorizontal = options.orientation !== "vertical";
      var isGrid = !!((_options$groups = options.groups) !== null && _options$groups !== void 0 && _options$groups.length);
      var up = function up2() {
        if (isGrid) {
          var item = findFirstEnabledItemInTheLastRow(options.items);
          if (item !== null && item !== void 0 && item.id) {
            var _options$move;
            (_options$move = options.move) === null || _options$move === void 0 ? void 0 : _options$move.call(options, item.id);
          }
        } else {
          var _options$last;
          (_options$last = options.last) === null || _options$last === void 0 ? void 0 : _options$last.call(options);
        }
      };
      var keyMap = {
        ArrowUp: (isGrid || isVertical) && up,
        ArrowRight: (isGrid || isHorizontal) && options.first,
        ArrowDown: (isGrid || isVertical) && options.first,
        ArrowLeft: (isGrid || isHorizontal) && options.last,
        Home: options.first,
        End: options.last,
        PageUp: options.first,
        PageDown: options.last
      };
      var action = keyMap[event.key];
      if (action) {
        event.preventDefault();
        action();
      }
    }, [options.currentId, options.orientation, options.groups, options.items, options.move, options.last, options.first]);
    return _objectSpread23({
      ref: useForkRef(ref, htmlRef),
      id: options.baseId,
      onFocus,
      onFocusCapture,
      onBlurCapture,
      onKeyDownCapture,
      onKeyDown,
      onKeyUpCapture,
      "aria-activedescendant": options.unstable_virtual ? (currentItem === null || currentItem === void 0 ? void 0 : currentItem.id) || void 0 : void 0
    }, htmlProps);
  },
  useComposeProps: function useComposeProps(options, htmlProps) {
    htmlProps = useRole(options, htmlProps, true);
    var tabbableHTMLProps = useTabbable(options, htmlProps, true);
    if (options.unstable_virtual || options.currentId === null) {
      return _objectSpread23({
        tabIndex: 0
      }, tabbableHTMLProps);
    }
    return _objectSpread23(_objectSpread23({}, htmlProps), {}, {
      ref: tabbableHTMLProps.ref
    });
  }
});
var Composite = createComponent({
  as: "div",
  useHook: useComposite,
  useCreateElement: function useCreateElement$1(type, props, children) {
    useWarning(!props["aria-label"] && !props["aria-labelledby"], "You should provide either `aria-label` or `aria-labelledby` props.", "See https://reakit.io/docs/composite");
    return useCreateElement(type, props, children);
  }
});

// ../../../node_modules/reakit-utils/es/isTextField.js
function isTextField(element) {
  try {
    var isTextInput = element instanceof HTMLInputElement && element.selectionStart !== null;
    var isTextArea = element.tagName === "TEXTAREA";
    var isContentEditable = element.contentEditable === "true";
    return isTextInput || isTextArea || isContentEditable || false;
  } catch (error) {
    return false;
  }
}

// ../../../node_modules/reakit-utils/es/hasFocus.js
function hasFocus(element) {
  var activeElement = getActiveElement(element);
  if (!activeElement)
    return false;
  if (activeElement === element)
    return true;
  var activeDescendant = activeElement.getAttribute("aria-activedescendant");
  if (!activeDescendant)
    return false;
  return activeDescendant === element.id;
}

// ../../../node_modules/reakit-utils/es/ensureFocus.js
function ensureFocus(element, _temp) {
  var _ref = _temp === void 0 ? {} : _temp, preventScroll = _ref.preventScroll, _ref$isActive = _ref.isActive, isActive = _ref$isActive === void 0 ? hasFocus : _ref$isActive;
  if (isActive(element))
    return -1;
  element.focus({
    preventScroll
  });
  if (isActive(element))
    return -1;
  return requestAnimationFrame(function() {
    element.focus({
      preventScroll
    });
  });
}

// ../../../node_modules/reakit/es/Id/IdProvider.js
import {createContext as createContext2, useRef as useRef7, useCallback as useCallback5, createElement as createElement2} from "react";
var defaultPrefix = "id";
function generateRandomString(prefix) {
  if (prefix === void 0) {
    prefix = defaultPrefix;
  }
  return "" + (prefix ? prefix + "-" : "") + Math.random().toString(32).substr(2, 6);
}
var unstable_IdContext = /* @__PURE__ */ createContext2(generateRandomString);

// ../../../node_modules/reakit/es/Id/Id.js
import {useContext as useContext3, useState as useState6, useMemo as useMemo2} from "react";
var ID_STATE_KEYS = ["baseId", "unstable_idCountRef", "setBaseId"];
var ID_KEYS = [].concat(ID_STATE_KEYS, ["id"]);
var unstable_useId = createHook({
  keys: ID_KEYS,
  useOptions: function useOptions6(options, htmlProps) {
    var generateId = useContext3(unstable_IdContext);
    var _React$useState = useState6(function() {
      if (options.unstable_idCountRef) {
        options.unstable_idCountRef.current += 1;
        return "-" + options.unstable_idCountRef.current;
      }
      if (options.baseId) {
        return "-" + generateId("");
      }
      return "";
    }), suffix = _React$useState[0];
    var baseId = useMemo2(function() {
      return options.baseId || generateId();
    }, [options.baseId, generateId]);
    var id = htmlProps.id || options.id || "" + baseId + suffix;
    return _objectSpread23(_objectSpread23({}, options), {}, {
      id
    });
  },
  useProps: function useProps7(options, htmlProps) {
    return _objectSpread23({
      id: options.id
    }, htmlProps);
  }
});
var unstable_Id = createComponent({
  as: "div",
  useHook: unstable_useId
});

// ../../../node_modules/reakit-utils/es/fireEvent.js
function fireEvent(element, type, eventInit) {
  return element.dispatchEvent(createEvent(element, type, eventInit));
}

// ../../../node_modules/reakit/es/setTextFieldValue-0a221f4e.js
function setTextFieldValue(element, value) {
  if (element instanceof HTMLInputElement || element instanceof HTMLTextAreaElement) {
    var _Object$getOwnPropert;
    var proto = Object.getPrototypeOf(element);
    var setValue = (_Object$getOwnPropert = Object.getOwnPropertyDescriptor(proto, "value")) === null || _Object$getOwnPropert === void 0 ? void 0 : _Object$getOwnPropert.set;
    if (setValue) {
      setValue.call(element, value);
      fireEvent(element, "input", {
        bubbles: true
      });
    }
  }
}

// ../../../node_modules/reakit/es/Composite/CompositeItem.js
import {useRef as useRef8, useMemo as useMemo3, useEffect as useEffect8, useCallback as useCallback6} from "react";
function getWidget(itemElement) {
  return itemElement.querySelector("[data-composite-item-widget]");
}
function useItem(options) {
  return useMemo3(function() {
    var _options$items;
    return (_options$items = options.items) === null || _options$items === void 0 ? void 0 : _options$items.find(function(item) {
      return options.id && item.id === options.id;
    });
  }, [options.items, options.id]);
}
function targetIsAnotherItem(event, items) {
  if (isSelfTarget(event))
    return false;
  for (var _iterator = _createForOfIteratorHelperLoose3(items), _step; !(_step = _iterator()).done; ) {
    var item = _step.value;
    if (item.ref.current === event.target) {
      return true;
    }
  }
  return false;
}
var useCompositeItem = createHook({
  name: "CompositeItem",
  compose: [useClickable, unstable_useId],
  keys: COMPOSITE_ITEM_KEYS,
  propsAreEqual: function propsAreEqual3(prev, next) {
    if (!next.id || prev.id !== next.id) {
      return useClickable.unstable_propsAreEqual(prev, next);
    }
    var prevCurrentId = prev.currentId, prevMoves = prev.unstable_moves, prevProps = _objectWithoutPropertiesLoose3(prev, ["currentId", "unstable_moves"]);
    var nextCurrentId = next.currentId, nextMoves = next.unstable_moves, nextProps = _objectWithoutPropertiesLoose3(next, ["currentId", "unstable_moves"]);
    if (nextCurrentId !== prevCurrentId) {
      if (next.id === nextCurrentId || next.id === prevCurrentId) {
        return false;
      }
    } else if (prevMoves !== nextMoves) {
      return false;
    }
    return useClickable.unstable_propsAreEqual(prevProps, nextProps);
  },
  useOptions: function useOptions7(options) {
    return _objectSpread23(_objectSpread23({}, options), {}, {
      id: options.id,
      currentId: getCurrentId(options),
      unstable_clickOnSpace: options.unstable_hasActiveWidget ? false : options.unstable_clickOnSpace
    });
  },
  useProps: function useProps8(options, _ref) {
    var _options$items2;
    var htmlRef = _ref.ref, _ref$tabIndex = _ref.tabIndex, htmlTabIndex = _ref$tabIndex === void 0 ? 0 : _ref$tabIndex, htmlOnMouseDown = _ref.onMouseDown, htmlOnFocus = _ref.onFocus, htmlOnBlurCapture = _ref.onBlurCapture, htmlOnKeyDown = _ref.onKeyDown, htmlOnClick = _ref.onClick, htmlProps = _objectWithoutPropertiesLoose3(_ref, ["ref", "tabIndex", "onMouseDown", "onFocus", "onBlurCapture", "onKeyDown", "onClick"]);
    var ref = useRef8(null);
    var id = options.id;
    var trulyDisabled = options.disabled && !options.focusable;
    var isCurrentItem = options.currentId === id;
    var isCurrentItemRef = useLiveRef(isCurrentItem);
    var hasFocusedComposite = useRef8(false);
    var item = useItem(options);
    var onMouseDownRef = useLiveRef(htmlOnMouseDown);
    var onFocusRef = useLiveRef(htmlOnFocus);
    var onBlurCaptureRef = useLiveRef(htmlOnBlurCapture);
    var onKeyDownRef = useLiveRef(htmlOnKeyDown);
    var onClickRef = useLiveRef(htmlOnClick);
    var shouldTabIndex = !options.unstable_virtual && !options.unstable_hasActiveWidget && isCurrentItem || !((_options$items2 = options.items) !== null && _options$items2 !== void 0 && _options$items2.length);
    useEffect8(function() {
      var _options$registerItem;
      if (!id)
        return void 0;
      (_options$registerItem = options.registerItem) === null || _options$registerItem === void 0 ? void 0 : _options$registerItem.call(options, {
        id,
        ref,
        disabled: !!trulyDisabled
      });
      return function() {
        var _options$unregisterIt;
        (_options$unregisterIt = options.unregisterItem) === null || _options$unregisterIt === void 0 ? void 0 : _options$unregisterIt.call(options, id);
      };
    }, [id, trulyDisabled, options.registerItem, options.unregisterItem]);
    useEffect8(function() {
      var element = ref.current;
      if (!element) {
        warning(true, "Can't focus composite item component because `ref` wasn't passed to component.", "See https://reakit.io/docs/composite");
        return;
      }
      if (options.unstable_moves && isCurrentItemRef.current) {
        userFocus(element);
      }
    }, [options.unstable_moves]);
    var onMouseDown = useCallback6(function(event) {
      var _onMouseDownRef$curre;
      (_onMouseDownRef$curre = onMouseDownRef.current) === null || _onMouseDownRef$curre === void 0 ? void 0 : _onMouseDownRef$curre.call(onMouseDownRef, event);
      setUserFocus(event.currentTarget, true);
    }, []);
    var onFocus = useCallback6(function(event) {
      var _onFocusRef$current, _options$setCurrentId;
      var shouldFocusComposite = hasUserFocus(event.currentTarget);
      setUserFocus(event.currentTarget, false);
      (_onFocusRef$current = onFocusRef.current) === null || _onFocusRef$current === void 0 ? void 0 : _onFocusRef$current.call(onFocusRef, event);
      if (event.defaultPrevented)
        return;
      if (isPortalEvent(event))
        return;
      if (!id)
        return;
      if (targetIsAnotherItem(event, options.items))
        return;
      (_options$setCurrentId = options.setCurrentId) === null || _options$setCurrentId === void 0 ? void 0 : _options$setCurrentId.call(options, id);
      if (shouldFocusComposite && options.unstable_virtual && options.baseId && isSelfTarget(event)) {
        var target = event.target;
        var composite = getDocument(target).getElementById(options.baseId);
        if (composite) {
          hasFocusedComposite.current = true;
          ensureFocus(composite);
        }
      }
    }, [id, options.items, options.setCurrentId, options.unstable_virtual, options.baseId]);
    var onBlurCapture = useCallback6(function(event) {
      var _onBlurCaptureRef$cur;
      (_onBlurCaptureRef$cur = onBlurCaptureRef.current) === null || _onBlurCaptureRef$cur === void 0 ? void 0 : _onBlurCaptureRef$cur.call(onBlurCaptureRef, event);
      if (event.defaultPrevented)
        return;
      if (options.unstable_virtual && hasFocusedComposite.current) {
        hasFocusedComposite.current = false;
        event.preventDefault();
        event.stopPropagation();
      }
    }, [options.unstable_virtual]);
    var onKeyDown = useCallback6(function(event) {
      var _onKeyDownRef$current;
      if (!isSelfTarget(event))
        return;
      var isVertical = options.orientation !== "horizontal";
      var isHorizontal = options.orientation !== "vertical";
      var isGrid = !!(item !== null && item !== void 0 && item.groupId);
      var keyMap = {
        ArrowUp: (isGrid || isVertical) && options.up,
        ArrowRight: (isGrid || isHorizontal) && options.next,
        ArrowDown: (isGrid || isVertical) && options.down,
        ArrowLeft: (isGrid || isHorizontal) && options.previous,
        Home: function Home() {
          if (!isGrid || event.ctrlKey) {
            var _options$first;
            (_options$first = options.first) === null || _options$first === void 0 ? void 0 : _options$first.call(options);
          } else {
            var _options$previous;
            (_options$previous = options.previous) === null || _options$previous === void 0 ? void 0 : _options$previous.call(options, true);
          }
        },
        End: function End() {
          if (!isGrid || event.ctrlKey) {
            var _options$last;
            (_options$last = options.last) === null || _options$last === void 0 ? void 0 : _options$last.call(options);
          } else {
            var _options$next;
            (_options$next = options.next) === null || _options$next === void 0 ? void 0 : _options$next.call(options, true);
          }
        },
        PageUp: function PageUp() {
          if (isGrid) {
            var _options$up;
            (_options$up = options.up) === null || _options$up === void 0 ? void 0 : _options$up.call(options, true);
          } else {
            var _options$first2;
            (_options$first2 = options.first) === null || _options$first2 === void 0 ? void 0 : _options$first2.call(options);
          }
        },
        PageDown: function PageDown() {
          if (isGrid) {
            var _options$down;
            (_options$down = options.down) === null || _options$down === void 0 ? void 0 : _options$down.call(options, true);
          } else {
            var _options$last2;
            (_options$last2 = options.last) === null || _options$last2 === void 0 ? void 0 : _options$last2.call(options);
          }
        }
      };
      var action = keyMap[event.key];
      if (action) {
        event.preventDefault();
        action();
        return;
      }
      (_onKeyDownRef$current = onKeyDownRef.current) === null || _onKeyDownRef$current === void 0 ? void 0 : _onKeyDownRef$current.call(onKeyDownRef, event);
      if (event.defaultPrevented)
        return;
      if (event.key.length === 1 && event.key !== " ") {
        var widget = getWidget(event.currentTarget);
        if (widget && isTextField(widget)) {
          widget.focus();
          setTextFieldValue(widget, "");
        }
      } else if (event.key === "Delete" || event.key === "Backspace") {
        var _widget = getWidget(event.currentTarget);
        if (_widget && isTextField(_widget)) {
          event.preventDefault();
          setTextFieldValue(_widget, "");
        }
      }
    }, [options.orientation, item, options.up, options.next, options.down, options.previous, options.first, options.last]);
    var onClick = useCallback6(function(event) {
      var _onClickRef$current;
      (_onClickRef$current = onClickRef.current) === null || _onClickRef$current === void 0 ? void 0 : _onClickRef$current.call(onClickRef, event);
      if (event.defaultPrevented)
        return;
      var element = event.currentTarget;
      var widget = getWidget(element);
      if (widget && !hasFocusWithin(widget)) {
        widget.focus();
      }
    }, []);
    return _objectSpread23({
      ref: useForkRef(ref, htmlRef),
      id,
      tabIndex: shouldTabIndex ? htmlTabIndex : -1,
      "aria-selected": options.unstable_virtual && isCurrentItem ? true : void 0,
      onMouseDown,
      onFocus,
      onBlurCapture,
      onKeyDown,
      onClick
    }, htmlProps);
  }
});
var CompositeItem = createComponent({
  as: "button",
  memo: true,
  useHook: useCompositeItem
});

// ../../../node_modules/reakit/es/Group/Group.js
var GROUP_KEYS = [];
var useGroup = createHook({
  name: "Group",
  compose: useRole,
  keys: GROUP_KEYS,
  useProps: function useProps9(_, htmlProps) {
    return _objectSpread23({
      role: "group"
    }, htmlProps);
  }
});
var Group = createComponent({
  as: "div",
  useHook: useGroup
});

// ../../../node_modules/reakit/es/Composite/CompositeGroup.js
import {useRef as useRef9} from "react";
var useCompositeGroup = createHook({
  name: "CompositeGroup",
  compose: [useGroup, unstable_useId],
  keys: COMPOSITE_GROUP_KEYS,
  propsAreEqual: function propsAreEqual4(prev, next) {
    if (!next.id || prev.id !== next.id) {
      return useGroup.unstable_propsAreEqual(prev, next);
    }
    var prevCurrentId = prev.currentId, prevMoves = prev.unstable_moves, prevProps = _objectWithoutPropertiesLoose3(prev, ["currentId", "unstable_moves"]);
    var nextCurrentId = next.currentId, nextMoves = next.unstable_moves, nextProps = _objectWithoutPropertiesLoose3(next, ["currentId", "unstable_moves"]);
    if (prev.items && next.items) {
      var prevCurrentItem = findEnabledItemById(prev.items, prevCurrentId);
      var nextCurrentItem = findEnabledItemById(next.items, nextCurrentId);
      var prevGroupId = prevCurrentItem === null || prevCurrentItem === void 0 ? void 0 : prevCurrentItem.groupId;
      var nextGroupId = nextCurrentItem === null || nextCurrentItem === void 0 ? void 0 : nextCurrentItem.groupId;
      if (next.id === nextGroupId || next.id === prevGroupId) {
        return false;
      }
    }
    return useGroup.unstable_propsAreEqual(prevProps, nextProps);
  },
  useProps: function useProps10(options, _ref) {
    var htmlRef = _ref.ref, htmlProps = _objectWithoutPropertiesLoose3(_ref, ["ref"]);
    var ref = useRef9(null);
    var id = options.id;
    useIsomorphicEffect(function() {
      var _options$registerGrou;
      if (!id)
        return void 0;
      (_options$registerGrou = options.registerGroup) === null || _options$registerGrou === void 0 ? void 0 : _options$registerGrou.call(options, {
        id,
        ref
      });
      return function() {
        var _options$unregisterGr;
        (_options$unregisterGr = options.unregisterGroup) === null || _options$unregisterGr === void 0 ? void 0 : _options$unregisterGr.call(options, id);
      };
    }, [id, options.registerGroup, options.unregisterGroup]);
    return _objectSpread23({
      ref: useForkRef(ref, htmlRef)
    }, htmlProps);
  }
});
var CompositeGroup = createComponent({
  as: "div",
  useHook: useCompositeGroup
});

// ../../../node_modules/reakit-utils/es/applyState.js
function isUpdater(argument) {
  return typeof argument === "function";
}
function applyState(argument, currentValue) {
  if (isUpdater(argument)) {
    return argument(currentValue);
  }
  return argument;
}

// ../../../node_modules/reakit/es/Id/IdState.js
import {useContext as useContext4, useRef as useRef10, useState as useState7} from "react";
function unstable_useIdState(initialState) {
  if (initialState === void 0) {
    initialState = {};
  }
  var _useSealedState = useSealedState(initialState), initialBaseId = _useSealedState.baseId;
  var generateId = useContext4(unstable_IdContext);
  var idCountRef = useRef10(0);
  var _React$useState = useState7(function() {
    return initialBaseId || generateId();
  }), baseId = _React$useState[0], setBaseId = _React$useState[1];
  return {
    baseId,
    setBaseId,
    unstable_idCountRef: idCountRef
  };
}

// ../../../node_modules/reakit/es/Composite/CompositeState.js
import {useRef as useRef11, useEffect as useEffect9, useReducer as useReducer2, useState as useState8, useCallback as useCallback7} from "react";
function isElementPreceding(element1, element2) {
  return Boolean(element2.compareDocumentPosition(element1) & Node.DOCUMENT_POSITION_PRECEDING);
}
function findDOMIndex(items, item) {
  return items.findIndex(function(currentItem) {
    if (!currentItem.ref.current || !item.ref.current) {
      return false;
    }
    return isElementPreceding(item.ref.current, currentItem.ref.current);
  });
}
function getMaxLength(rows) {
  var maxLength = 0;
  for (var _iterator = _createForOfIteratorHelperLoose3(rows), _step; !(_step = _iterator()).done; ) {
    var length = _step.value.length;
    if (length > maxLength) {
      maxLength = length;
    }
  }
  return maxLength;
}
function verticalizeItems(items) {
  var groups = groupItems(items);
  var maxLength = getMaxLength(groups);
  var verticalized = [];
  for (var i = 0; i < maxLength; i += 1) {
    for (var _iterator = _createForOfIteratorHelperLoose3(groups), _step; !(_step = _iterator()).done; ) {
      var group = _step.value;
      if (group[i]) {
        verticalized.push(_objectSpread23(_objectSpread23({}, group[i]), {}, {
          groupId: group[i].groupId ? "" + i : void 0
        }));
      }
    }
  }
  return verticalized;
}
function createEmptyItem(groupId) {
  return {
    id: "__EMPTY_ITEM__",
    disabled: true,
    ref: {
      current: null
    },
    groupId
  };
}
function fillGroups(groups, currentId, shift) {
  var maxLength = getMaxLength(groups);
  for (var _iterator = _createForOfIteratorHelperLoose3(groups), _step; !(_step = _iterator()).done; ) {
    var group = _step.value;
    for (var i = 0; i < maxLength; i += 1) {
      var item = group[i];
      if (!item || shift && item.disabled) {
        var isFrist = i === 0;
        var previousItem = isFrist && shift ? findFirstEnabledItem(group) : group[i - 1];
        group[i] = previousItem && currentId !== (previousItem === null || previousItem === void 0 ? void 0 : previousItem.id) && shift ? previousItem : createEmptyItem(previousItem === null || previousItem === void 0 ? void 0 : previousItem.groupId);
      }
    }
  }
  return groups;
}
var nullItem = {
  id: null,
  ref: {
    current: null
  }
};
function placeItemsAfter(items, id, shouldInsertNullItem) {
  var index = items.findIndex(function(item) {
    return item.id === id;
  });
  return [].concat(items.slice(index + 1), shouldInsertNullItem ? [nullItem] : [], items.slice(0, index));
}
function getItemsInGroup(items, groupId) {
  return items.filter(function(item) {
    return item.groupId === groupId;
  });
}
var map = {
  horizontal: "vertical",
  vertical: "horizontal"
};
function getOppositeOrientation(orientation) {
  return orientation && map[orientation];
}
function addItemAtIndex(array, item, index) {
  if (!(index in array)) {
    return [].concat(array, [item]);
  }
  return [].concat(array.slice(0, index), [item], array.slice(index));
}
function sortBasedOnDOMPosition(items) {
  var pairs = items.map(function(item, index) {
    return [index, item];
  });
  var isOrderDifferent = false;
  pairs.sort(function(_ref, _ref2) {
    var indexA = _ref[0], a = _ref[1];
    var indexB = _ref2[0], b = _ref2[1];
    var elementA = a.ref.current;
    var elementB = b.ref.current;
    if (!elementA || !elementB)
      return 0;
    if (isElementPreceding(elementA, elementB)) {
      if (indexA > indexB) {
        isOrderDifferent = true;
      }
      return -1;
    }
    if (indexA < indexB) {
      isOrderDifferent = true;
    }
    return 1;
  });
  if (isOrderDifferent) {
    return pairs.map(function(_ref3) {
      var _ = _ref3[0], item = _ref3[1];
      return item;
    });
  }
  return items;
}
function setItemsBasedOnDOMPosition(items, setItems) {
  var sortedItems = sortBasedOnDOMPosition(items);
  if (items !== sortedItems) {
    setItems(sortedItems);
  }
}
function useIntersectionObserver(items, setItems) {
  var previousItems = useRef11([]);
  useEffect9(function() {
    var callback = function callback2() {
      var hasPreviousItems = !!previousItems.current.length;
      if (hasPreviousItems) {
        setItemsBasedOnDOMPosition(items, setItems);
      }
      previousItems.current = items;
    };
    var observer = new IntersectionObserver(callback, {
      root: document.body
    });
    for (var _iterator = _createForOfIteratorHelperLoose3(items), _step; !(_step = _iterator()).done; ) {
      var item = _step.value;
      if (item.ref.current) {
        observer.observe(item.ref.current);
      }
    }
    return function() {
      observer.disconnect();
    };
  }, [items]);
}
function useTimeoutObserver(items, setItems) {
  useEffect9(function() {
    var callback = function callback2() {
      return setItemsBasedOnDOMPosition(items, setItems);
    };
    var timeout = setTimeout(callback, 250);
    return function() {
      return clearTimeout(timeout);
    };
  });
}
function useSortBasedOnDOMPosition(items, setItems) {
  if (typeof IntersectionObserver === "function") {
    useIntersectionObserver(items, setItems);
  } else {
    useTimeoutObserver(items, setItems);
  }
}
function reducer(state, action) {
  var virtual = state.unstable_virtual, rtl = state.rtl, orientation = state.orientation, items = state.items, groups = state.groups, currentId = state.currentId, loop = state.loop, wrap = state.wrap, pastIds = state.pastIds, shift = state.shift, moves = state.unstable_moves, includesBaseElement = state.unstable_includesBaseElement, initialVirtual = state.initialVirtual, initialRTL = state.initialRTL, initialOrientation = state.initialOrientation, initialCurrentId = state.initialCurrentId, initialLoop = state.initialLoop, initialWrap = state.initialWrap, initialShift = state.initialShift, hasSetCurrentId = state.hasSetCurrentId;
  switch (action.type) {
    case "registerGroup": {
      var _group = action.group;
      if (groups.length === 0) {
        return _objectSpread23(_objectSpread23({}, state), {}, {
          groups: [_group]
        });
      }
      var index = findDOMIndex(groups, _group);
      return _objectSpread23(_objectSpread23({}, state), {}, {
        groups: addItemAtIndex(groups, _group, index)
      });
    }
    case "unregisterGroup": {
      var _id = action.id;
      var nextGroups = groups.filter(function(group) {
        return group.id !== _id;
      });
      if (nextGroups.length === groups.length) {
        return state;
      }
      return _objectSpread23(_objectSpread23({}, state), {}, {
        groups: nextGroups
      });
    }
    case "registerItem": {
      var _item = action.item;
      var _group2 = groups.find(function(r) {
        var _r$ref$current;
        return (_r$ref$current = r.ref.current) === null || _r$ref$current === void 0 ? void 0 : _r$ref$current.contains(_item.ref.current);
      });
      var nextItem = _objectSpread23({
        groupId: _group2 === null || _group2 === void 0 ? void 0 : _group2.id
      }, _item);
      var _index = findDOMIndex(items, nextItem);
      var nextState = _objectSpread23(_objectSpread23({}, state), {}, {
        items: addItemAtIndex(items, nextItem, _index)
      });
      if (!hasSetCurrentId && !moves && initialCurrentId === void 0) {
        var _findFirstEnabledItem;
        return _objectSpread23(_objectSpread23({}, nextState), {}, {
          currentId: (_findFirstEnabledItem = findFirstEnabledItem(nextState.items)) === null || _findFirstEnabledItem === void 0 ? void 0 : _findFirstEnabledItem.id
        });
      }
      return nextState;
    }
    case "unregisterItem": {
      var _id2 = action.id;
      var nextItems = items.filter(function(item) {
        return item.id !== _id2;
      });
      if (nextItems.length === items.length) {
        return state;
      }
      var nextPastIds = pastIds.filter(function(pastId) {
        return pastId !== _id2;
      });
      var _nextState = _objectSpread23(_objectSpread23({}, state), {}, {
        pastIds: nextPastIds,
        items: nextItems
      });
      if (currentId && currentId === _id2) {
        var nextId = includesBaseElement ? null : getCurrentId(_objectSpread23(_objectSpread23({}, _nextState), {}, {
          currentId: nextPastIds[0]
        }));
        return _objectSpread23(_objectSpread23({}, _nextState), {}, {
          currentId: nextId
        });
      }
      return _nextState;
    }
    case "move": {
      var _id3 = action.id;
      if (_id3 === void 0) {
        return state;
      }
      var filteredPastIds = pastIds.filter(function(pastId) {
        return pastId !== currentId && pastId !== _id3;
      });
      var _nextPastIds = currentId ? [currentId].concat(filteredPastIds) : filteredPastIds;
      var _nextState2 = _objectSpread23(_objectSpread23({}, state), {}, {
        pastIds: _nextPastIds
      });
      if (_id3 === null) {
        return _objectSpread23(_objectSpread23({}, _nextState2), {}, {
          unstable_moves: moves + 1,
          currentId: getCurrentId(_nextState2, _id3)
        });
      }
      var _item2 = findEnabledItemById(items, _id3);
      return _objectSpread23(_objectSpread23({}, _nextState2), {}, {
        unstable_moves: _item2 ? moves + 1 : moves,
        currentId: getCurrentId(_nextState2, _item2 === null || _item2 === void 0 ? void 0 : _item2.id)
      });
    }
    case "next": {
      if (currentId == null) {
        return reducer(state, _objectSpread23(_objectSpread23({}, action), {}, {
          type: "first"
        }));
      }
      var isHorizontal = orientation !== "vertical";
      var isRTL = rtl && isHorizontal;
      var allItems = isRTL ? reverse(items) : items;
      var currentItem = allItems.find(function(item) {
        return item.id === currentId;
      });
      if (!currentItem) {
        return reducer(state, _objectSpread23(_objectSpread23({}, action), {}, {
          type: "first"
        }));
      }
      var isGrid = !!currentItem.groupId;
      var currentIndex = allItems.indexOf(currentItem);
      var _nextItems = allItems.slice(currentIndex + 1);
      var nextItemsInGroup = getItemsInGroup(_nextItems, currentItem.groupId);
      if (action.allTheWay) {
        var _nextItem2 = findFirstEnabledItem(isRTL ? getItemsInGroup(allItems, currentItem.groupId) : reverse(nextItemsInGroup));
        return reducer(state, _objectSpread23(_objectSpread23({}, action), {}, {
          type: "move",
          id: _nextItem2 === null || _nextItem2 === void 0 ? void 0 : _nextItem2.id
        }));
      }
      var oppositeOrientation = getOppositeOrientation(isGrid ? orientation || "horizontal" : orientation);
      var canLoop = loop && loop !== oppositeOrientation;
      var canWrap = isGrid && wrap && wrap !== oppositeOrientation;
      var hasNullItem = action.hasNullItem || !isGrid && canLoop && includesBaseElement;
      if (canLoop) {
        var loopItems = canWrap && !hasNullItem ? allItems : getItemsInGroup(allItems, currentItem.groupId);
        var sortedItems = placeItemsAfter(loopItems, currentId, hasNullItem);
        var _nextItem3 = findFirstEnabledItem(sortedItems, currentId);
        return reducer(state, _objectSpread23(_objectSpread23({}, action), {}, {
          type: "move",
          id: _nextItem3 === null || _nextItem3 === void 0 ? void 0 : _nextItem3.id
        }));
      }
      if (canWrap) {
        var _nextItem4 = findFirstEnabledItem(hasNullItem ? nextItemsInGroup : _nextItems, currentId);
        var _nextId = hasNullItem ? (_nextItem4 === null || _nextItem4 === void 0 ? void 0 : _nextItem4.id) || null : _nextItem4 === null || _nextItem4 === void 0 ? void 0 : _nextItem4.id;
        return reducer(state, _objectSpread23(_objectSpread23({}, action), {}, {
          type: "move",
          id: _nextId
        }));
      }
      var _nextItem = findFirstEnabledItem(nextItemsInGroup, currentId);
      if (!_nextItem && hasNullItem) {
        return reducer(state, _objectSpread23(_objectSpread23({}, action), {}, {
          type: "move",
          id: null
        }));
      }
      return reducer(state, _objectSpread23(_objectSpread23({}, action), {}, {
        type: "move",
        id: _nextItem === null || _nextItem === void 0 ? void 0 : _nextItem.id
      }));
    }
    case "previous": {
      var _isGrid = !!groups.length;
      var _hasNullItem = !_isGrid && includesBaseElement;
      var _nextState3 = reducer(_objectSpread23(_objectSpread23({}, state), {}, {
        items: reverse(items)
      }), _objectSpread23(_objectSpread23({}, action), {}, {
        type: "next",
        hasNullItem: _hasNullItem
      }));
      return _objectSpread23(_objectSpread23({}, _nextState3), {}, {
        items
      });
    }
    case "down": {
      var shouldShift = shift && !action.allTheWay;
      var verticalItems = verticalizeItems(flatten2(fillGroups(groupItems(items), currentId, shouldShift)));
      var _canLoop = loop && loop !== "horizontal";
      var _hasNullItem2 = _canLoop && includesBaseElement;
      var _nextState4 = reducer(_objectSpread23(_objectSpread23({}, state), {}, {
        orientation: "vertical",
        items: verticalItems
      }), _objectSpread23(_objectSpread23({}, action), {}, {
        type: "next",
        hasNullItem: _hasNullItem2
      }));
      return _objectSpread23(_objectSpread23({}, _nextState4), {}, {
        orientation,
        items
      });
    }
    case "up": {
      var _shouldShift = shift && !action.allTheWay;
      var _verticalItems = verticalizeItems(reverse(flatten2(fillGroups(groupItems(items), currentId, _shouldShift))));
      var _hasNullItem3 = includesBaseElement;
      var _nextState5 = reducer(_objectSpread23(_objectSpread23({}, state), {}, {
        orientation: "vertical",
        items: _verticalItems
      }), _objectSpread23(_objectSpread23({}, action), {}, {
        type: "next",
        hasNullItem: _hasNullItem3
      }));
      return _objectSpread23(_objectSpread23({}, _nextState5), {}, {
        orientation,
        items
      });
    }
    case "first": {
      var firstItem = findFirstEnabledItem(items);
      return reducer(state, _objectSpread23(_objectSpread23({}, action), {}, {
        type: "move",
        id: firstItem === null || firstItem === void 0 ? void 0 : firstItem.id
      }));
    }
    case "last": {
      var _nextState6 = reducer(_objectSpread23(_objectSpread23({}, state), {}, {
        items: reverse(items)
      }), _objectSpread23(_objectSpread23({}, action), {}, {
        type: "first"
      }));
      return _objectSpread23(_objectSpread23({}, _nextState6), {}, {
        items
      });
    }
    case "sort": {
      return _objectSpread23(_objectSpread23({}, state), {}, {
        items: sortBasedOnDOMPosition(items),
        groups: sortBasedOnDOMPosition(groups)
      });
    }
    case "setVirtual":
      return _objectSpread23(_objectSpread23({}, state), {}, {
        unstable_virtual: applyState(action.virtual, virtual)
      });
    case "setRTL":
      return _objectSpread23(_objectSpread23({}, state), {}, {
        rtl: applyState(action.rtl, rtl)
      });
    case "setOrientation":
      return _objectSpread23(_objectSpread23({}, state), {}, {
        orientation: applyState(action.orientation, orientation)
      });
    case "setCurrentId": {
      var nextCurrentId = getCurrentId(_objectSpread23(_objectSpread23({}, state), {}, {
        currentId: applyState(action.currentId, currentId)
      }));
      return _objectSpread23(_objectSpread23({}, state), {}, {
        currentId: nextCurrentId,
        hasSetCurrentId: true
      });
    }
    case "setLoop":
      return _objectSpread23(_objectSpread23({}, state), {}, {
        loop: applyState(action.loop, loop)
      });
    case "setWrap":
      return _objectSpread23(_objectSpread23({}, state), {}, {
        wrap: applyState(action.wrap, wrap)
      });
    case "setShift":
      return _objectSpread23(_objectSpread23({}, state), {}, {
        shift: applyState(action.shift, shift)
      });
    case "setIncludesBaseElement": {
      return _objectSpread23(_objectSpread23({}, state), {}, {
        unstable_includesBaseElement: applyState(action.includesBaseElement, includesBaseElement)
      });
    }
    case "reset":
      return _objectSpread23(_objectSpread23({}, state), {}, {
        unstable_virtual: initialVirtual,
        rtl: initialRTL,
        orientation: initialOrientation,
        currentId: getCurrentId(_objectSpread23(_objectSpread23({}, state), {}, {
          currentId: initialCurrentId
        })),
        loop: initialLoop,
        wrap: initialWrap,
        shift: initialShift,
        unstable_moves: 0,
        pastIds: []
      });
    case "setItems": {
      return _objectSpread23(_objectSpread23({}, state), {}, {
        items: action.items
      });
    }
    default:
      throw new Error();
  }
}
function useAction(fn2) {
  return useCallback7(fn2, []);
}
function useIsUnmountedRef() {
  var isUnmountedRef = useRef11(false);
  useIsomorphicEffect(function() {
    return function() {
      isUnmountedRef.current = true;
    };
  }, []);
  return isUnmountedRef;
}
function useCompositeState(initialState) {
  if (initialState === void 0) {
    initialState = {};
  }
  var _useSealedState = useSealedState(initialState), _useSealedState$unsta = _useSealedState.unstable_virtual, virtual = _useSealedState$unsta === void 0 ? false : _useSealedState$unsta, _useSealedState$rtl = _useSealedState.rtl, rtl = _useSealedState$rtl === void 0 ? false : _useSealedState$rtl, orientation = _useSealedState.orientation, currentId = _useSealedState.currentId, _useSealedState$loop = _useSealedState.loop, loop = _useSealedState$loop === void 0 ? false : _useSealedState$loop, _useSealedState$wrap = _useSealedState.wrap, wrap = _useSealedState$wrap === void 0 ? false : _useSealedState$wrap, _useSealedState$shift = _useSealedState.shift, shift = _useSealedState$shift === void 0 ? false : _useSealedState$shift, unstable_includesBaseElement = _useSealedState.unstable_includesBaseElement, sealed = _objectWithoutPropertiesLoose3(_useSealedState, ["unstable_virtual", "rtl", "orientation", "currentId", "loop", "wrap", "shift", "unstable_includesBaseElement"]);
  var idState = unstable_useIdState(sealed);
  var _React$useReducer = useReducer2(reducer, {
    unstable_virtual: virtual,
    rtl,
    orientation,
    items: [],
    groups: [],
    currentId,
    loop,
    wrap,
    shift,
    unstable_moves: 0,
    pastIds: [],
    unstable_includesBaseElement: unstable_includesBaseElement != null ? unstable_includesBaseElement : currentId === null,
    initialVirtual: virtual,
    initialRTL: rtl,
    initialOrientation: orientation,
    initialCurrentId: currentId,
    initialLoop: loop,
    initialWrap: wrap,
    initialShift: shift
  }), _React$useReducer$ = _React$useReducer[0], pastIds = _React$useReducer$.pastIds, initialVirtual = _React$useReducer$.initialVirtual, initialRTL = _React$useReducer$.initialRTL, initialOrientation = _React$useReducer$.initialOrientation, initialCurrentId = _React$useReducer$.initialCurrentId, initialLoop = _React$useReducer$.initialLoop, initialWrap = _React$useReducer$.initialWrap, initialShift = _React$useReducer$.initialShift, hasSetCurrentId = _React$useReducer$.hasSetCurrentId, state = _objectWithoutPropertiesLoose3(_React$useReducer$, ["pastIds", "initialVirtual", "initialRTL", "initialOrientation", "initialCurrentId", "initialLoop", "initialWrap", "initialShift", "hasSetCurrentId"]), dispatch = _React$useReducer[1];
  var _React$useState = useState8(false), hasActiveWidget = _React$useState[0], setHasActiveWidget = _React$useState[1];
  var isUnmountedRef = useIsUnmountedRef();
  var setItems = useCallback7(function(items) {
    return dispatch({
      type: "setItems",
      items
    });
  }, []);
  useSortBasedOnDOMPosition(state.items, setItems);
  return _objectSpread23(_objectSpread23(_objectSpread23({}, idState), state), {}, {
    unstable_hasActiveWidget: hasActiveWidget,
    unstable_setHasActiveWidget: setHasActiveWidget,
    registerItem: useAction(function(item) {
      if (isUnmountedRef.current)
        return;
      dispatch({
        type: "registerItem",
        item
      });
    }),
    unregisterItem: useAction(function(id) {
      if (isUnmountedRef.current)
        return;
      dispatch({
        type: "unregisterItem",
        id
      });
    }),
    registerGroup: useAction(function(group) {
      if (isUnmountedRef.current)
        return;
      dispatch({
        type: "registerGroup",
        group
      });
    }),
    unregisterGroup: useAction(function(id) {
      if (isUnmountedRef.current)
        return;
      dispatch({
        type: "unregisterGroup",
        id
      });
    }),
    move: useAction(function(id) {
      return dispatch({
        type: "move",
        id
      });
    }),
    next: useAction(function(allTheWay) {
      return dispatch({
        type: "next",
        allTheWay
      });
    }),
    previous: useAction(function(allTheWay) {
      return dispatch({
        type: "previous",
        allTheWay
      });
    }),
    up: useAction(function(allTheWay) {
      return dispatch({
        type: "up",
        allTheWay
      });
    }),
    down: useAction(function(allTheWay) {
      return dispatch({
        type: "down",
        allTheWay
      });
    }),
    first: useAction(function() {
      return dispatch({
        type: "first"
      });
    }),
    last: useAction(function() {
      return dispatch({
        type: "last"
      });
    }),
    sort: useAction(function() {
      return dispatch({
        type: "sort"
      });
    }),
    unstable_setVirtual: useAction(function(value) {
      return dispatch({
        type: "setVirtual",
        virtual: value
      });
    }),
    setRTL: useAction(function(value) {
      return dispatch({
        type: "setRTL",
        rtl: value
      });
    }),
    setOrientation: useAction(function(value) {
      return dispatch({
        type: "setOrientation",
        orientation: value
      });
    }),
    setCurrentId: useAction(function(value) {
      return dispatch({
        type: "setCurrentId",
        currentId: value
      });
    }),
    setLoop: useAction(function(value) {
      return dispatch({
        type: "setLoop",
        loop: value
      });
    }),
    setWrap: useAction(function(value) {
      return dispatch({
        type: "setWrap",
        wrap: value
      });
    }),
    setShift: useAction(function(value) {
      return dispatch({
        type: "setShift",
        shift: value
      });
    }),
    unstable_setIncludesBaseElement: useAction(function(value) {
      return dispatch({
        type: "setIncludesBaseElement",
        includesBaseElement: value
      });
    }),
    reset: useAction(function() {
      return dispatch({
        type: "reset"
      });
    })
  });
}

// ../../../node_modules/@popperjs/core/lib/enums.js
var top = "top";
var bottom = "bottom";
var right = "right";
var left = "left";
var auto = "auto";
var basePlacements = [top, bottom, right, left];
var start = "start";
var end = "end";
var clippingParents = "clippingParents";
var viewport = "viewport";
var popper = "popper";
var reference = "reference";
var variationPlacements = /* @__PURE__ */ basePlacements.reduce(function(acc, placement) {
  return acc.concat([placement + "-" + start, placement + "-" + end]);
}, []);
var placements = /* @__PURE__ */ [].concat(basePlacements, [auto]).reduce(function(acc, placement) {
  return acc.concat([placement, placement + "-" + start, placement + "-" + end]);
}, []);
var beforeRead = "beforeRead";
var read = "read";
var afterRead = "afterRead";
var beforeMain = "beforeMain";
var main = "main";
var afterMain = "afterMain";
var beforeWrite = "beforeWrite";
var write = "write";
var afterWrite = "afterWrite";
var modifierPhases = [beforeRead, read, afterRead, beforeMain, main, afterMain, beforeWrite, write, afterWrite];

// ../../../node_modules/@popperjs/core/lib/dom-utils/getNodeName.js
function getNodeName(element) {
  return element ? (element.nodeName || "").toLowerCase() : null;
}

// ../../../node_modules/@popperjs/core/lib/dom-utils/getWindow.js
function getWindow2(node) {
  if (node.toString() !== "[object Window]") {
    var ownerDocument = node.ownerDocument;
    return ownerDocument ? ownerDocument.defaultView || window : window;
  }
  return node;
}

// ../../../node_modules/@popperjs/core/lib/dom-utils/instanceOf.js
function isElement(node) {
  var OwnElement = getWindow2(node).Element;
  return node instanceof OwnElement || node instanceof Element;
}
function isHTMLElement(node) {
  var OwnElement = getWindow2(node).HTMLElement;
  return node instanceof OwnElement || node instanceof HTMLElement;
}
function isShadowRoot(node) {
  var OwnElement = getWindow2(node).ShadowRoot;
  return node instanceof OwnElement || node instanceof ShadowRoot;
}

// ../../../node_modules/@popperjs/core/lib/modifiers/applyStyles.js
function applyStyles(_ref) {
  var state = _ref.state;
  Object.keys(state.elements).forEach(function(name) {
    var style = state.styles[name] || {};
    var attributes = state.attributes[name] || {};
    var element = state.elements[name];
    if (!isHTMLElement(element) || !getNodeName(element)) {
      return;
    }
    Object.assign(element.style, style);
    Object.keys(attributes).forEach(function(name2) {
      var value = attributes[name2];
      if (value === false) {
        element.removeAttribute(name2);
      } else {
        element.setAttribute(name2, value === true ? "" : value);
      }
    });
  });
}
function effect(_ref2) {
  var state = _ref2.state;
  var initialStyles = {
    popper: {
      position: state.options.strategy,
      left: "0",
      top: "0",
      margin: "0"
    },
    arrow: {
      position: "absolute"
    },
    reference: {}
  };
  Object.assign(state.elements.popper.style, initialStyles.popper);
  if (state.elements.arrow) {
    Object.assign(state.elements.arrow.style, initialStyles.arrow);
  }
  return function() {
    Object.keys(state.elements).forEach(function(name) {
      var element = state.elements[name];
      var attributes = state.attributes[name] || {};
      var styleProperties = Object.keys(state.styles.hasOwnProperty(name) ? state.styles[name] : initialStyles[name]);
      var style = styleProperties.reduce(function(style2, property) {
        style2[property] = "";
        return style2;
      }, {});
      if (!isHTMLElement(element) || !getNodeName(element)) {
        return;
      }
      Object.assign(element.style, style);
      Object.keys(attributes).forEach(function(attribute) {
        element.removeAttribute(attribute);
      });
    });
  };
}
var applyStyles_default = {
  name: "applyStyles",
  enabled: true,
  phase: "write",
  fn: applyStyles,
  effect,
  requires: ["computeStyles"]
};

// ../../../node_modules/@popperjs/core/lib/utils/getBasePlacement.js
function getBasePlacement(placement) {
  return placement.split("-")[0];
}

// ../../../node_modules/@popperjs/core/lib/dom-utils/getLayoutRect.js
function getLayoutRect(element) {
  return {
    x: element.offsetLeft,
    y: element.offsetTop,
    width: element.offsetWidth,
    height: element.offsetHeight
  };
}

// ../../../node_modules/@popperjs/core/lib/dom-utils/contains.js
function contains2(parent, child) {
  var rootNode = child.getRootNode && child.getRootNode();
  if (parent.contains(child)) {
    return true;
  } else if (rootNode && isShadowRoot(rootNode)) {
    var next = child;
    do {
      if (next && parent.isSameNode(next)) {
        return true;
      }
      next = next.parentNode || next.host;
    } while (next);
  }
  return false;
}

// ../../../node_modules/@popperjs/core/lib/dom-utils/getComputedStyle.js
function getComputedStyle(element) {
  return getWindow2(element).getComputedStyle(element);
}

// ../../../node_modules/@popperjs/core/lib/dom-utils/isTableElement.js
function isTableElement(element) {
  return ["table", "td", "th"].indexOf(getNodeName(element)) >= 0;
}

// ../../../node_modules/@popperjs/core/lib/dom-utils/getDocumentElement.js
function getDocumentElement(element) {
  return ((isElement(element) ? element.ownerDocument : element.document) || window.document).documentElement;
}

// ../../../node_modules/@popperjs/core/lib/dom-utils/getParentNode.js
function getParentNode(element) {
  if (getNodeName(element) === "html") {
    return element;
  }
  return element.assignedSlot || element.parentNode || element.host || getDocumentElement(element);
}

// ../../../node_modules/@popperjs/core/lib/dom-utils/getOffsetParent.js
function getTrueOffsetParent(element) {
  if (!isHTMLElement(element) || getComputedStyle(element).position === "fixed") {
    return null;
  }
  var offsetParent = element.offsetParent;
  if (offsetParent) {
    var html = getDocumentElement(offsetParent);
    if (getNodeName(offsetParent) === "body" && getComputedStyle(offsetParent).position === "static" && getComputedStyle(html).position !== "static") {
      return html;
    }
  }
  return offsetParent;
}
function getContainingBlock(element) {
  var currentNode = getParentNode(element);
  while (isHTMLElement(currentNode) && ["html", "body"].indexOf(getNodeName(currentNode)) < 0) {
    var css = getComputedStyle(currentNode);
    if (css.transform !== "none" || css.perspective !== "none" || css.willChange && css.willChange !== "auto") {
      return currentNode;
    } else {
      currentNode = currentNode.parentNode;
    }
  }
  return null;
}
function getOffsetParent(element) {
  var window2 = getWindow2(element);
  var offsetParent = getTrueOffsetParent(element);
  while (offsetParent && isTableElement(offsetParent) && getComputedStyle(offsetParent).position === "static") {
    offsetParent = getTrueOffsetParent(offsetParent);
  }
  if (offsetParent && getNodeName(offsetParent) === "body" && getComputedStyle(offsetParent).position === "static") {
    return window2;
  }
  return offsetParent || getContainingBlock(element) || window2;
}

// ../../../node_modules/@popperjs/core/lib/utils/getMainAxisFromPlacement.js
function getMainAxisFromPlacement(placement) {
  return ["top", "bottom"].indexOf(placement) >= 0 ? "x" : "y";
}

// ../../../node_modules/@popperjs/core/lib/utils/within.js
function within(min, value, max) {
  return Math.max(min, Math.min(value, max));
}

// ../../../node_modules/@popperjs/core/lib/utils/getFreshSideObject.js
function getFreshSideObject() {
  return {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  };
}

// ../../../node_modules/@popperjs/core/lib/utils/mergePaddingObject.js
function mergePaddingObject(paddingObject) {
  return Object.assign(Object.assign({}, getFreshSideObject()), paddingObject);
}

// ../../../node_modules/@popperjs/core/lib/utils/expandToHashMap.js
function expandToHashMap(value, keys) {
  return keys.reduce(function(hashMap, key) {
    hashMap[key] = value;
    return hashMap;
  }, {});
}

// ../../../node_modules/@popperjs/core/lib/modifiers/arrow.js
function arrow(_ref) {
  var _state$modifiersData$;
  var state = _ref.state, name = _ref.name;
  var arrowElement = state.elements.arrow;
  var popperOffsets2 = state.modifiersData.popperOffsets;
  var basePlacement = getBasePlacement(state.placement);
  var axis = getMainAxisFromPlacement(basePlacement);
  var isVertical = [left, right].indexOf(basePlacement) >= 0;
  var len = isVertical ? "height" : "width";
  if (!arrowElement || !popperOffsets2) {
    return;
  }
  var paddingObject = state.modifiersData[name + "#persistent"].padding;
  var arrowRect = getLayoutRect(arrowElement);
  var minProp = axis === "y" ? top : left;
  var maxProp = axis === "y" ? bottom : right;
  var endDiff = state.rects.reference[len] + state.rects.reference[axis] - popperOffsets2[axis] - state.rects.popper[len];
  var startDiff = popperOffsets2[axis] - state.rects.reference[axis];
  var arrowOffsetParent = getOffsetParent(arrowElement);
  var clientSize = arrowOffsetParent ? axis === "y" ? arrowOffsetParent.clientHeight || 0 : arrowOffsetParent.clientWidth || 0 : 0;
  var centerToReference = endDiff / 2 - startDiff / 2;
  var min = paddingObject[minProp];
  var max = clientSize - arrowRect[len] - paddingObject[maxProp];
  var center = clientSize / 2 - arrowRect[len] / 2 + centerToReference;
  var offset2 = within(min, center, max);
  var axisProp = axis;
  state.modifiersData[name] = (_state$modifiersData$ = {}, _state$modifiersData$[axisProp] = offset2, _state$modifiersData$.centerOffset = offset2 - center, _state$modifiersData$);
}
function effect2(_ref2) {
  var state = _ref2.state, options = _ref2.options, name = _ref2.name;
  var _options$element = options.element, arrowElement = _options$element === void 0 ? "[data-popper-arrow]" : _options$element, _options$padding = options.padding, padding = _options$padding === void 0 ? 0 : _options$padding;
  if (arrowElement == null) {
    return;
  }
  if (typeof arrowElement === "string") {
    arrowElement = state.elements.popper.querySelector(arrowElement);
    if (!arrowElement) {
      return;
    }
  }
  if (true) {
    if (!isHTMLElement(arrowElement)) {
      console.error(['Popper: "arrow" element must be an HTMLElement (not an SVGElement).', "To use an SVG arrow, wrap it in an HTMLElement that will be used as", "the arrow."].join(" "));
    }
  }
  if (!contains2(state.elements.popper, arrowElement)) {
    if (true) {
      console.error(['Popper: "arrow" modifier\'s `element` must be a child of the popper', "element."].join(" "));
    }
    return;
  }
  state.elements.arrow = arrowElement;
  state.modifiersData[name + "#persistent"] = {
    padding: mergePaddingObject(typeof padding !== "number" ? padding : expandToHashMap(padding, basePlacements))
  };
}
var arrow_default = {
  name: "arrow",
  enabled: true,
  phase: "main",
  fn: arrow,
  effect: effect2,
  requires: ["popperOffsets"],
  requiresIfExists: ["preventOverflow"]
};

// ../../../node_modules/@popperjs/core/lib/modifiers/computeStyles.js
var unsetSides = {
  top: "auto",
  right: "auto",
  bottom: "auto",
  left: "auto"
};
function roundOffsetsByDPR(_ref) {
  var x = _ref.x, y = _ref.y;
  var win = window;
  var dpr = win.devicePixelRatio || 1;
  return {
    x: Math.round(x * dpr) / dpr || 0,
    y: Math.round(y * dpr) / dpr || 0
  };
}
function mapToStyles(_ref2) {
  var _Object$assign2;
  var popper2 = _ref2.popper, popperRect = _ref2.popperRect, placement = _ref2.placement, offsets = _ref2.offsets, position = _ref2.position, gpuAcceleration = _ref2.gpuAcceleration, adaptive = _ref2.adaptive, roundOffsets = _ref2.roundOffsets;
  var _ref3 = roundOffsets ? roundOffsetsByDPR(offsets) : offsets, _ref3$x = _ref3.x, x = _ref3$x === void 0 ? 0 : _ref3$x, _ref3$y = _ref3.y, y = _ref3$y === void 0 ? 0 : _ref3$y;
  var hasX = offsets.hasOwnProperty("x");
  var hasY = offsets.hasOwnProperty("y");
  var sideX = left;
  var sideY = top;
  var win = window;
  if (adaptive) {
    var offsetParent = getOffsetParent(popper2);
    if (offsetParent === getWindow2(popper2)) {
      offsetParent = getDocumentElement(popper2);
    }
    if (placement === top) {
      sideY = bottom;
      y -= offsetParent.clientHeight - popperRect.height;
      y *= gpuAcceleration ? 1 : -1;
    }
    if (placement === left) {
      sideX = right;
      x -= offsetParent.clientWidth - popperRect.width;
      x *= gpuAcceleration ? 1 : -1;
    }
  }
  var commonStyles = Object.assign({
    position
  }, adaptive && unsetSides);
  if (gpuAcceleration) {
    var _Object$assign;
    return Object.assign(Object.assign({}, commonStyles), {}, (_Object$assign = {}, _Object$assign[sideY] = hasY ? "0" : "", _Object$assign[sideX] = hasX ? "0" : "", _Object$assign.transform = (win.devicePixelRatio || 1) < 2 ? "translate(" + x + "px, " + y + "px)" : "translate3d(" + x + "px, " + y + "px, 0)", _Object$assign));
  }
  return Object.assign(Object.assign({}, commonStyles), {}, (_Object$assign2 = {}, _Object$assign2[sideY] = hasY ? y + "px" : "", _Object$assign2[sideX] = hasX ? x + "px" : "", _Object$assign2.transform = "", _Object$assign2));
}
function computeStyles(_ref4) {
  var state = _ref4.state, options = _ref4.options;
  var _options$gpuAccelerat = options.gpuAcceleration, gpuAcceleration = _options$gpuAccelerat === void 0 ? true : _options$gpuAccelerat, _options$adaptive = options.adaptive, adaptive = _options$adaptive === void 0 ? true : _options$adaptive, _options$roundOffsets = options.roundOffsets, roundOffsets = _options$roundOffsets === void 0 ? true : _options$roundOffsets;
  if (true) {
    var transitionProperty = getComputedStyle(state.elements.popper).transitionProperty || "";
    if (adaptive && ["transform", "top", "right", "bottom", "left"].some(function(property) {
      return transitionProperty.indexOf(property) >= 0;
    })) {
      console.warn(["Popper: Detected CSS transitions on at least one of the following", 'CSS properties: "transform", "top", "right", "bottom", "left".', "\n\n", 'Disable the "computeStyles" modifier\'s `adaptive` option to allow', "for smooth transitions, or remove these properties from the CSS", "transition declaration on the popper element if only transitioning", "opacity or background-color for example.", "\n\n", "We recommend using the popper element as a wrapper around an inner", "element that can have any CSS property transitioned for animations."].join(" "));
    }
  }
  var commonStyles = {
    placement: getBasePlacement(state.placement),
    popper: state.elements.popper,
    popperRect: state.rects.popper,
    gpuAcceleration
  };
  if (state.modifiersData.popperOffsets != null) {
    state.styles.popper = Object.assign(Object.assign({}, state.styles.popper), mapToStyles(Object.assign(Object.assign({}, commonStyles), {}, {
      offsets: state.modifiersData.popperOffsets,
      position: state.options.strategy,
      adaptive,
      roundOffsets
    })));
  }
  if (state.modifiersData.arrow != null) {
    state.styles.arrow = Object.assign(Object.assign({}, state.styles.arrow), mapToStyles(Object.assign(Object.assign({}, commonStyles), {}, {
      offsets: state.modifiersData.arrow,
      position: "absolute",
      adaptive: false,
      roundOffsets
    })));
  }
  state.attributes.popper = Object.assign(Object.assign({}, state.attributes.popper), {}, {
    "data-popper-placement": state.placement
  });
}
var computeStyles_default = {
  name: "computeStyles",
  enabled: true,
  phase: "beforeWrite",
  fn: computeStyles,
  data: {}
};

// ../../../node_modules/@popperjs/core/lib/modifiers/eventListeners.js
var passive = {
  passive: true
};
function effect3(_ref) {
  var state = _ref.state, instance = _ref.instance, options = _ref.options;
  var _options$scroll = options.scroll, scroll = _options$scroll === void 0 ? true : _options$scroll, _options$resize = options.resize, resize = _options$resize === void 0 ? true : _options$resize;
  var window2 = getWindow2(state.elements.popper);
  var scrollParents = [].concat(state.scrollParents.reference, state.scrollParents.popper);
  if (scroll) {
    scrollParents.forEach(function(scrollParent) {
      scrollParent.addEventListener("scroll", instance.update, passive);
    });
  }
  if (resize) {
    window2.addEventListener("resize", instance.update, passive);
  }
  return function() {
    if (scroll) {
      scrollParents.forEach(function(scrollParent) {
        scrollParent.removeEventListener("scroll", instance.update, passive);
      });
    }
    if (resize) {
      window2.removeEventListener("resize", instance.update, passive);
    }
  };
}
var eventListeners_default = {
  name: "eventListeners",
  enabled: true,
  phase: "write",
  fn: function fn() {
  },
  effect: effect3,
  data: {}
};

// ../../../node_modules/@popperjs/core/lib/utils/getOppositePlacement.js
var hash = {
  left: "right",
  right: "left",
  bottom: "top",
  top: "bottom"
};
function getOppositePlacement(placement) {
  return placement.replace(/left|right|bottom|top/g, function(matched) {
    return hash[matched];
  });
}

// ../../../node_modules/@popperjs/core/lib/utils/getOppositeVariationPlacement.js
var hash2 = {
  start: "end",
  end: "start"
};
function getOppositeVariationPlacement(placement) {
  return placement.replace(/start|end/g, function(matched) {
    return hash2[matched];
  });
}

// ../../../node_modules/@popperjs/core/lib/dom-utils/getBoundingClientRect.js
function getBoundingClientRect(element) {
  var rect = element.getBoundingClientRect();
  return {
    width: rect.width,
    height: rect.height,
    top: rect.top,
    right: rect.right,
    bottom: rect.bottom,
    left: rect.left,
    x: rect.left,
    y: rect.top
  };
}

// ../../../node_modules/@popperjs/core/lib/dom-utils/getWindowScroll.js
function getWindowScroll(node) {
  var win = getWindow2(node);
  var scrollLeft = win.pageXOffset;
  var scrollTop = win.pageYOffset;
  return {
    scrollLeft,
    scrollTop
  };
}

// ../../../node_modules/@popperjs/core/lib/dom-utils/getWindowScrollBarX.js
function getWindowScrollBarX(element) {
  return getBoundingClientRect(getDocumentElement(element)).left + getWindowScroll(element).scrollLeft;
}

// ../../../node_modules/@popperjs/core/lib/dom-utils/getViewportRect.js
function getViewportRect(element) {
  var win = getWindow2(element);
  var html = getDocumentElement(element);
  var visualViewport = win.visualViewport;
  var width = html.clientWidth;
  var height = html.clientHeight;
  var x = 0;
  var y = 0;
  if (visualViewport) {
    width = visualViewport.width;
    height = visualViewport.height;
    if (!/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
      x = visualViewport.offsetLeft;
      y = visualViewport.offsetTop;
    }
  }
  return {
    width,
    height,
    x: x + getWindowScrollBarX(element),
    y
  };
}

// ../../../node_modules/@popperjs/core/lib/dom-utils/getDocumentRect.js
function getDocumentRect(element) {
  var html = getDocumentElement(element);
  var winScroll = getWindowScroll(element);
  var body = element.ownerDocument.body;
  var width = Math.max(html.scrollWidth, html.clientWidth, body ? body.scrollWidth : 0, body ? body.clientWidth : 0);
  var height = Math.max(html.scrollHeight, html.clientHeight, body ? body.scrollHeight : 0, body ? body.clientHeight : 0);
  var x = -winScroll.scrollLeft + getWindowScrollBarX(element);
  var y = -winScroll.scrollTop;
  if (getComputedStyle(body || html).direction === "rtl") {
    x += Math.max(html.clientWidth, body ? body.clientWidth : 0) - width;
  }
  return {
    width,
    height,
    x,
    y
  };
}

// ../../../node_modules/@popperjs/core/lib/dom-utils/isScrollParent.js
function isScrollParent(element) {
  var _getComputedStyle = getComputedStyle(element), overflow = _getComputedStyle.overflow, overflowX = _getComputedStyle.overflowX, overflowY = _getComputedStyle.overflowY;
  return /auto|scroll|overlay|hidden/.test(overflow + overflowY + overflowX);
}

// ../../../node_modules/@popperjs/core/lib/dom-utils/getScrollParent.js
function getScrollParent(node) {
  if (["html", "body", "#document"].indexOf(getNodeName(node)) >= 0) {
    return node.ownerDocument.body;
  }
  if (isHTMLElement(node) && isScrollParent(node)) {
    return node;
  }
  return getScrollParent(getParentNode(node));
}

// ../../../node_modules/@popperjs/core/lib/dom-utils/listScrollParents.js
function listScrollParents(element, list) {
  if (list === void 0) {
    list = [];
  }
  var scrollParent = getScrollParent(element);
  var isBody = getNodeName(scrollParent) === "body";
  var win = getWindow2(scrollParent);
  var target = isBody ? [win].concat(win.visualViewport || [], isScrollParent(scrollParent) ? scrollParent : []) : scrollParent;
  var updatedList = list.concat(target);
  return isBody ? updatedList : updatedList.concat(listScrollParents(getParentNode(target)));
}

// ../../../node_modules/@popperjs/core/lib/utils/rectToClientRect.js
function rectToClientRect(rect) {
  return Object.assign(Object.assign({}, rect), {}, {
    left: rect.x,
    top: rect.y,
    right: rect.x + rect.width,
    bottom: rect.y + rect.height
  });
}

// ../../../node_modules/@popperjs/core/lib/dom-utils/getClippingRect.js
function getInnerBoundingClientRect(element) {
  var rect = getBoundingClientRect(element);
  rect.top = rect.top + element.clientTop;
  rect.left = rect.left + element.clientLeft;
  rect.bottom = rect.top + element.clientHeight;
  rect.right = rect.left + element.clientWidth;
  rect.width = element.clientWidth;
  rect.height = element.clientHeight;
  rect.x = rect.left;
  rect.y = rect.top;
  return rect;
}
function getClientRectFromMixedType(element, clippingParent) {
  return clippingParent === viewport ? rectToClientRect(getViewportRect(element)) : isHTMLElement(clippingParent) ? getInnerBoundingClientRect(clippingParent) : rectToClientRect(getDocumentRect(getDocumentElement(element)));
}
function getClippingParents(element) {
  var clippingParents2 = listScrollParents(getParentNode(element));
  var canEscapeClipping = ["absolute", "fixed"].indexOf(getComputedStyle(element).position) >= 0;
  var clipperElement = canEscapeClipping && isHTMLElement(element) ? getOffsetParent(element) : element;
  if (!isElement(clipperElement)) {
    return [];
  }
  return clippingParents2.filter(function(clippingParent) {
    return isElement(clippingParent) && contains2(clippingParent, clipperElement) && getNodeName(clippingParent) !== "body";
  });
}
function getClippingRect(element, boundary, rootBoundary) {
  var mainClippingParents = boundary === "clippingParents" ? getClippingParents(element) : [].concat(boundary);
  var clippingParents2 = [].concat(mainClippingParents, [rootBoundary]);
  var firstClippingParent = clippingParents2[0];
  var clippingRect = clippingParents2.reduce(function(accRect, clippingParent) {
    var rect = getClientRectFromMixedType(element, clippingParent);
    accRect.top = Math.max(rect.top, accRect.top);
    accRect.right = Math.min(rect.right, accRect.right);
    accRect.bottom = Math.min(rect.bottom, accRect.bottom);
    accRect.left = Math.max(rect.left, accRect.left);
    return accRect;
  }, getClientRectFromMixedType(element, firstClippingParent));
  clippingRect.width = clippingRect.right - clippingRect.left;
  clippingRect.height = clippingRect.bottom - clippingRect.top;
  clippingRect.x = clippingRect.left;
  clippingRect.y = clippingRect.top;
  return clippingRect;
}

// ../../../node_modules/@popperjs/core/lib/utils/getVariation.js
function getVariation(placement) {
  return placement.split("-")[1];
}

// ../../../node_modules/@popperjs/core/lib/utils/computeOffsets.js
function computeOffsets(_ref) {
  var reference2 = _ref.reference, element = _ref.element, placement = _ref.placement;
  var basePlacement = placement ? getBasePlacement(placement) : null;
  var variation = placement ? getVariation(placement) : null;
  var commonX = reference2.x + reference2.width / 2 - element.width / 2;
  var commonY = reference2.y + reference2.height / 2 - element.height / 2;
  var offsets;
  switch (basePlacement) {
    case top:
      offsets = {
        x: commonX,
        y: reference2.y - element.height
      };
      break;
    case bottom:
      offsets = {
        x: commonX,
        y: reference2.y + reference2.height
      };
      break;
    case right:
      offsets = {
        x: reference2.x + reference2.width,
        y: commonY
      };
      break;
    case left:
      offsets = {
        x: reference2.x - element.width,
        y: commonY
      };
      break;
    default:
      offsets = {
        x: reference2.x,
        y: reference2.y
      };
  }
  var mainAxis = basePlacement ? getMainAxisFromPlacement(basePlacement) : null;
  if (mainAxis != null) {
    var len = mainAxis === "y" ? "height" : "width";
    switch (variation) {
      case start:
        offsets[mainAxis] = offsets[mainAxis] - (reference2[len] / 2 - element[len] / 2);
        break;
      case end:
        offsets[mainAxis] = offsets[mainAxis] + (reference2[len] / 2 - element[len] / 2);
        break;
      default:
    }
  }
  return offsets;
}

// ../../../node_modules/@popperjs/core/lib/utils/detectOverflow.js
function detectOverflow(state, options) {
  if (options === void 0) {
    options = {};
  }
  var _options = options, _options$placement = _options.placement, placement = _options$placement === void 0 ? state.placement : _options$placement, _options$boundary = _options.boundary, boundary = _options$boundary === void 0 ? clippingParents : _options$boundary, _options$rootBoundary = _options.rootBoundary, rootBoundary = _options$rootBoundary === void 0 ? viewport : _options$rootBoundary, _options$elementConte = _options.elementContext, elementContext = _options$elementConte === void 0 ? popper : _options$elementConte, _options$altBoundary = _options.altBoundary, altBoundary = _options$altBoundary === void 0 ? false : _options$altBoundary, _options$padding = _options.padding, padding = _options$padding === void 0 ? 0 : _options$padding;
  var paddingObject = mergePaddingObject(typeof padding !== "number" ? padding : expandToHashMap(padding, basePlacements));
  var altContext = elementContext === popper ? reference : popper;
  var referenceElement = state.elements.reference;
  var popperRect = state.rects.popper;
  var element = state.elements[altBoundary ? altContext : elementContext];
  var clippingClientRect = getClippingRect(isElement(element) ? element : element.contextElement || getDocumentElement(state.elements.popper), boundary, rootBoundary);
  var referenceClientRect = getBoundingClientRect(referenceElement);
  var popperOffsets2 = computeOffsets({
    reference: referenceClientRect,
    element: popperRect,
    strategy: "absolute",
    placement
  });
  var popperClientRect = rectToClientRect(Object.assign(Object.assign({}, popperRect), popperOffsets2));
  var elementClientRect = elementContext === popper ? popperClientRect : referenceClientRect;
  var overflowOffsets = {
    top: clippingClientRect.top - elementClientRect.top + paddingObject.top,
    bottom: elementClientRect.bottom - clippingClientRect.bottom + paddingObject.bottom,
    left: clippingClientRect.left - elementClientRect.left + paddingObject.left,
    right: elementClientRect.right - clippingClientRect.right + paddingObject.right
  };
  var offsetData = state.modifiersData.offset;
  if (elementContext === popper && offsetData) {
    var offset2 = offsetData[placement];
    Object.keys(overflowOffsets).forEach(function(key) {
      var multiply = [right, bottom].indexOf(key) >= 0 ? 1 : -1;
      var axis = [top, bottom].indexOf(key) >= 0 ? "y" : "x";
      overflowOffsets[key] += offset2[axis] * multiply;
    });
  }
  return overflowOffsets;
}

// ../../../node_modules/@popperjs/core/lib/utils/computeAutoPlacement.js
function computeAutoPlacement(state, options) {
  if (options === void 0) {
    options = {};
  }
  var _options = options, placement = _options.placement, boundary = _options.boundary, rootBoundary = _options.rootBoundary, padding = _options.padding, flipVariations = _options.flipVariations, _options$allowedAutoP = _options.allowedAutoPlacements, allowedAutoPlacements = _options$allowedAutoP === void 0 ? placements : _options$allowedAutoP;
  var variation = getVariation(placement);
  var placements2 = variation ? flipVariations ? variationPlacements : variationPlacements.filter(function(placement2) {
    return getVariation(placement2) === variation;
  }) : basePlacements;
  var allowedPlacements = placements2.filter(function(placement2) {
    return allowedAutoPlacements.indexOf(placement2) >= 0;
  });
  if (allowedPlacements.length === 0) {
    allowedPlacements = placements2;
    if (true) {
      console.error(["Popper: The `allowedAutoPlacements` option did not allow any", "placements. Ensure the `placement` option matches the variation", "of the allowed placements.", 'For example, "auto" cannot be used to allow "bottom-start".', 'Use "auto-start" instead.'].join(" "));
    }
  }
  var overflows = allowedPlacements.reduce(function(acc, placement2) {
    acc[placement2] = detectOverflow(state, {
      placement: placement2,
      boundary,
      rootBoundary,
      padding
    })[getBasePlacement(placement2)];
    return acc;
  }, {});
  return Object.keys(overflows).sort(function(a, b) {
    return overflows[a] - overflows[b];
  });
}

// ../../../node_modules/@popperjs/core/lib/modifiers/flip.js
function getExpandedFallbackPlacements(placement) {
  if (getBasePlacement(placement) === auto) {
    return [];
  }
  var oppositePlacement = getOppositePlacement(placement);
  return [getOppositeVariationPlacement(placement), oppositePlacement, getOppositeVariationPlacement(oppositePlacement)];
}
function flip(_ref) {
  var state = _ref.state, options = _ref.options, name = _ref.name;
  if (state.modifiersData[name]._skip) {
    return;
  }
  var _options$mainAxis = options.mainAxis, checkMainAxis = _options$mainAxis === void 0 ? true : _options$mainAxis, _options$altAxis = options.altAxis, checkAltAxis = _options$altAxis === void 0 ? true : _options$altAxis, specifiedFallbackPlacements = options.fallbackPlacements, padding = options.padding, boundary = options.boundary, rootBoundary = options.rootBoundary, altBoundary = options.altBoundary, _options$flipVariatio = options.flipVariations, flipVariations = _options$flipVariatio === void 0 ? true : _options$flipVariatio, allowedAutoPlacements = options.allowedAutoPlacements;
  var preferredPlacement = state.options.placement;
  var basePlacement = getBasePlacement(preferredPlacement);
  var isBasePlacement = basePlacement === preferredPlacement;
  var fallbackPlacements = specifiedFallbackPlacements || (isBasePlacement || !flipVariations ? [getOppositePlacement(preferredPlacement)] : getExpandedFallbackPlacements(preferredPlacement));
  var placements2 = [preferredPlacement].concat(fallbackPlacements).reduce(function(acc, placement2) {
    return acc.concat(getBasePlacement(placement2) === auto ? computeAutoPlacement(state, {
      placement: placement2,
      boundary,
      rootBoundary,
      padding,
      flipVariations,
      allowedAutoPlacements
    }) : placement2);
  }, []);
  var referenceRect = state.rects.reference;
  var popperRect = state.rects.popper;
  var checksMap = new Map();
  var makeFallbackChecks = true;
  var firstFittingPlacement = placements2[0];
  for (var i = 0; i < placements2.length; i++) {
    var placement = placements2[i];
    var _basePlacement = getBasePlacement(placement);
    var isStartVariation = getVariation(placement) === start;
    var isVertical = [top, bottom].indexOf(_basePlacement) >= 0;
    var len = isVertical ? "width" : "height";
    var overflow = detectOverflow(state, {
      placement,
      boundary,
      rootBoundary,
      altBoundary,
      padding
    });
    var mainVariationSide = isVertical ? isStartVariation ? right : left : isStartVariation ? bottom : top;
    if (referenceRect[len] > popperRect[len]) {
      mainVariationSide = getOppositePlacement(mainVariationSide);
    }
    var altVariationSide = getOppositePlacement(mainVariationSide);
    var checks = [];
    if (checkMainAxis) {
      checks.push(overflow[_basePlacement] <= 0);
    }
    if (checkAltAxis) {
      checks.push(overflow[mainVariationSide] <= 0, overflow[altVariationSide] <= 0);
    }
    if (checks.every(function(check) {
      return check;
    })) {
      firstFittingPlacement = placement;
      makeFallbackChecks = false;
      break;
    }
    checksMap.set(placement, checks);
  }
  if (makeFallbackChecks) {
    var numberOfChecks = flipVariations ? 3 : 1;
    var _loop = function _loop2(_i2) {
      var fittingPlacement = placements2.find(function(placement2) {
        var checks2 = checksMap.get(placement2);
        if (checks2) {
          return checks2.slice(0, _i2).every(function(check) {
            return check;
          });
        }
      });
      if (fittingPlacement) {
        firstFittingPlacement = fittingPlacement;
        return "break";
      }
    };
    for (var _i = numberOfChecks; _i > 0; _i--) {
      var _ret = _loop(_i);
      if (_ret === "break")
        break;
    }
  }
  if (state.placement !== firstFittingPlacement) {
    state.modifiersData[name]._skip = true;
    state.placement = firstFittingPlacement;
    state.reset = true;
  }
}
var flip_default = {
  name: "flip",
  enabled: true,
  phase: "main",
  fn: flip,
  requiresIfExists: ["offset"],
  data: {
    _skip: false
  }
};

// ../../../node_modules/@popperjs/core/lib/modifiers/hide.js
function getSideOffsets(overflow, rect, preventedOffsets) {
  if (preventedOffsets === void 0) {
    preventedOffsets = {
      x: 0,
      y: 0
    };
  }
  return {
    top: overflow.top - rect.height - preventedOffsets.y,
    right: overflow.right - rect.width + preventedOffsets.x,
    bottom: overflow.bottom - rect.height + preventedOffsets.y,
    left: overflow.left - rect.width - preventedOffsets.x
  };
}
function isAnySideFullyClipped(overflow) {
  return [top, right, bottom, left].some(function(side) {
    return overflow[side] >= 0;
  });
}
function hide(_ref) {
  var state = _ref.state, name = _ref.name;
  var referenceRect = state.rects.reference;
  var popperRect = state.rects.popper;
  var preventedOffsets = state.modifiersData.preventOverflow;
  var referenceOverflow = detectOverflow(state, {
    elementContext: "reference"
  });
  var popperAltOverflow = detectOverflow(state, {
    altBoundary: true
  });
  var referenceClippingOffsets = getSideOffsets(referenceOverflow, referenceRect);
  var popperEscapeOffsets = getSideOffsets(popperAltOverflow, popperRect, preventedOffsets);
  var isReferenceHidden = isAnySideFullyClipped(referenceClippingOffsets);
  var hasPopperEscaped = isAnySideFullyClipped(popperEscapeOffsets);
  state.modifiersData[name] = {
    referenceClippingOffsets,
    popperEscapeOffsets,
    isReferenceHidden,
    hasPopperEscaped
  };
  state.attributes.popper = Object.assign(Object.assign({}, state.attributes.popper), {}, {
    "data-popper-reference-hidden": isReferenceHidden,
    "data-popper-escaped": hasPopperEscaped
  });
}
var hide_default = {
  name: "hide",
  enabled: true,
  phase: "main",
  requiresIfExists: ["preventOverflow"],
  fn: hide
};

// ../../../node_modules/@popperjs/core/lib/modifiers/offset.js
function distanceAndSkiddingToXY(placement, rects, offset2) {
  var basePlacement = getBasePlacement(placement);
  var invertDistance = [left, top].indexOf(basePlacement) >= 0 ? -1 : 1;
  var _ref = typeof offset2 === "function" ? offset2(Object.assign(Object.assign({}, rects), {}, {
    placement
  })) : offset2, skidding = _ref[0], distance = _ref[1];
  skidding = skidding || 0;
  distance = (distance || 0) * invertDistance;
  return [left, right].indexOf(basePlacement) >= 0 ? {
    x: distance,
    y: skidding
  } : {
    x: skidding,
    y: distance
  };
}
function offset(_ref2) {
  var state = _ref2.state, options = _ref2.options, name = _ref2.name;
  var _options$offset = options.offset, offset2 = _options$offset === void 0 ? [0, 0] : _options$offset;
  var data = placements.reduce(function(acc, placement) {
    acc[placement] = distanceAndSkiddingToXY(placement, state.rects, offset2);
    return acc;
  }, {});
  var _data$state$placement = data[state.placement], x = _data$state$placement.x, y = _data$state$placement.y;
  if (state.modifiersData.popperOffsets != null) {
    state.modifiersData.popperOffsets.x += x;
    state.modifiersData.popperOffsets.y += y;
  }
  state.modifiersData[name] = data;
}
var offset_default = {
  name: "offset",
  enabled: true,
  phase: "main",
  requires: ["popperOffsets"],
  fn: offset
};

// ../../../node_modules/@popperjs/core/lib/modifiers/popperOffsets.js
function popperOffsets(_ref) {
  var state = _ref.state, name = _ref.name;
  state.modifiersData[name] = computeOffsets({
    reference: state.rects.reference,
    element: state.rects.popper,
    strategy: "absolute",
    placement: state.placement
  });
}
var popperOffsets_default = {
  name: "popperOffsets",
  enabled: true,
  phase: "read",
  fn: popperOffsets,
  data: {}
};

// ../../../node_modules/@popperjs/core/lib/utils/getAltAxis.js
function getAltAxis(axis) {
  return axis === "x" ? "y" : "x";
}

// ../../../node_modules/@popperjs/core/lib/modifiers/preventOverflow.js
function preventOverflow(_ref) {
  var state = _ref.state, options = _ref.options, name = _ref.name;
  var _options$mainAxis = options.mainAxis, checkMainAxis = _options$mainAxis === void 0 ? true : _options$mainAxis, _options$altAxis = options.altAxis, checkAltAxis = _options$altAxis === void 0 ? false : _options$altAxis, boundary = options.boundary, rootBoundary = options.rootBoundary, altBoundary = options.altBoundary, padding = options.padding, _options$tether = options.tether, tether = _options$tether === void 0 ? true : _options$tether, _options$tetherOffset = options.tetherOffset, tetherOffset = _options$tetherOffset === void 0 ? 0 : _options$tetherOffset;
  var overflow = detectOverflow(state, {
    boundary,
    rootBoundary,
    padding,
    altBoundary
  });
  var basePlacement = getBasePlacement(state.placement);
  var variation = getVariation(state.placement);
  var isBasePlacement = !variation;
  var mainAxis = getMainAxisFromPlacement(basePlacement);
  var altAxis = getAltAxis(mainAxis);
  var popperOffsets2 = state.modifiersData.popperOffsets;
  var referenceRect = state.rects.reference;
  var popperRect = state.rects.popper;
  var tetherOffsetValue = typeof tetherOffset === "function" ? tetherOffset(Object.assign(Object.assign({}, state.rects), {}, {
    placement: state.placement
  })) : tetherOffset;
  var data = {
    x: 0,
    y: 0
  };
  if (!popperOffsets2) {
    return;
  }
  if (checkMainAxis) {
    var mainSide = mainAxis === "y" ? top : left;
    var altSide = mainAxis === "y" ? bottom : right;
    var len = mainAxis === "y" ? "height" : "width";
    var offset2 = popperOffsets2[mainAxis];
    var min = popperOffsets2[mainAxis] + overflow[mainSide];
    var max = popperOffsets2[mainAxis] - overflow[altSide];
    var additive = tether ? -popperRect[len] / 2 : 0;
    var minLen = variation === start ? referenceRect[len] : popperRect[len];
    var maxLen = variation === start ? -popperRect[len] : -referenceRect[len];
    var arrowElement = state.elements.arrow;
    var arrowRect = tether && arrowElement ? getLayoutRect(arrowElement) : {
      width: 0,
      height: 0
    };
    var arrowPaddingObject = state.modifiersData["arrow#persistent"] ? state.modifiersData["arrow#persistent"].padding : getFreshSideObject();
    var arrowPaddingMin = arrowPaddingObject[mainSide];
    var arrowPaddingMax = arrowPaddingObject[altSide];
    var arrowLen = within(0, referenceRect[len], arrowRect[len]);
    var minOffset = isBasePlacement ? referenceRect[len] / 2 - additive - arrowLen - arrowPaddingMin - tetherOffsetValue : minLen - arrowLen - arrowPaddingMin - tetherOffsetValue;
    var maxOffset = isBasePlacement ? -referenceRect[len] / 2 + additive + arrowLen + arrowPaddingMax + tetherOffsetValue : maxLen + arrowLen + arrowPaddingMax + tetherOffsetValue;
    var arrowOffsetParent = state.elements.arrow && getOffsetParent(state.elements.arrow);
    var clientOffset = arrowOffsetParent ? mainAxis === "y" ? arrowOffsetParent.clientTop || 0 : arrowOffsetParent.clientLeft || 0 : 0;
    var offsetModifierValue = state.modifiersData.offset ? state.modifiersData.offset[state.placement][mainAxis] : 0;
    var tetherMin = popperOffsets2[mainAxis] + minOffset - offsetModifierValue - clientOffset;
    var tetherMax = popperOffsets2[mainAxis] + maxOffset - offsetModifierValue;
    var preventedOffset = within(tether ? Math.min(min, tetherMin) : min, offset2, tether ? Math.max(max, tetherMax) : max);
    popperOffsets2[mainAxis] = preventedOffset;
    data[mainAxis] = preventedOffset - offset2;
  }
  if (checkAltAxis) {
    var _mainSide = mainAxis === "x" ? top : left;
    var _altSide = mainAxis === "x" ? bottom : right;
    var _offset = popperOffsets2[altAxis];
    var _min = _offset + overflow[_mainSide];
    var _max = _offset - overflow[_altSide];
    var _preventedOffset = within(_min, _offset, _max);
    popperOffsets2[altAxis] = _preventedOffset;
    data[altAxis] = _preventedOffset - _offset;
  }
  state.modifiersData[name] = data;
}
var preventOverflow_default = {
  name: "preventOverflow",
  enabled: true,
  phase: "main",
  fn: preventOverflow,
  requiresIfExists: ["offset"]
};

// ../../../node_modules/@popperjs/core/lib/dom-utils/getHTMLElementScroll.js
function getHTMLElementScroll(element) {
  return {
    scrollLeft: element.scrollLeft,
    scrollTop: element.scrollTop
  };
}

// ../../../node_modules/@popperjs/core/lib/dom-utils/getNodeScroll.js
function getNodeScroll(node) {
  if (node === getWindow2(node) || !isHTMLElement(node)) {
    return getWindowScroll(node);
  } else {
    return getHTMLElementScroll(node);
  }
}

// ../../../node_modules/@popperjs/core/lib/dom-utils/getCompositeRect.js
function getCompositeRect(elementOrVirtualElement, offsetParent, isFixed) {
  if (isFixed === void 0) {
    isFixed = false;
  }
  var documentElement = getDocumentElement(offsetParent);
  var rect = getBoundingClientRect(elementOrVirtualElement);
  var isOffsetParentAnElement = isHTMLElement(offsetParent);
  var scroll = {
    scrollLeft: 0,
    scrollTop: 0
  };
  var offsets = {
    x: 0,
    y: 0
  };
  if (isOffsetParentAnElement || !isOffsetParentAnElement && !isFixed) {
    if (getNodeName(offsetParent) !== "body" || isScrollParent(documentElement)) {
      scroll = getNodeScroll(offsetParent);
    }
    if (isHTMLElement(offsetParent)) {
      offsets = getBoundingClientRect(offsetParent);
      offsets.x += offsetParent.clientLeft;
      offsets.y += offsetParent.clientTop;
    } else if (documentElement) {
      offsets.x = getWindowScrollBarX(documentElement);
    }
  }
  return {
    x: rect.left + scroll.scrollLeft - offsets.x,
    y: rect.top + scroll.scrollTop - offsets.y,
    width: rect.width,
    height: rect.height
  };
}

// ../../../node_modules/@popperjs/core/lib/utils/orderModifiers.js
function order(modifiers) {
  var map2 = new Map();
  var visited = new Set();
  var result = [];
  modifiers.forEach(function(modifier) {
    map2.set(modifier.name, modifier);
  });
  function sort(modifier) {
    visited.add(modifier.name);
    var requires = [].concat(modifier.requires || [], modifier.requiresIfExists || []);
    requires.forEach(function(dep) {
      if (!visited.has(dep)) {
        var depModifier = map2.get(dep);
        if (depModifier) {
          sort(depModifier);
        }
      }
    });
    result.push(modifier);
  }
  modifiers.forEach(function(modifier) {
    if (!visited.has(modifier.name)) {
      sort(modifier);
    }
  });
  return result;
}
function orderModifiers(modifiers) {
  var orderedModifiers = order(modifiers);
  return modifierPhases.reduce(function(acc, phase) {
    return acc.concat(orderedModifiers.filter(function(modifier) {
      return modifier.phase === phase;
    }));
  }, []);
}

// ../../../node_modules/@popperjs/core/lib/utils/debounce.js
function debounce(fn2) {
  var pending;
  return function() {
    if (!pending) {
      pending = new Promise(function(resolve) {
        Promise.resolve().then(function() {
          pending = void 0;
          resolve(fn2());
        });
      });
    }
    return pending;
  };
}

// ../../../node_modules/@popperjs/core/lib/utils/format.js
function format(str) {
  for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    args[_key - 1] = arguments[_key];
  }
  return [].concat(args).reduce(function(p, c) {
    return p.replace(/%s/, c);
  }, str);
}

// ../../../node_modules/@popperjs/core/lib/utils/validateModifiers.js
var INVALID_MODIFIER_ERROR = 'Popper: modifier "%s" provided an invalid %s property, expected %s but got %s';
var MISSING_DEPENDENCY_ERROR = 'Popper: modifier "%s" requires "%s", but "%s" modifier is not available';
var VALID_PROPERTIES = ["name", "enabled", "phase", "fn", "effect", "requires", "options"];
function validateModifiers(modifiers) {
  modifiers.forEach(function(modifier) {
    Object.keys(modifier).forEach(function(key) {
      switch (key) {
        case "name":
          if (typeof modifier.name !== "string") {
            console.error(format(INVALID_MODIFIER_ERROR, String(modifier.name), '"name"', '"string"', '"' + String(modifier.name) + '"'));
          }
          break;
        case "enabled":
          if (typeof modifier.enabled !== "boolean") {
            console.error(format(INVALID_MODIFIER_ERROR, modifier.name, '"enabled"', '"boolean"', '"' + String(modifier.enabled) + '"'));
          }
        case "phase":
          if (modifierPhases.indexOf(modifier.phase) < 0) {
            console.error(format(INVALID_MODIFIER_ERROR, modifier.name, '"phase"', "either " + modifierPhases.join(", "), '"' + String(modifier.phase) + '"'));
          }
          break;
        case "fn":
          if (typeof modifier.fn !== "function") {
            console.error(format(INVALID_MODIFIER_ERROR, modifier.name, '"fn"', '"function"', '"' + String(modifier.fn) + '"'));
          }
          break;
        case "effect":
          if (typeof modifier.effect !== "function") {
            console.error(format(INVALID_MODIFIER_ERROR, modifier.name, '"effect"', '"function"', '"' + String(modifier.fn) + '"'));
          }
          break;
        case "requires":
          if (!Array.isArray(modifier.requires)) {
            console.error(format(INVALID_MODIFIER_ERROR, modifier.name, '"requires"', '"array"', '"' + String(modifier.requires) + '"'));
          }
          break;
        case "requiresIfExists":
          if (!Array.isArray(modifier.requiresIfExists)) {
            console.error(format(INVALID_MODIFIER_ERROR, modifier.name, '"requiresIfExists"', '"array"', '"' + String(modifier.requiresIfExists) + '"'));
          }
          break;
        case "options":
        case "data":
          break;
        default:
          console.error('PopperJS: an invalid property has been provided to the "' + modifier.name + '" modifier, valid properties are ' + VALID_PROPERTIES.map(function(s) {
            return '"' + s + '"';
          }).join(", ") + '; but "' + key + '" was provided.');
      }
      modifier.requires && modifier.requires.forEach(function(requirement) {
        if (modifiers.find(function(mod) {
          return mod.name === requirement;
        }) == null) {
          console.error(format(MISSING_DEPENDENCY_ERROR, String(modifier.name), requirement, requirement));
        }
      });
    });
  });
}

// ../../../node_modules/@popperjs/core/lib/utils/uniqueBy.js
function uniqueBy(arr, fn2) {
  var identifiers = new Set();
  return arr.filter(function(item) {
    var identifier = fn2(item);
    if (!identifiers.has(identifier)) {
      identifiers.add(identifier);
      return true;
    }
  });
}

// ../../../node_modules/@popperjs/core/lib/utils/mergeByName.js
function mergeByName(modifiers) {
  var merged = modifiers.reduce(function(merged2, current) {
    var existing = merged2[current.name];
    merged2[current.name] = existing ? Object.assign(Object.assign(Object.assign({}, existing), current), {}, {
      options: Object.assign(Object.assign({}, existing.options), current.options),
      data: Object.assign(Object.assign({}, existing.data), current.data)
    }) : current;
    return merged2;
  }, {});
  return Object.keys(merged).map(function(key) {
    return merged[key];
  });
}

// ../../../node_modules/@popperjs/core/lib/createPopper.js
var INVALID_ELEMENT_ERROR = "Popper: Invalid reference or popper argument provided. They must be either a DOM element or virtual element.";
var INFINITE_LOOP_ERROR = "Popper: An infinite loop in the modifiers cycle has been detected! The cycle has been interrupted to prevent a browser crash.";
var DEFAULT_OPTIONS = {
  placement: "bottom",
  modifiers: [],
  strategy: "absolute"
};
function areValidElements() {
  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }
  return !args.some(function(element) {
    return !(element && typeof element.getBoundingClientRect === "function");
  });
}
function popperGenerator(generatorOptions) {
  if (generatorOptions === void 0) {
    generatorOptions = {};
  }
  var _generatorOptions = generatorOptions, _generatorOptions$def = _generatorOptions.defaultModifiers, defaultModifiers2 = _generatorOptions$def === void 0 ? [] : _generatorOptions$def, _generatorOptions$def2 = _generatorOptions.defaultOptions, defaultOptions = _generatorOptions$def2 === void 0 ? DEFAULT_OPTIONS : _generatorOptions$def2;
  return function createPopper2(reference2, popper2, options) {
    if (options === void 0) {
      options = defaultOptions;
    }
    var state = {
      placement: "bottom",
      orderedModifiers: [],
      options: Object.assign(Object.assign({}, DEFAULT_OPTIONS), defaultOptions),
      modifiersData: {},
      elements: {
        reference: reference2,
        popper: popper2
      },
      attributes: {},
      styles: {}
    };
    var effectCleanupFns = [];
    var isDestroyed = false;
    var instance = {
      state,
      setOptions: function setOptions(options2) {
        cleanupModifierEffects();
        state.options = Object.assign(Object.assign(Object.assign({}, defaultOptions), state.options), options2);
        state.scrollParents = {
          reference: isElement(reference2) ? listScrollParents(reference2) : reference2.contextElement ? listScrollParents(reference2.contextElement) : [],
          popper: listScrollParents(popper2)
        };
        var orderedModifiers = orderModifiers(mergeByName([].concat(defaultModifiers2, state.options.modifiers)));
        state.orderedModifiers = orderedModifiers.filter(function(m) {
          return m.enabled;
        });
        if (true) {
          var modifiers = uniqueBy([].concat(orderedModifiers, state.options.modifiers), function(_ref) {
            var name = _ref.name;
            return name;
          });
          validateModifiers(modifiers);
          if (getBasePlacement(state.options.placement) === auto) {
            var flipModifier = state.orderedModifiers.find(function(_ref2) {
              var name = _ref2.name;
              return name === "flip";
            });
            if (!flipModifier) {
              console.error(['Popper: "auto" placements require the "flip" modifier be', "present and enabled to work."].join(" "));
            }
          }
          var _getComputedStyle = getComputedStyle(popper2), marginTop = _getComputedStyle.marginTop, marginRight = _getComputedStyle.marginRight, marginBottom = _getComputedStyle.marginBottom, marginLeft = _getComputedStyle.marginLeft;
          if ([marginTop, marginRight, marginBottom, marginLeft].some(function(margin) {
            return parseFloat(margin);
          })) {
            console.warn(['Popper: CSS "margin" styles cannot be used to apply padding', "between the popper and its reference element or boundary.", "To replicate margin, use the `offset` modifier, as well as", "the `padding` option in the `preventOverflow` and `flip`", "modifiers."].join(" "));
          }
        }
        runModifierEffects();
        return instance.update();
      },
      forceUpdate: function forceUpdate() {
        if (isDestroyed) {
          return;
        }
        var _state$elements = state.elements, reference3 = _state$elements.reference, popper3 = _state$elements.popper;
        if (!areValidElements(reference3, popper3)) {
          if (true) {
            console.error(INVALID_ELEMENT_ERROR);
          }
          return;
        }
        state.rects = {
          reference: getCompositeRect(reference3, getOffsetParent(popper3), state.options.strategy === "fixed"),
          popper: getLayoutRect(popper3)
        };
        state.reset = false;
        state.placement = state.options.placement;
        state.orderedModifiers.forEach(function(modifier) {
          return state.modifiersData[modifier.name] = Object.assign({}, modifier.data);
        });
        var __debug_loops__ = 0;
        for (var index = 0; index < state.orderedModifiers.length; index++) {
          if (true) {
            __debug_loops__ += 1;
            if (__debug_loops__ > 100) {
              console.error(INFINITE_LOOP_ERROR);
              break;
            }
          }
          if (state.reset === true) {
            state.reset = false;
            index = -1;
            continue;
          }
          var _state$orderedModifie = state.orderedModifiers[index], fn2 = _state$orderedModifie.fn, _state$orderedModifie2 = _state$orderedModifie.options, _options = _state$orderedModifie2 === void 0 ? {} : _state$orderedModifie2, name = _state$orderedModifie.name;
          if (typeof fn2 === "function") {
            state = fn2({
              state,
              options: _options,
              name,
              instance
            }) || state;
          }
        }
      },
      update: debounce(function() {
        return new Promise(function(resolve) {
          instance.forceUpdate();
          resolve(state);
        });
      }),
      destroy: function destroy() {
        cleanupModifierEffects();
        isDestroyed = true;
      }
    };
    if (!areValidElements(reference2, popper2)) {
      if (true) {
        console.error(INVALID_ELEMENT_ERROR);
      }
      return instance;
    }
    instance.setOptions(options).then(function(state2) {
      if (!isDestroyed && options.onFirstUpdate) {
        options.onFirstUpdate(state2);
      }
    });
    function runModifierEffects() {
      state.orderedModifiers.forEach(function(_ref3) {
        var name = _ref3.name, _ref3$options = _ref3.options, options2 = _ref3$options === void 0 ? {} : _ref3$options, effect4 = _ref3.effect;
        if (typeof effect4 === "function") {
          var cleanupFn = effect4({
            state,
            name,
            instance,
            options: options2
          });
          var noopFn = function noopFn2() {
          };
          effectCleanupFns.push(cleanupFn || noopFn);
        }
      });
    }
    function cleanupModifierEffects() {
      effectCleanupFns.forEach(function(fn2) {
        return fn2();
      });
      effectCleanupFns = [];
    }
    return instance;
  };
}

// ../../../node_modules/@popperjs/core/lib/popper.js
var defaultModifiers = [eventListeners_default, popperOffsets_default, computeStyles_default, applyStyles_default, offset_default, flip_default, preventOverflow_default, arrow_default, hide_default];
var createPopper = /* @__PURE__ */ popperGenerator({
  defaultModifiers
});

// ../../../node_modules/reakit/es/Disclosure/DisclosureState.js
import {useState as useState9, useEffect as useEffect10, useCallback as useCallback8, useRef as useRef12} from "react";
function useLastValue(value) {
  var lastValue = useRef12(null);
  useIsomorphicEffect(function() {
    lastValue.current = value;
  }, [value]);
  return lastValue;
}
function useDisclosureState(initialState) {
  if (initialState === void 0) {
    initialState = {};
  }
  var _useSealedState = useSealedState(initialState), _useSealedState$visib = _useSealedState.visible, initialVisible = _useSealedState$visib === void 0 ? false : _useSealedState$visib, _useSealedState$anima = _useSealedState.animated, initialAnimated = _useSealedState$anima === void 0 ? false : _useSealedState$anima, sealed = _objectWithoutPropertiesLoose3(_useSealedState, ["visible", "animated"]);
  var id = unstable_useIdState(sealed);
  var _React$useState = useState9(initialVisible), visible = _React$useState[0], setVisible = _React$useState[1];
  var _React$useState2 = useState9(initialAnimated), animated = _React$useState2[0], setAnimated = _React$useState2[1];
  var _React$useState3 = useState9(false), animating = _React$useState3[0], setAnimating = _React$useState3[1];
  var lastVisible = useLastValue(visible);
  var visibleHasChanged = lastVisible.current != null && lastVisible.current !== visible;
  if (animated && !animating && visibleHasChanged) {
    setAnimating(true);
  }
  useEffect10(function() {
    if (typeof animated === "number" && animating) {
      var timeout = setTimeout(function() {
        return setAnimating(false);
      }, animated);
      return function() {
        clearTimeout(timeout);
      };
    }
    if (animated && animating && true) {
      var _timeout = setTimeout(function() {
        warning(animating, "It's been 8 seconds but stopAnimation has not been called. Does the disclousure element have a CSS transition?");
      }, 8e3);
      return function() {
        clearTimeout(_timeout);
      };
    }
    return function() {
    };
  }, [animated, animating]);
  var show2 = useCallback8(function() {
    return setVisible(true);
  }, []);
  var hide3 = useCallback8(function() {
    return setVisible(false);
  }, []);
  var toggle = useCallback8(function() {
    return setVisible(function(v) {
      return !v;
    });
  }, []);
  var stopAnimation = useCallback8(function() {
    return setAnimating(false);
  }, []);
  return _objectSpread23(_objectSpread23({}, id), {}, {
    visible,
    animated,
    animating,
    show: show2,
    hide: hide3,
    toggle,
    setVisible,
    setAnimated,
    stopAnimation
  });
}

// ../../../node_modules/reakit/es/Dialog/DialogState.js
import {useState as useState10, useRef as useRef13} from "react";
function useDialogState(initialState) {
  if (initialState === void 0) {
    initialState = {};
  }
  var _useSealedState = useSealedState(initialState), _useSealedState$modal = _useSealedState.modal, initialModal = _useSealedState$modal === void 0 ? true : _useSealedState$modal, sealed = _objectWithoutPropertiesLoose3(_useSealedState, ["modal"]);
  var disclosure = useDisclosureState(sealed);
  var _React$useState = useState10(initialModal), modal = _React$useState[0], setModal = _React$useState[1];
  var disclosureRef = useRef13(null);
  return _objectSpread23(_objectSpread23({}, disclosure), {}, {
    modal,
    setModal,
    unstable_disclosureRef: disclosureRef
  });
}

// ../../../node_modules/reakit/es/Popover/PopoverState.js
import {useRef as useRef14, useState as useState11, useCallback as useCallback9, useEffect as useEffect11} from "react";
var isSafari = isUA("Mac") && !isUA("Chrome") && isUA("Safari");
function applyStyles2(styles) {
  return function(prevStyles) {
    if (styles && !shallowEqual(prevStyles, styles)) {
      return styles;
    }
    return prevStyles;
  };
}
function usePopoverState(initialState) {
  if (initialState === void 0) {
    initialState = {};
  }
  var _useSealedState = useSealedState(initialState), _useSealedState$gutte = _useSealedState.gutter, gutter = _useSealedState$gutte === void 0 ? 12 : _useSealedState$gutte, _useSealedState$place = _useSealedState.placement, sealedPlacement = _useSealedState$place === void 0 ? "bottom" : _useSealedState$place, _useSealedState$unsta = _useSealedState.unstable_flip, flip2 = _useSealedState$unsta === void 0 ? true : _useSealedState$unsta, sealedOffset = _useSealedState.unstable_offset, _useSealedState$unsta2 = _useSealedState.unstable_preventOverflow, preventOverflow2 = _useSealedState$unsta2 === void 0 ? true : _useSealedState$unsta2, _useSealedState$unsta3 = _useSealedState.unstable_fixed, fixed = _useSealedState$unsta3 === void 0 ? false : _useSealedState$unsta3, _useSealedState$modal = _useSealedState.modal, modal = _useSealedState$modal === void 0 ? false : _useSealedState$modal, sealed = _objectWithoutPropertiesLoose3(_useSealedState, ["gutter", "placement", "unstable_flip", "unstable_offset", "unstable_preventOverflow", "unstable_fixed", "modal"]);
  var popper2 = useRef14(null);
  var referenceRef = useRef14(null);
  var popoverRef = useRef14(null);
  var arrowRef = useRef14(null);
  var _React$useState = useState11(sealedPlacement), originalPlacement = _React$useState[0], place = _React$useState[1];
  var _React$useState2 = useState11(sealedPlacement), placement = _React$useState2[0], setPlacement = _React$useState2[1];
  var _React$useState3 = useState11(sealedOffset || [0, gutter]), offset2 = _React$useState3[0];
  var _React$useState4 = useState11({}), popoverStyles = _React$useState4[0], setPopoverStyles = _React$useState4[1];
  var _React$useState5 = useState11({}), arrowStyles = _React$useState5[0], setArrowStyles = _React$useState5[1];
  var dialog = useDialogState(_objectSpread23({
    modal
  }, sealed));
  var update = useCallback9(function() {
    if (popper2.current) {
      popper2.current.forceUpdate();
      return true;
    }
    return false;
  }, []);
  var updateState = useCallback9(function(state) {
    if (state.placement) {
      setPlacement(state.placement);
    }
    if (state.styles) {
      setPopoverStyles(applyStyles2(state.styles.popper));
      if (arrowRef.current) {
        setArrowStyles(applyStyles2(state.styles.arrow));
      }
    }
  }, []);
  useIsomorphicEffect(function() {
    if (referenceRef.current && popoverRef.current) {
      popper2.current = createPopper(referenceRef.current, popoverRef.current, {
        placement: originalPlacement,
        strategy: fixed ? "fixed" : "absolute",
        onFirstUpdate: isSafari ? updateState : void 0,
        modifiers: [{
          name: "eventListeners",
          enabled: dialog.visible
        }, {
          name: "applyStyles",
          enabled: false
        }, {
          name: "flip",
          enabled: flip2,
          options: {
            padding: 8
          }
        }, {
          name: "offset",
          options: {
            offset: offset2
          }
        }, {
          name: "preventOverflow",
          enabled: preventOverflow2,
          options: {
            tetherOffset: function tetherOffset() {
              var _arrowRef$current;
              return ((_arrowRef$current = arrowRef.current) === null || _arrowRef$current === void 0 ? void 0 : _arrowRef$current.clientWidth) || 0;
            }
          }
        }, {
          name: "arrow",
          enabled: !!arrowRef.current,
          options: {
            element: arrowRef.current
          }
        }, {
          name: "updateState",
          phase: "write",
          requires: ["computeStyles"],
          enabled: dialog.visible && true,
          fn: function fn2(_ref) {
            var state = _ref.state;
            return updateState(state);
          }
        }]
      });
    }
    return function() {
      if (popper2.current) {
        popper2.current.destroy();
        popper2.current = null;
      }
    };
  }, [originalPlacement, fixed, dialog.visible, flip2, offset2, preventOverflow2]);
  useEffect11(function() {
    if (!dialog.visible)
      return void 0;
    var id = window.requestAnimationFrame(function() {
      var _popper$current;
      (_popper$current = popper2.current) === null || _popper$current === void 0 ? void 0 : _popper$current.forceUpdate();
    });
    return function() {
      window.cancelAnimationFrame(id);
    };
  }, [dialog.visible]);
  return _objectSpread23(_objectSpread23({}, dialog), {}, {
    unstable_referenceRef: referenceRef,
    unstable_popoverRef: popoverRef,
    unstable_arrowRef: arrowRef,
    unstable_popoverStyles: popoverStyles,
    unstable_arrowStyles: arrowStyles,
    unstable_update: update,
    unstable_originalPlacement: originalPlacement,
    placement,
    place
  });
}

// ../../../node_modules/reakit/es/__keys-e6a5cfbe.js
var DISCLOSURE_STATE_KEYS = ["baseId", "unstable_idCountRef", "visible", "animated", "animating", "setBaseId", "show", "hide", "toggle", "setVisible", "setAnimated", "stopAnimation"];
var DISCLOSURE_KEYS = DISCLOSURE_STATE_KEYS;
var DISCLOSURE_CONTENT_KEYS = DISCLOSURE_KEYS;

// ../../../node_modules/reakit/es/Disclosure/DisclosureContent.js
import {useState as useState12, useRef as useRef15, useEffect as useEffect12, useCallback as useCallback10} from "react";
var useDisclosureContent = createHook({
  name: "DisclosureContent",
  compose: useRole,
  keys: DISCLOSURE_CONTENT_KEYS,
  useProps: function useProps11(options, _ref) {
    var htmlOnTransitionEnd = _ref.onTransitionEnd, htmlOnAnimationEnd = _ref.onAnimationEnd, htmlStyle = _ref.style, htmlProps = _objectWithoutPropertiesLoose3(_ref, ["onTransitionEnd", "onAnimationEnd", "style"]);
    var animating = options.animated && options.animating;
    var _React$useState = useState12(null), transition = _React$useState[0], setTransition = _React$useState[1];
    var hidden = !options.visible && !animating;
    var style = hidden ? _objectSpread23({
      display: "none"
    }, htmlStyle) : htmlStyle;
    var onTransitionEndRef = useLiveRef(htmlOnTransitionEnd);
    var onAnimationEndRef = useLiveRef(htmlOnAnimationEnd);
    var raf = useRef15(0);
    useEffect12(function() {
      if (!options.animated)
        return void 0;
      raf.current = window.requestAnimationFrame(function() {
        raf.current = window.requestAnimationFrame(function() {
          if (options.visible) {
            setTransition("enter");
          } else if (animating) {
            setTransition("leave");
          } else {
            setTransition(null);
          }
        });
      });
      return function() {
        return window.cancelAnimationFrame(raf.current);
      };
    }, [options.animated, options.visible, animating]);
    var onEnd = useCallback10(function(event) {
      if (!isSelfTarget(event))
        return;
      if (!animating)
        return;
      if (options.animated === true) {
        var _options$stopAnimatio;
        (_options$stopAnimatio = options.stopAnimation) === null || _options$stopAnimatio === void 0 ? void 0 : _options$stopAnimatio.call(options);
      }
    }, [options.animated, animating, options.stopAnimation]);
    var onTransitionEnd = useCallback10(function(event) {
      var _onTransitionEndRef$c;
      (_onTransitionEndRef$c = onTransitionEndRef.current) === null || _onTransitionEndRef$c === void 0 ? void 0 : _onTransitionEndRef$c.call(onTransitionEndRef, event);
      onEnd(event);
    }, [onEnd]);
    var onAnimationEnd = useCallback10(function(event) {
      var _onAnimationEndRef$cu;
      (_onAnimationEndRef$cu = onAnimationEndRef.current) === null || _onAnimationEndRef$cu === void 0 ? void 0 : _onAnimationEndRef$cu.call(onAnimationEndRef, event);
      onEnd(event);
    }, [onEnd]);
    return _objectSpread23({
      id: options.baseId,
      "data-enter": transition === "enter" ? "" : void 0,
      "data-leave": transition === "leave" ? "" : void 0,
      onTransitionEnd,
      onAnimationEnd,
      hidden,
      style
    }, htmlProps);
  }
});
var DisclosureContent = createComponent({
  as: "div",
  useHook: useDisclosureContent
});

// ../../../node_modules/reakit/es/Portal/Portal.js
import {createContext as createContext3, useContext as useContext5, useState as useState13, createElement as createElement3} from "react";
import {createPortal} from "react-dom";
function getBodyElement() {
  return canUseDOM ? document.body : null;
}
var PortalContext = /* @__PURE__ */ createContext3(getBodyElement());
function Portal(_ref) {
  var children = _ref.children;
  var context = useContext5(PortalContext) || getBodyElement();
  var _React$useState = useState13(function() {
    if (canUseDOM) {
      var element = document.createElement("div");
      element.className = Portal.__className;
      return element;
    }
    return null;
  }), hostNode = _React$useState[0];
  useIsomorphicEffect(function() {
    if (!hostNode || !context)
      return void 0;
    context.appendChild(hostNode);
    return function() {
      context.removeChild(hostNode);
    };
  }, [hostNode, context]);
  if (hostNode) {
    return /* @__PURE__ */ createPortal(/* @__PURE__ */ createElement3(PortalContext.Provider, {
      value: hostNode
    }, children), hostNode);
  }
  return null;
}
Portal.__className = "__reakit-portal";
Portal.__selector = "." + Portal.__className;

// ../../../node_modules/reakit-utils/es/removeItemFromArray.js
function removeItemFromArray(array, item) {
  var index = array.indexOf(item);
  return removeIndexFromArray(array, index);
}

// ../../../node_modules/reakit/es/MenuContext-6af6cf92.js
import {createContext as createContext4, useContext as useContext6, useState as useState14, useCallback as useCallback11, useEffect as useEffect13, useMemo as useMemo4, createElement as createElement4} from "react";
var MenuContext = /* @__PURE__ */ createContext4(null);
function useMenuContext(menuRef, role, options) {
  var orphan = "unstable_orphan" in options && options.unstable_orphan;
  var parent = useContext6(MenuContext);
  var _React$useState = useState14([]), children = _React$useState[0], setChildren = _React$useState[1];
  var _ref = parent || {}, addChildToParent = _ref.addChild, removeChildFromParent = _ref.removeChild;
  var addChild = useCallback11(function(ref) {
    return setChildren(function(refs) {
      return [].concat(refs, [ref]);
    });
  }, []);
  var removeChild = useCallback11(function(ref) {
    return setChildren(function(refs) {
      return removeItemFromArray(refs, ref);
    });
  }, []);
  useEffect13(function() {
    if (!addChildToParent || orphan)
      return void 0;
    addChildToParent(menuRef);
    return function() {
      removeChildFromParent === null || removeChildFromParent === void 0 ? void 0 : removeChildFromParent(menuRef);
    };
  }, [menuRef, addChildToParent, removeChildFromParent, orphan]);
  var providerValue = useMemo4(function() {
    return {
      orientation: options.orientation,
      next: options.next,
      previous: options.previous,
      ref: menuRef,
      role,
      parent,
      children,
      addChild,
      removeChild
    };
  }, [options.orientation, options.next, options.previous, menuRef, role, parent, children, addChild, removeChild]);
  var wrapElement = useCallback11(function(element) {
    return /* @__PURE__ */ createElement4(MenuContext.Provider, {
      value: providerValue
    }, element);
  }, [providerValue]);
  return wrapElement;
}

// ../../../node_modules/reakit/es/Dialog/Dialog.js
import {useRef as useRef16, useEffect as useEffect14, useContext as useContext7, useState as useState15, useCallback as useCallback12, useMemo as useMemo5, createElement as createElement5, createContext as createContext6, useReducer as useReducer3} from "react";
import "react-dom";

// ../../../node_modules/body-scroll-lock/lib/bodyScrollLock.esm.js
function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }
    return arr2;
  } else {
    return Array.from(arr);
  }
}
var hasPassiveEvents = false;
if (typeof window !== "undefined") {
  passiveTestOptions = {
    get passive() {
      hasPassiveEvents = true;
      return void 0;
    }
  };
  window.addEventListener("testPassive", null, passiveTestOptions);
  window.removeEventListener("testPassive", null, passiveTestOptions);
}
var passiveTestOptions;
var isIosDevice = typeof window !== "undefined" && window.navigator && window.navigator.platform && (/iP(ad|hone|od)/.test(window.navigator.platform) || window.navigator.platform === "MacIntel" && window.navigator.maxTouchPoints > 1);
var locks = [];
var documentListenerAdded = false;
var initialClientY = -1;
var previousBodyOverflowSetting = void 0;
var previousBodyPaddingRight = void 0;
var allowTouchMove = function allowTouchMove2(el) {
  return locks.some(function(lock) {
    if (lock.options.allowTouchMove && lock.options.allowTouchMove(el)) {
      return true;
    }
    return false;
  });
};
var preventDefault = function preventDefault2(rawEvent) {
  var e = rawEvent || window.event;
  if (allowTouchMove(e.target)) {
    return true;
  }
  if (e.touches.length > 1)
    return true;
  if (e.preventDefault)
    e.preventDefault();
  return false;
};
var setOverflowHidden = function setOverflowHidden2(options) {
  if (previousBodyPaddingRight === void 0) {
    var _reserveScrollBarGap = !!options && options.reserveScrollBarGap === true;
    var scrollBarGap = window.innerWidth - document.documentElement.clientWidth;
    if (_reserveScrollBarGap && scrollBarGap > 0) {
      previousBodyPaddingRight = document.body.style.paddingRight;
      document.body.style.paddingRight = scrollBarGap + "px";
    }
  }
  if (previousBodyOverflowSetting === void 0) {
    previousBodyOverflowSetting = document.body.style.overflow;
    document.body.style.overflow = "hidden";
  }
};
var restoreOverflowSetting = function restoreOverflowSetting2() {
  if (previousBodyPaddingRight !== void 0) {
    document.body.style.paddingRight = previousBodyPaddingRight;
    previousBodyPaddingRight = void 0;
  }
  if (previousBodyOverflowSetting !== void 0) {
    document.body.style.overflow = previousBodyOverflowSetting;
    previousBodyOverflowSetting = void 0;
  }
};
var isTargetElementTotallyScrolled = function isTargetElementTotallyScrolled2(targetElement) {
  return targetElement ? targetElement.scrollHeight - targetElement.scrollTop <= targetElement.clientHeight : false;
};
var handleScroll = function handleScroll2(event, targetElement) {
  var clientY = event.targetTouches[0].clientY - initialClientY;
  if (allowTouchMove(event.target)) {
    return false;
  }
  if (targetElement && targetElement.scrollTop === 0 && clientY > 0) {
    return preventDefault(event);
  }
  if (isTargetElementTotallyScrolled(targetElement) && clientY < 0) {
    return preventDefault(event);
  }
  event.stopPropagation();
  return true;
};
var disableBodyScroll = function disableBodyScroll2(targetElement, options) {
  if (!targetElement) {
    console.error("disableBodyScroll unsuccessful - targetElement must be provided when calling disableBodyScroll on IOS devices.");
    return;
  }
  if (locks.some(function(lock2) {
    return lock2.targetElement === targetElement;
  })) {
    return;
  }
  var lock = {
    targetElement,
    options: options || {}
  };
  locks = [].concat(_toConsumableArray(locks), [lock]);
  if (isIosDevice) {
    targetElement.ontouchstart = function(event) {
      if (event.targetTouches.length === 1) {
        initialClientY = event.targetTouches[0].clientY;
      }
    };
    targetElement.ontouchmove = function(event) {
      if (event.targetTouches.length === 1) {
        handleScroll(event, targetElement);
      }
    };
    if (!documentListenerAdded) {
      document.addEventListener("touchmove", preventDefault, hasPassiveEvents ? {passive: false} : void 0);
      documentListenerAdded = true;
    }
  } else {
    setOverflowHidden(options);
  }
};
var enableBodyScroll = function enableBodyScroll2(targetElement) {
  if (!targetElement) {
    console.error("enableBodyScroll unsuccessful - targetElement must be provided when calling enableBodyScroll on IOS devices.");
    return;
  }
  locks = locks.filter(function(lock) {
    return lock.targetElement !== targetElement;
  });
  if (isIosDevice) {
    targetElement.ontouchstart = null;
    targetElement.ontouchmove = null;
    if (documentListenerAdded && locks.length === 0) {
      document.removeEventListener("touchmove", preventDefault, hasPassiveEvents ? {passive: false} : void 0);
      documentListenerAdded = false;
    }
  } else if (!locks.length) {
    restoreOverflowSetting();
  }
};

// ../../../node_modules/reakit/es/DialogBackdropContext-8775f78b.js
import {createContext as createContext5} from "react";
var DialogBackdropContext = /* @__PURE__ */ createContext5(void 0);

// ../../../node_modules/reakit-utils/es/isEmpty.js
function isEmpty(arg) {
  if (Array.isArray(arg))
    return !arg.length;
  if (isObject(arg))
    return !Object.keys(arg).length;
  if (arg == null)
    return true;
  if (arg === "")
    return true;
  return false;
}

// ../../../node_modules/reakit/es/__keys-ed7b48af.js
var DIALOG_STATE_KEYS = ["baseId", "unstable_idCountRef", "visible", "animated", "animating", "setBaseId", "show", "hide", "toggle", "setVisible", "setAnimated", "stopAnimation", "modal", "unstable_disclosureRef", "setModal"];
var DIALOG_KEYS = [].concat(DIALOG_STATE_KEYS, ["hideOnEsc", "hideOnClickOutside", "preventBodyScroll", "unstable_initialFocusRef", "unstable_finalFocusRef", "unstable_orphan", "unstable_autoFocusOnShow", "unstable_autoFocusOnHide"]);
var DIALOG_BACKDROP_KEYS = DIALOG_STATE_KEYS;
var DIALOG_DISCLOSURE_KEYS = DIALOG_BACKDROP_KEYS;

// ../../../node_modules/reakit/es/Dialog/Dialog.js
function useDisclosureRef(dialogRef, options) {
  var ref = useRef16(null);
  var animating = !!(options.animated && options.animating);
  useEffect14(function() {
    if (options.visible || animating)
      return void 0;
    var onFocus = function onFocus2(event) {
      var target = event.target;
      if ("focus" in target) {
        ref.current = target;
        if (options.unstable_disclosureRef) {
          options.unstable_disclosureRef.current = target;
        }
      }
    };
    var document2 = getDocument(dialogRef.current);
    document2.addEventListener("focusin", onFocus);
    return function() {
      return document2.removeEventListener("focusin", onFocus);
    };
  }, [options.visible, animating, options.unstable_disclosureRef, dialogRef]);
  useEffect14(function() {
    var _options$unstable_dis;
    if (!options.visible || animating)
      return void 0;
    var onMouseDown = function onMouseDown2(event) {
      var element = event.currentTarget;
      if (!isButton(element))
        return;
      event.preventDefault();
      element.focus();
    };
    var disclosure = ((_options$unstable_dis = options.unstable_disclosureRef) === null || _options$unstable_dis === void 0 ? void 0 : _options$unstable_dis.current) || ref.current;
    disclosure === null || disclosure === void 0 ? void 0 : disclosure.addEventListener("mousedown", onMouseDown);
    return function() {
      return disclosure === null || disclosure === void 0 ? void 0 : disclosure.removeEventListener("mousedown", onMouseDown);
    };
  }, [options.visible, animating, options.unstable_disclosureRef]);
  return options.unstable_disclosureRef || ref;
}
function usePreventBodyScroll(targetRef, options) {
  var shouldPrevent = Boolean(options.preventBodyScroll && options.visible);
  useEffect14(function() {
    var element = targetRef.current;
    if (!element || !shouldPrevent)
      return void 0;
    disableBodyScroll(element, {
      reserveScrollBarGap: true
    });
    return function() {
      return enableBodyScroll(element);
    };
  }, [targetRef, shouldPrevent]);
}
function useFocusOnShow(dialogRef, nestedDialogs, options) {
  var initialFocusRef = options.unstable_initialFocusRef;
  var shouldFocus = options.visible && options.unstable_autoFocusOnShow;
  var animating = !!(options.animated && options.animating);
  useUpdateEffect(function() {
    var dialog = dialogRef.current;
    warning(!!shouldFocus && !dialog, "[reakit/Dialog]", "Can't set initial focus on dialog because `ref` wasn't passed to the dialog element.", "See https://reakit.io/docs/dialog");
    if (!shouldFocus)
      return;
    if (!dialog)
      return;
    if (animating)
      return;
    if (nestedDialogs.some(function(child) {
      return child.current && !child.current.hidden;
    })) {
      return;
    }
    if (initialFocusRef !== null && initialFocusRef !== void 0 && initialFocusRef.current) {
      initialFocusRef.current.focus({
        preventScroll: true
      });
    } else {
      var tabbable = getFirstTabbableIn(dialog, true);
      var isActive = function isActive2() {
        return hasFocusWithin(dialog);
      };
      if (tabbable) {
        ensureFocus(tabbable, {
          preventScroll: true,
          isActive
        });
      } else {
        ensureFocus(dialog, {
          preventScroll: true,
          isActive
        });
        warning(dialog.tabIndex === void 0 || dialog.tabIndex < 0, "It's recommended to have at least one tabbable element inside dialog. The dialog element has been automatically focused.", "If this is the intended behavior, pass `tabIndex={0}` to the dialog element to disable this warning.", "See https://reakit.io/docs/dialog/#initial-focus", dialog);
      }
    }
  }, [dialogRef, shouldFocus, animating, nestedDialogs, initialFocusRef]);
}
function usePortalRef(dialogRef, options) {
  var portalRef = useRef16(null);
  useEffect14(function() {
    var dialog = dialogRef.current;
    if (!dialog || !options.visible)
      return;
    portalRef.current = closest(dialog, Portal.__selector);
  }, [dialogRef, options.visible]);
  return portalRef;
}
function removeFromDOM(element) {
  if (element.parentNode == null)
    return;
  element.parentNode.removeChild(element);
}
var focusTrapClassName = "__reakit-focus-trap";
function isFocusTrap(element) {
  var _element$classList;
  return (_element$classList = element.classList) === null || _element$classList === void 0 ? void 0 : _element$classList.contains(focusTrapClassName);
}
function useFocusTrap(dialogRef, visibleModals, options) {
  var portalRef = usePortalRef(dialogRef, options);
  var shouldTrap = options.visible && options.modal;
  var beforeElement = useRef16(null);
  var afterElement = useRef16(null);
  useEffect14(function() {
    if (!shouldTrap)
      return void 0;
    var portal = portalRef.current;
    if (!portal) {
      warning(true, "Can't trap focus within modal dialog because either `ref` wasn't passed to component or the component wasn't rendered within a portal", "See https://reakit.io/docs/dialog");
      return void 0;
    }
    if (!beforeElement.current) {
      var document2 = getDocument(portal);
      beforeElement.current = document2.createElement("div");
      beforeElement.current.className = focusTrapClassName;
      beforeElement.current.tabIndex = 0;
      beforeElement.current.style.position = "fixed";
      beforeElement.current.setAttribute("aria-hidden", "true");
    }
    if (!afterElement.current) {
      afterElement.current = beforeElement.current.cloneNode();
    }
    portal.insertAdjacentElement("beforebegin", beforeElement.current);
    portal.insertAdjacentElement("afterend", afterElement.current);
    return function() {
      if (beforeElement.current)
        removeFromDOM(beforeElement.current);
      if (afterElement.current)
        removeFromDOM(afterElement.current);
    };
  }, [portalRef, shouldTrap]);
  useEffect14(function() {
    var before = beforeElement.current;
    var after = afterElement.current;
    if (!shouldTrap || !before || !after)
      return void 0;
    var handleFocus = function handleFocus2(event) {
      var dialog = dialogRef.current;
      if (!dialog || visibleModals.length)
        return;
      event.preventDefault();
      var isAfter = event.target === after;
      var tabbable = isAfter ? getFirstTabbableIn(dialog) : getLastTabbableIn(dialog);
      if (tabbable) {
        tabbable.focus();
      } else {
        dialog.focus();
      }
    };
    before.addEventListener("focus", handleFocus);
    after.addEventListener("focus", handleFocus);
    return function() {
      before.removeEventListener("focus", handleFocus);
      after.removeEventListener("focus", handleFocus);
    };
  }, [dialogRef, visibleModals, shouldTrap]);
}
function hidByFocusingAnotherElement(dialogRef) {
  var dialog = dialogRef.current;
  if (!dialog)
    return false;
  var activeElement = getActiveElement(dialog);
  if (!activeElement)
    return false;
  if (contains(dialog, activeElement))
    return false;
  if (isTabbable(activeElement))
    return true;
  if (activeElement.getAttribute("data-dialog") === "true")
    return true;
  return false;
}
function useFocusOnHide(dialogRef, disclosureRef, options) {
  var shouldFocus = options.unstable_autoFocusOnHide && !options.visible;
  var animating = !!(options.animated && options.animating);
  useUpdateEffect(function() {
    var _options$unstable_fin;
    if (!shouldFocus)
      return;
    if (animating)
      return;
    if (hidByFocusingAnotherElement(dialogRef)) {
      return;
    }
    var finalFocusEl = ((_options$unstable_fin = options.unstable_finalFocusRef) === null || _options$unstable_fin === void 0 ? void 0 : _options$unstable_fin.current) || disclosureRef.current;
    if (finalFocusEl) {
      if (finalFocusEl.id) {
        var document2 = getDocument(finalFocusEl);
        var compositeElement = document2.querySelector("[aria-activedescendant='" + finalFocusEl.id + "']");
        if (compositeElement) {
          ensureFocus(compositeElement);
          return;
        }
      }
      ensureFocus(finalFocusEl);
      return;
    }
    warning(true, "Can't return focus after closing dialog. Either render a disclosure component or provide a `unstable_finalFocusRef` prop.", "See https://reakit.io/docs/dialog", dialogRef.current);
  }, [shouldFocus, animating, dialogRef, disclosureRef]);
}
var DialogContext = /* @__PURE__ */ createContext6({});
function useNestedDialogs(dialogRef, options) {
  var context = useContext7(DialogContext);
  var _React$useState = useState15([]), dialogs = _React$useState[0], setDialogs = _React$useState[1];
  var _React$useState2 = useState15(dialogs), visibleModals = _React$useState2[0], setVisibleModals = _React$useState2[1];
  var addDialog = useCallback12(function(ref) {
    var _context$addDialog;
    (_context$addDialog = context.addDialog) === null || _context$addDialog === void 0 ? void 0 : _context$addDialog.call(context, ref);
    setDialogs(function(prevDialogs) {
      return [].concat(prevDialogs, [ref]);
    });
  }, [context.addDialog]);
  var removeDialog = useCallback12(function(ref) {
    var _context$removeDialog;
    (_context$removeDialog = context.removeDialog) === null || _context$removeDialog === void 0 ? void 0 : _context$removeDialog.call(context, ref);
    setDialogs(function(prevDialogs) {
      return removeItemFromArray(prevDialogs, ref);
    });
  }, [context.removeDialog]);
  var showDialog = useCallback12(function(ref) {
    var _context$showDialog;
    (_context$showDialog = context.showDialog) === null || _context$showDialog === void 0 ? void 0 : _context$showDialog.call(context, ref);
    setVisibleModals(function(prevDialogs) {
      return [].concat(prevDialogs, [ref]);
    });
  }, [context.showDialog]);
  var hideDialog = useCallback12(function(ref) {
    var _context$hideDialog;
    (_context$hideDialog = context.hideDialog) === null || _context$hideDialog === void 0 ? void 0 : _context$hideDialog.call(context, ref);
    setVisibleModals(function(prevDialogs) {
      return removeItemFromArray(prevDialogs, ref);
    });
  }, [context.hideDialog]);
  useEffect14(function() {
    var _context$addDialog2;
    if (options.unstable_orphan)
      return void 0;
    (_context$addDialog2 = context.addDialog) === null || _context$addDialog2 === void 0 ? void 0 : _context$addDialog2.call(context, dialogRef);
    return function() {
      var _context$removeDialog2;
      (_context$removeDialog2 = context.removeDialog) === null || _context$removeDialog2 === void 0 ? void 0 : _context$removeDialog2.call(context, dialogRef);
    };
  }, [options.unstable_orphan, context.addDialog, dialogRef, context.removeDialog]);
  useEffect14(function() {
    var _context$showDialog2;
    if (options.unstable_orphan)
      return void 0;
    if (!options.modal)
      return void 0;
    if (!options.visible)
      return void 0;
    (_context$showDialog2 = context.showDialog) === null || _context$showDialog2 === void 0 ? void 0 : _context$showDialog2.call(context, dialogRef);
    return function() {
      var _context$hideDialog2;
      (_context$hideDialog2 = context.hideDialog) === null || _context$hideDialog2 === void 0 ? void 0 : _context$hideDialog2.call(context, dialogRef);
    };
  }, [options.unstable_orphan, options.modal, options.visible, context.showDialog, dialogRef, context.hideDialog]);
  useEffect14(function() {
    if (context.visible === false && options.visible && !options.unstable_orphan) {
      var _options$hide;
      (_options$hide = options.hide) === null || _options$hide === void 0 ? void 0 : _options$hide.call(options);
    }
  }, [context.visible, options.visible, options.hide, options.unstable_orphan]);
  var providerValue = useMemo5(function() {
    return {
      visible: options.visible,
      addDialog,
      removeDialog,
      showDialog,
      hideDialog
    };
  }, [options.visible, addDialog, removeDialog, showDialog, hideDialog]);
  var wrap = useCallback12(function(element) {
    return /* @__PURE__ */ createElement5(DialogContext.Provider, {
      value: providerValue
    }, element);
  }, [providerValue]);
  return {
    dialogs,
    visibleModals,
    wrap
  };
}
function dialogContains(target) {
  return function(dialogRef) {
    var dialog = dialogRef.current;
    if (!dialog)
      return false;
    if (contains(dialog, target))
      return true;
    var document2 = getDocument(dialog);
    var backdrop = document2.querySelector('[data-dialog-ref="' + dialog.id + '"]');
    if (backdrop) {
      return contains(backdrop, target);
    }
    return false;
  };
}
function isDisclosure(target, disclosure) {
  return contains(disclosure, target);
}
function isInDocument(target) {
  var document2 = getDocument(target);
  if (target.tagName === "HTML") {
    return true;
  }
  return contains(document2.body, target);
}
function useEventListenerOutside(containerRef, disclosureRef, nestedDialogs, eventType, listener, shouldListen, capture) {
  var listenerRef = useLiveRef(listener);
  useEffect14(function() {
    if (!shouldListen)
      return void 0;
    var onEvent = function onEvent2(event) {
      if (!listenerRef.current)
        return;
      var container = containerRef.current;
      var disclosure = disclosureRef.current;
      var target = event.target;
      if (!container) {
        warning(true, "Can't detect events outside dialog because `ref` wasn't passed to component.", "See https://reakit.io/docs/dialog");
        return;
      }
      if (!isInDocument(target))
        return;
      if (contains(container, target))
        return;
      if (disclosure && isDisclosure(target, disclosure))
        return;
      if (isFocusTrap(target) || nestedDialogs.some(dialogContains(target))) {
        return;
      }
      listenerRef.current(event);
    };
    var document2 = getDocument(containerRef.current);
    document2.addEventListener(eventType, onEvent, capture);
    return function() {
      return document2.removeEventListener(eventType, onEvent, capture);
    };
  }, [containerRef, disclosureRef, nestedDialogs, eventType, shouldListen, listenerRef]);
}
function useMouseDownRef(dialogRef, options) {
  var mouseDownRef = useRef16();
  useEffect14(function() {
    if (!options.visible)
      return void 0;
    if (!options.hideOnClickOutside)
      return void 0;
    var document2 = getDocument(dialogRef.current);
    var onMouseDown = function onMouseDown2(event) {
      mouseDownRef.current = event.target;
    };
    document2.addEventListener("mousedown", onMouseDown);
    return function() {
      return document2.removeEventListener("mousedown", onMouseDown);
    };
  }, [options.visible, options.hideOnClickOutside, dialogRef]);
  return mouseDownRef;
}
function useHideOnClickOutside(dialogRef, disclosureRef, nestedDialogs, options) {
  var mouseDownRef = useMouseDownRef(dialogRef, options);
  useEventListenerOutside(dialogRef, disclosureRef, nestedDialogs, "click", function(event) {
    if (mouseDownRef.current === event.target) {
      var _options$hide;
      (_options$hide = options.hide) === null || _options$hide === void 0 ? void 0 : _options$hide.call(options);
    }
  }, options.visible && options.hideOnClickOutside);
  useEventListenerOutside(dialogRef, disclosureRef, nestedDialogs, "focusin", function(event) {
    var document2 = getDocument(dialogRef.current);
    if (event.target !== document2 && event.target !== document2.body) {
      var _options$hide2;
      (_options$hide2 = options.hide) === null || _options$hide2 === void 0 ? void 0 : _options$hide2.call(options);
    }
  }, options.visible && options.hideOnClickOutside);
}
function useDisableHoverOutside(portalRef, nestedDialogs, options) {
  var useEvent = function useEvent2(eventType) {
    return useEventListenerOutside(portalRef, {
      current: null
    }, nestedDialogs, eventType, function(event) {
      event.stopPropagation();
      event.preventDefault();
    }, options.visible && options.modal, true);
  };
  useEvent("mouseover");
  useEvent("mousemove");
  useEvent("mouseout");
}
function useFocusOnChildUnmount(dialogRef, options) {
  useEffect14(function() {
    var dialog = dialogRef.current;
    if (!options.visible || !dialog)
      return void 0;
    var observer = new MutationObserver(function(mutations) {
      var target = mutations[0].target;
      if (target !== dialog)
        return;
      var document2 = getDocument(dialog);
      var activeElement = getActiveElement(dialog);
      if (activeElement === document2.body || isEmpty(activeElement)) {
        dialog.focus();
      }
    });
    observer.observe(dialog, {
      childList: true,
      subtree: true
    });
    return function() {
      observer.disconnect();
    };
  }, [options.visible, dialogRef]);
}
function isActualElement(element) {
  return element && element.tagName && element.tagName !== "HTML" && element !== getDocument(element).body;
}
function useFocusOnBlur(dialogRef, options) {
  var _React$useReducer = useReducer3(function(n) {
    return n + 1;
  }, 0), blurred = _React$useReducer[0], scheduleFocus = _React$useReducer[1];
  useIsomorphicEffect(function() {
    var dialog = dialogRef.current;
    if (!options.visible)
      return;
    if (!blurred)
      return;
    if (!isActualElement(getActiveElement(dialog))) {
      warning(!dialog, "Can't focus dialog after a nested element got blurred because `ref` wasn't passed to the component", "See https://reakit.io/docs/dialog");
      dialog === null || dialog === void 0 ? void 0 : dialog.focus();
    }
  }, [blurred, dialogRef]);
  var onBlur = useCallback12(function(event) {
    if (!options.visible)
      return;
    var nextActiveElement = getNextActiveElementOnBlur(event);
    if (!isActualElement(nextActiveElement)) {
      scheduleFocus();
    }
  }, [options.visible]);
  return onBlur;
}
var useDialog = createHook({
  name: "Dialog",
  compose: useDisclosureContent,
  keys: DIALOG_KEYS,
  useOptions: function useOptions8(_ref) {
    var _ref$modal = _ref.modal, modal = _ref$modal === void 0 ? true : _ref$modal, _ref$hideOnEsc = _ref.hideOnEsc, hideOnEsc = _ref$hideOnEsc === void 0 ? true : _ref$hideOnEsc, _ref$hideOnClickOutsi = _ref.hideOnClickOutside, hideOnClickOutside = _ref$hideOnClickOutsi === void 0 ? true : _ref$hideOnClickOutsi, _ref$preventBodyScrol = _ref.preventBodyScroll, preventBodyScroll = _ref$preventBodyScrol === void 0 ? modal : _ref$preventBodyScrol, _ref$unstable_autoFoc = _ref.unstable_autoFocusOnShow, unstable_autoFocusOnShow = _ref$unstable_autoFoc === void 0 ? true : _ref$unstable_autoFoc, _ref$unstable_autoFoc2 = _ref.unstable_autoFocusOnHide, unstable_autoFocusOnHide = _ref$unstable_autoFoc2 === void 0 ? true : _ref$unstable_autoFoc2, unstable_orphan = _ref.unstable_orphan, options = _objectWithoutPropertiesLoose3(_ref, ["modal", "hideOnEsc", "hideOnClickOutside", "preventBodyScroll", "unstable_autoFocusOnShow", "unstable_autoFocusOnHide", "unstable_orphan"]);
    return _objectSpread23({
      modal,
      hideOnEsc,
      hideOnClickOutside,
      preventBodyScroll: modal && preventBodyScroll,
      unstable_autoFocusOnShow,
      unstable_autoFocusOnHide,
      unstable_orphan: modal && unstable_orphan
    }, options);
  },
  useProps: function useProps12(options, _ref2) {
    var htmlRef = _ref2.ref, htmlOnKeyDown = _ref2.onKeyDown, htmlOnBlur = _ref2.onBlur, htmlWrapElement = _ref2.wrapElement, tabIndex = _ref2.tabIndex, htmlProps = _objectWithoutPropertiesLoose3(_ref2, ["ref", "onKeyDown", "onBlur", "wrapElement", "tabIndex"]);
    var dialog = useRef16(null);
    var backdrop = useContext7(DialogBackdropContext);
    var hasBackdrop = backdrop && backdrop === options.baseId;
    var disclosure = useDisclosureRef(dialog, options);
    var onKeyDownRef = useLiveRef(htmlOnKeyDown);
    var onBlurRef = useLiveRef(htmlOnBlur);
    var focusOnBlur = useFocusOnBlur(dialog, options);
    var _useNestedDialogs = useNestedDialogs(dialog, options), dialogs = _useNestedDialogs.dialogs, visibleModals = _useNestedDialogs.visibleModals, wrap = _useNestedDialogs.wrap;
    var modal = options.modal && !visibleModals.length ? true : void 0;
    usePreventBodyScroll(dialog, options);
    useFocusTrap(dialog, visibleModals, options);
    useFocusOnChildUnmount(dialog, options);
    useFocusOnShow(dialog, dialogs, options);
    useFocusOnHide(dialog, disclosure, options);
    useHideOnClickOutside(dialog, disclosure, dialogs, options);
    useDisableHoverOutside(dialog, dialogs, options);
    var onKeyDown = useCallback12(function(event) {
      var _onKeyDownRef$current;
      (_onKeyDownRef$current = onKeyDownRef.current) === null || _onKeyDownRef$current === void 0 ? void 0 : _onKeyDownRef$current.call(onKeyDownRef, event);
      if (event.defaultPrevented)
        return;
      if (event.key !== "Escape")
        return;
      if (!options.hideOnEsc)
        return;
      if (!options.hide) {
        warning(true, "`hideOnEsc` prop is truthy, but `hide` prop wasn't provided.", "See https://reakit.io/docs/dialog", dialog.current);
        return;
      }
      event.stopPropagation();
      options.hide();
    }, [options.hideOnEsc, options.hide]);
    var onBlur = useCallback12(function(event) {
      var _onBlurRef$current;
      (_onBlurRef$current = onBlurRef.current) === null || _onBlurRef$current === void 0 ? void 0 : _onBlurRef$current.call(onBlurRef, event);
      focusOnBlur(event);
    }, [focusOnBlur]);
    var wrapElement = useCallback12(function(element) {
      element = wrap(element);
      if (options.modal && !hasBackdrop) {
        element = /* @__PURE__ */ createElement5(Portal, null, element);
      }
      if (htmlWrapElement) {
        element = htmlWrapElement(element);
      }
      return /* @__PURE__ */ createElement5(MenuContext.Provider, {
        value: null
      }, element);
    }, [wrap, options.modal, hasBackdrop, htmlWrapElement]);
    return _objectSpread23({
      ref: useForkRef(dialog, htmlRef),
      role: "dialog",
      tabIndex: tabIndex != null ? tabIndex : -1,
      "aria-modal": modal,
      "data-dialog": true,
      onKeyDown,
      onBlur,
      wrapElement
    }, htmlProps);
  }
});
var Dialog = createComponent({
  as: "div",
  useHook: useDialog,
  useCreateElement: function useCreateElement$12(type, props, children) {
    useWarning(!props["aria-label"] && !props["aria-labelledby"], "You should provide either `aria-label` or `aria-labelledby` props.", "See https://reakit.io/docs/dialog");
    return useCreateElement(type, props, children);
  }
});

// ../../../node_modules/reakit/es/__keys-26bb1730.js
var POPOVER_STATE_KEYS = ["baseId", "unstable_idCountRef", "visible", "animated", "animating", "setBaseId", "show", "hide", "toggle", "setVisible", "setAnimated", "stopAnimation", "modal", "unstable_disclosureRef", "setModal", "unstable_referenceRef", "unstable_popoverRef", "unstable_arrowRef", "unstable_popoverStyles", "unstable_arrowStyles", "unstable_originalPlacement", "unstable_update", "placement", "place"];
var POPOVER_KEYS = POPOVER_STATE_KEYS;
var POPOVER_ARROW_KEYS = [].concat(POPOVER_KEYS, ["size"]);
var POPOVER_BACKDROP_KEYS = POPOVER_KEYS;
var POPOVER_DISCLOSURE_KEYS = POPOVER_BACKDROP_KEYS;

// ../../../node_modules/reakit/es/Popover/Popover.js
import "react";
import "react-dom";
var usePopover = createHook({
  name: "Popover",
  compose: useDialog,
  keys: POPOVER_KEYS,
  useOptions: function useOptions9(_ref) {
    var _ref$modal = _ref.modal, modal = _ref$modal === void 0 ? false : _ref$modal, options = _objectWithoutPropertiesLoose3(_ref, ["modal"]);
    return _objectSpread23({
      modal
    }, options);
  },
  useProps: function useProps13(options, _ref2) {
    var htmlRef = _ref2.ref, htmlStyle = _ref2.style, htmlProps = _objectWithoutPropertiesLoose3(_ref2, ["ref", "style"]);
    return _objectSpread23({
      ref: useForkRef(options.unstable_popoverRef, htmlRef),
      style: _objectSpread23(_objectSpread23({}, options.unstable_popoverStyles), htmlStyle)
    }, htmlProps);
  }
});
var Popover = createComponent({
  as: "div",
  useHook: usePopover,
  useCreateElement: function useCreateElement$13(type, props, children) {
    useWarning(!props["aria-label"] && !props["aria-labelledby"], "You should provide either `aria-label` or `aria-labelledby` props.", "See https://reakit.io/docs/popover");
    return useCreateElement(type, props, children);
  }
});

// ../../../node_modules/reakit/es/Disclosure/Disclosure.js
import {useCallback as useCallback13} from "react";
var useDisclosure = createHook({
  name: "Disclosure",
  compose: useButton,
  keys: DISCLOSURE_KEYS,
  useProps: function useProps14(options, _ref) {
    var htmlOnClick = _ref.onClick, ariaControls = _ref["aria-controls"], htmlProps = _objectWithoutPropertiesLoose3(_ref, ["onClick", "aria-controls"]);
    var onClickRef = useLiveRef(htmlOnClick);
    var controls = ariaControls ? ariaControls + " " + options.baseId : options.baseId;
    var onClick = useCallback13(function(event) {
      var _onClickRef$current, _options$toggle;
      (_onClickRef$current = onClickRef.current) === null || _onClickRef$current === void 0 ? void 0 : _onClickRef$current.call(onClickRef, event);
      if (event.defaultPrevented)
        return;
      (_options$toggle = options.toggle) === null || _options$toggle === void 0 ? void 0 : _options$toggle.call(options);
    }, [options.toggle]);
    return _objectSpread23({
      "aria-expanded": !!options.visible,
      "aria-controls": controls,
      onClick
    }, htmlProps);
  }
});
var Disclosure = createComponent({
  as: "button",
  memo: true,
  useHook: useDisclosure
});

// ../../../node_modules/reakit/es/Dialog/DialogDisclosure.js
import {useRef as useRef17, useState as useState16, useCallback as useCallback14} from "react";
var useDialogDisclosure = createHook({
  name: "DialogDisclosure",
  compose: useDisclosure,
  keys: DIALOG_DISCLOSURE_KEYS,
  useProps: function useProps15(options, _ref) {
    var htmlRef = _ref.ref, htmlOnClick = _ref.onClick, htmlProps = _objectWithoutPropertiesLoose3(_ref, ["ref", "onClick"]);
    var ref = useRef17(null);
    var onClickRef = useLiveRef(htmlOnClick);
    var _React$useState = useState16(false), expanded = _React$useState[0], setExpanded = _React$useState[1];
    var disclosureRef = options.unstable_disclosureRef;
    useIsomorphicEffect(function() {
      var element = ref.current;
      warning(!element, "Can't determine whether the element is the current disclosure because `ref` wasn't passed to the component", "See https://reakit.io/docs/dialog");
      if (disclosureRef && !disclosureRef.current) {
        disclosureRef.current = element;
      }
      var isCurrentDisclosure = !(disclosureRef !== null && disclosureRef !== void 0 && disclosureRef.current) || disclosureRef.current === element;
      setExpanded(!!options.visible && isCurrentDisclosure);
    }, [options.visible, disclosureRef]);
    var onClick = useCallback14(function(event) {
      var _onClickRef$current;
      (_onClickRef$current = onClickRef.current) === null || _onClickRef$current === void 0 ? void 0 : _onClickRef$current.call(onClickRef, event);
      if (event.defaultPrevented)
        return;
      if (disclosureRef) {
        disclosureRef.current = event.currentTarget;
      }
    }, [disclosureRef]);
    return _objectSpread23({
      ref: useForkRef(ref, htmlRef),
      "aria-haspopup": "dialog",
      "aria-expanded": expanded,
      onClick
    }, htmlProps);
  }
});
var DialogDisclosure = createComponent({
  as: "button",
  memo: true,
  useHook: useDialogDisclosure
});

// ../../../node_modules/reakit/es/__keys-d251e56b.js
var RADIO_STATE_KEYS = ["baseId", "unstable_idCountRef", "unstable_virtual", "rtl", "orientation", "items", "groups", "currentId", "loop", "wrap", "shift", "unstable_moves", "unstable_hasActiveWidget", "unstable_includesBaseElement", "state", "setBaseId", "registerItem", "unregisterItem", "registerGroup", "unregisterGroup", "move", "next", "previous", "up", "down", "first", "last", "sort", "unstable_setVirtual", "setRTL", "setOrientation", "setCurrentId", "setLoop", "setWrap", "setShift", "reset", "unstable_setIncludesBaseElement", "unstable_setHasActiveWidget", "setState"];
var RADIO_KEYS = [].concat(RADIO_STATE_KEYS, ["value", "checked", "unstable_checkOnFocus"]);

// ../../../node_modules/reakit/es/Radio/Radio.js
import {useRef as useRef18, useState as useState17, useEffect as useEffect15, useCallback as useCallback15} from "react";
function getChecked2(options) {
  if (typeof options.checked !== "undefined") {
    return options.checked;
  }
  return typeof options.value !== "undefined" && options.state === options.value;
}
function useInitialChecked(options) {
  var _React$useState = useState17(function() {
    return getChecked2(options);
  }), initialChecked = _React$useState[0];
  var _React$useState2 = useState17(options.currentId), initialCurrentId = _React$useState2[0];
  var id = options.id, setCurrentId = options.setCurrentId;
  useEffect15(function() {
    if (initialChecked && id && initialCurrentId !== id) {
      setCurrentId === null || setCurrentId === void 0 ? void 0 : setCurrentId(id);
    }
  }, [initialChecked, id, setCurrentId, initialCurrentId]);
}
function fireChange2(element, onChange) {
  var event = createEvent(element, "change");
  Object.defineProperties(event, {
    type: {
      value: "change"
    },
    target: {
      value: element
    },
    currentTarget: {
      value: element
    }
  });
  onChange === null || onChange === void 0 ? void 0 : onChange(event);
}
var useRadio = createHook({
  name: "Radio",
  compose: useCompositeItem,
  keys: RADIO_KEYS,
  useOptions: function useOptions10(_ref, _ref2) {
    var _options$value;
    var value = _ref2.value, checked = _ref2.checked;
    var _ref$unstable_clickOn = _ref.unstable_clickOnEnter, unstable_clickOnEnter = _ref$unstable_clickOn === void 0 ? false : _ref$unstable_clickOn, _ref$unstable_checkOn = _ref.unstable_checkOnFocus, unstable_checkOnFocus = _ref$unstable_checkOn === void 0 ? true : _ref$unstable_checkOn, options = _objectWithoutPropertiesLoose3(_ref, ["unstable_clickOnEnter", "unstable_checkOnFocus"]);
    return _objectSpread23(_objectSpread23({
      checked,
      unstable_clickOnEnter,
      unstable_checkOnFocus
    }, options), {}, {
      value: (_options$value = options.value) != null ? _options$value : value
    });
  },
  useProps: function useProps16(options, _ref3) {
    var htmlRef = _ref3.ref, htmlOnChange = _ref3.onChange, htmlOnClick = _ref3.onClick, htmlProps = _objectWithoutPropertiesLoose3(_ref3, ["ref", "onChange", "onClick"]);
    var ref = useRef18(null);
    var _React$useState3 = useState17(true), isNativeRadio = _React$useState3[0], setIsNativeRadio = _React$useState3[1];
    var checked = getChecked2(options);
    var isCurrentItemRef = useLiveRef(options.currentId === options.id);
    var onChangeRef = useLiveRef(htmlOnChange);
    var onClickRef = useLiveRef(htmlOnClick);
    useInitialChecked(options);
    useEffect15(function() {
      var element = ref.current;
      if (!element) {
        warning(true, "Can't determine whether the element is a native radio because `ref` wasn't passed to the component", "See https://reakit.io/docs/radio");
        return;
      }
      if (element.tagName !== "INPUT" || element.type !== "radio") {
        setIsNativeRadio(false);
      }
    }, []);
    var onChange = useCallback15(function(event) {
      var _onChangeRef$current, _options$setState;
      (_onChangeRef$current = onChangeRef.current) === null || _onChangeRef$current === void 0 ? void 0 : _onChangeRef$current.call(onChangeRef, event);
      if (event.defaultPrevented)
        return;
      if (options.disabled)
        return;
      (_options$setState = options.setState) === null || _options$setState === void 0 ? void 0 : _options$setState.call(options, options.value);
    }, [options.disabled, options.setState, options.value]);
    var onClick = useCallback15(function(event) {
      var _onClickRef$current;
      (_onClickRef$current = onClickRef.current) === null || _onClickRef$current === void 0 ? void 0 : _onClickRef$current.call(onClickRef, event);
      if (event.defaultPrevented)
        return;
      if (isNativeRadio)
        return;
      fireChange2(event.currentTarget, onChange);
    }, [onChange, isNativeRadio]);
    useEffect15(function() {
      var element = ref.current;
      if (!element)
        return;
      if (options.unstable_moves && isCurrentItemRef.current && options.unstable_checkOnFocus) {
        fireChange2(element, onChange);
      }
    }, [options.unstable_moves, options.unstable_checkOnFocus, onChange]);
    return _objectSpread23({
      ref: useForkRef(ref, htmlRef),
      role: !isNativeRadio ? "radio" : void 0,
      type: isNativeRadio ? "radio" : void 0,
      value: isNativeRadio ? options.value : void 0,
      name: isNativeRadio ? options.baseId : void 0,
      "aria-checked": checked,
      checked,
      onChange,
      onClick
    }, htmlProps);
  }
});
var Radio = createComponent({
  as: "input",
  memo: true,
  useHook: useRadio
});

// ../../../node_modules/reakit/es/Menu/MenuBar.js
import {useState as useState18, useEffect as useEffect16, useRef as useRef19, useCallback as useCallback16} from "react";

// ../../../node_modules/reakit/es/__keys-f74df4e0.js
var MENU_BAR_STATE_KEYS = ["baseId", "unstable_idCountRef", "unstable_virtual", "rtl", "orientation", "items", "groups", "currentId", "loop", "wrap", "shift", "unstable_moves", "unstable_hasActiveWidget", "unstable_includesBaseElement", "unstable_values", "setBaseId", "registerItem", "unregisterItem", "registerGroup", "unregisterGroup", "move", "next", "previous", "up", "down", "first", "last", "sort", "unstable_setVirtual", "setRTL", "setOrientation", "setCurrentId", "setLoop", "setWrap", "setShift", "reset", "unstable_setIncludesBaseElement", "unstable_setHasActiveWidget", "unstable_setValue"];
var MENU_STATE_KEYS = [].concat(MENU_BAR_STATE_KEYS, ["visible", "animated", "animating", "show", "hide", "toggle", "setVisible", "setAnimated", "stopAnimation", "modal", "unstable_disclosureRef", "setModal", "unstable_referenceRef", "unstable_popoverRef", "unstable_arrowRef", "unstable_popoverStyles", "unstable_arrowStyles", "unstable_originalPlacement", "unstable_update", "placement", "place"]);
var MENU_KEYS = MENU_STATE_KEYS;
var MENU_ARROW_KEYS = MENU_KEYS;
var MENU_BAR_KEYS = MENU_ARROW_KEYS;
var MENU_BUTTON_KEYS = MENU_BAR_KEYS;
var MENU_DISCLOSURE_KEYS = MENU_BUTTON_KEYS;
var MENU_GROUP_KEYS = MENU_DISCLOSURE_KEYS;
var MENU_ITEM_KEYS = MENU_GROUP_KEYS;
var MENU_ITEM_CHECKBOX_KEYS = [].concat(MENU_ITEM_KEYS, ["name"]);
var MENU_ITEM_RADIO_KEYS = MENU_ITEM_CHECKBOX_KEYS;
var MENU_SEPARATOR_KEYS = MENU_ITEM_KEYS;

// ../../../node_modules/reakit/es/Menu/MenuBar.js
function useShortcuts(menuRef, _ref, timeout) {
  var items = _ref.items, move = _ref.move;
  if (timeout === void 0) {
    timeout = 500;
  }
  var _React$useState = useState18(""), keys = _React$useState[0], setKeys = _React$useState[1];
  useEffect16(function() {
    if (!keys)
      return void 0;
    var timeoutId = setTimeout(function() {
      return setKeys("");
    }, timeout);
    var stop = items.find(function(s) {
      return Boolean(s.ref.current && s.ref.current.textContent && s.ref.current.textContent.toLowerCase().startsWith(keys));
    });
    if (stop) {
      move(stop.id);
    }
    return function() {
      return clearTimeout(timeoutId);
    };
  }, [keys, items, move, timeout]);
  useEffect16(function() {
    var menu = menuRef.current;
    if (!menu)
      return void 0;
    var onKeyDown = function onKeyDown2(event) {
      var _target$getAttribute;
      if (event.key.length > 1)
        return;
      if (event.shiftKey)
        return;
      if (event.metaKey)
        return;
      if (event.ctrlKey)
        return;
      if (event.altKey)
        return;
      var target = event.target;
      var role = (_target$getAttribute = target.getAttribute) === null || _target$getAttribute === void 0 ? void 0 : _target$getAttribute.call(target, "role");
      var targetIsMenu = target === menu;
      var targetIsMenuItem = role && role.indexOf("menuitem") !== -1 && closest(target, "[role=menu],[role=menubar]") === menu;
      if (!targetIsMenu && !targetIsMenuItem)
        return;
      if (/^[a-z0-9_-]$/i.test(event.key)) {
        event.stopPropagation();
        event.preventDefault();
        setKeys(function(k) {
          return "" + k + event.key;
        });
      }
    };
    menu.addEventListener("keydown", onKeyDown);
    return function() {
      return menu.removeEventListener("keydown", onKeyDown);
    };
  }, [menuRef, setKeys]);
}
var useMenuBar = createHook({
  name: "MenuBar",
  compose: useComposite,
  keys: MENU_BAR_KEYS,
  useProps: function useProps17(options, _ref) {
    var htmlRef = _ref.ref, htmlWrapElement = _ref.wrapElement, _ref$role = _ref.role, role = _ref$role === void 0 ? "menubar" : _ref$role, htmlProps = _objectWithoutPropertiesLoose3(_ref, ["ref", "wrapElement", "role"]);
    var ref = useRef19(null);
    var wrap = useMenuContext(ref, role, options);
    useShortcuts(ref, options);
    var wrapElement = useCallback16(function(element) {
      element = wrap(element);
      if (htmlWrapElement) {
        return htmlWrapElement(element);
      }
      return element;
    }, [wrap, htmlWrapElement]);
    return _objectSpread23({
      ref: useForkRef(ref, htmlRef),
      role,
      "aria-orientation": options.orientation,
      wrapElement
    }, htmlProps);
  }
});
var MenuBar = createComponent({
  as: "div",
  useHook: useMenuBar,
  useCreateElement: function useCreateElement$14(type, props, children) {
    useWarning(!props["aria-label"] && !props["aria-labelledby"] && props.role !== "menubar", "You should provide either `aria-label` or `aria-labelledby` props.", "See https://reakit.io/docs/menu");
    return useCreateElement(type, props, children);
  }
});

// ../../../node_modules/reakit/es/Menu/Menu.js
import {useContext as useContext8, useMemo as useMemo6, useCallback as useCallback17} from "react";
import "react-dom";
function usePlacementDir(placement) {
  return useMemo6(function() {
    var _placement$split;
    return placement === null || placement === void 0 ? void 0 : (_placement$split = placement.split("-")) === null || _placement$split === void 0 ? void 0 : _placement$split[0];
  }, [placement]);
}
var useMenu = createHook({
  name: "Menu",
  compose: [useMenuBar, usePopover],
  keys: MENU_KEYS,
  useOptions: function useOptions11(options) {
    var parent = useContext8(MenuContext);
    var parentIsMenuBar = (parent === null || parent === void 0 ? void 0 : parent.role) === "menubar";
    return _objectSpread23(_objectSpread23({
      unstable_autoFocusOnHide: !parentIsMenuBar,
      modal: false
    }, options), {}, {
      unstable_autoFocusOnShow: false,
      hideOnEsc: false
    });
  },
  useProps: function useProps18(options, _ref) {
    var htmlOnKeyDown = _ref.onKeyDown, htmlProps = _objectWithoutPropertiesLoose3(_ref, ["onKeyDown"]);
    var onKeyDownRef = useLiveRef(htmlOnKeyDown);
    var parent = useContext8(MenuContext);
    var hasParent = !!parent;
    var ancestorMenuBar = parent;
    while (ancestorMenuBar && ancestorMenuBar.role !== "menubar") {
      ancestorMenuBar = ancestorMenuBar.parent;
    }
    var _ref2 = ancestorMenuBar || {}, next = _ref2.next, previous = _ref2.previous, orientation = _ref2.orientation;
    var ancestorIsHorizontal = orientation === "horizontal";
    var dir = usePlacementDir(options.placement);
    var onKeyDown = useCallback17(function(event) {
      var _onKeyDownRef$current;
      (_onKeyDownRef$current = onKeyDownRef.current) === null || _onKeyDownRef$current === void 0 ? void 0 : _onKeyDownRef$current.call(onKeyDownRef, event);
      if (event.defaultPrevented)
        return;
      if (event.key === "Escape") {
        var _options$hide;
        if (!hasParent) {
          event.stopPropagation();
        }
        (_options$hide = options.hide) === null || _options$hide === void 0 ? void 0 : _options$hide.call(options);
      } else if (hasParent && !isPortalEvent(event)) {
        var ArrowRight = ancestorIsHorizontal && dir !== "left" ? next : dir === "left" && options.hide;
        var ArrowLeft = ancestorIsHorizontal && dir !== "right" ? previous : dir === "right" && options.hide;
        var keyMap = {
          ArrowRight,
          ArrowLeft
        };
        var action = keyMap[event.key];
        if (action) {
          event.preventDefault();
          if (hasParent) {
            event.stopPropagation();
          }
          action();
        }
      }
    }, [hasParent, ancestorIsHorizontal, next, previous, dir, options.hide]);
    return _objectSpread23({
      role: "menu",
      onKeyDown
    }, htmlProps);
  }
});
var Menu = createComponent({
  as: "div",
  useHook: useMenu,
  useCreateElement: function useCreateElement$15(type, props, children) {
    useWarning(!props["aria-label"] && !props["aria-labelledby"], "You should provide either `aria-label` or `aria-labelledby` props.", "See https://reakit.io/docs/menu");
    return useCreateElement(type, props, children);
  }
});

// ../../../node_modules/reakit/es/Menu/MenuBarState.js
import {useState as useState19, useCallback as useCallback18} from "react";
function useMenuBarState(initialState) {
  if (initialState === void 0) {
    initialState = {};
  }
  var _useSealedState = useSealedState(initialState), _useSealedState$orien = _useSealedState.orientation, orientation = _useSealedState$orien === void 0 ? "horizontal" : _useSealedState$orien, _useSealedState$unsta = _useSealedState.unstable_values, initialValues = _useSealedState$unsta === void 0 ? {} : _useSealedState$unsta, sealed = _objectWithoutPropertiesLoose3(_useSealedState, ["orientation", "unstable_values"]);
  var _React$useState = useState19(initialValues), values = _React$useState[0], setValues = _React$useState[1];
  var composite = useCompositeState(_objectSpread23(_objectSpread23({}, sealed), {}, {
    orientation
  }));
  return _objectSpread23(_objectSpread23({}, composite), {}, {
    unstable_values: values,
    unstable_setValue: useCallback18(function(name, value) {
      setValues(function(vals) {
        var _objectSpread2$1;
        return _objectSpread23(_objectSpread23({}, vals), {}, (_objectSpread2$1 = {}, _objectSpread2$1[name] = typeof value === "function" ? value(vals) : value, _objectSpread2$1));
      });
    }, [])
  });
}

// ../../../node_modules/reakit/es/Popover/PopoverDisclosure.js
import "react";
var usePopoverDisclosure = createHook({
  name: "PopoverDisclosure",
  compose: useDialogDisclosure,
  keys: POPOVER_DISCLOSURE_KEYS,
  useProps: function useProps19(options, _ref) {
    var htmlRef = _ref.ref, htmlProps = _objectWithoutPropertiesLoose3(_ref, ["ref"]);
    return _objectSpread23({
      ref: useForkRef(options.unstable_referenceRef, htmlRef)
    }, htmlProps);
  }
});
var PopoverDisclosure = createComponent({
  as: "button",
  memo: true,
  useHook: usePopoverDisclosure
});

// ../../../node_modules/reakit/es/findVisibleSubmenu-1553e354.js
function findVisibleSubmenu(submenus) {
  var visibleSubmenu = submenus === null || submenus === void 0 ? void 0 : submenus.find(function(submenu) {
    return submenu.current && !submenu.current.hidden;
  });
  return visibleSubmenu === null || visibleSubmenu === void 0 ? void 0 : visibleSubmenu.current;
}

// ../../../node_modules/reakit/es/Menu/MenuButton.js
import {useContext as useContext9, useRef as useRef20, useCallback as useCallback19} from "react";
var noop = function noop2() {
};
var useMenuButton = createHook({
  name: "MenuButton",
  compose: usePopoverDisclosure,
  keys: MENU_BUTTON_KEYS,
  propsAreEqual: function propsAreEqual5(prev, next) {
    var prevPopoverStyles = prev.unstable_popoverStyles, prevArrowStyles = prev.unstable_arrowStyles, prevCurrentId = prev.currentId, prevMoves = prev.unstable_moves, prevProps = _objectWithoutPropertiesLoose3(prev, ["unstable_popoverStyles", "unstable_arrowStyles", "currentId", "unstable_moves"]);
    var nextPopoverStyles = next.unstable_popoverStyles, nextArrowStyles = next.unstable_arrowStyles, nextCurrentId = next.currentId, nextMoves = next.unstable_moves, nextProps = _objectWithoutPropertiesLoose3(next, ["unstable_popoverStyles", "unstable_arrowStyles", "currentId", "unstable_moves"]);
    return usePopoverDisclosure.unstable_propsAreEqual(prevProps, nextProps);
  },
  useProps: function useProps20(options, _ref) {
    var htmlRef = _ref.ref, htmlOnClick = _ref.onClick, htmlOnKeyDown = _ref.onKeyDown, htmlOnFocus = _ref.onFocus, htmlOnMouseEnter = _ref.onMouseEnter, htmlOnMouseDown = _ref.onMouseDown, htmlProps = _objectWithoutPropertiesLoose3(_ref, ["ref", "onClick", "onKeyDown", "onFocus", "onMouseEnter", "onMouseDown"]);
    var parent = useContext9(MenuContext);
    var ref = useRef20(null);
    var hasPressedMouse = useRef20(false);
    var _options$placement$sp = options.placement.split("-"), dir = _options$placement$sp[0];
    var hasParent = !!parent;
    var parentIsMenuBar = (parent === null || parent === void 0 ? void 0 : parent.role) === "menubar";
    var disabled = options.disabled || htmlProps["aria-disabled"];
    var onClickRef = useLiveRef(htmlOnClick);
    var onKeyDownRef = useLiveRef(htmlOnKeyDown);
    var onFocusRef = useLiveRef(htmlOnFocus);
    var onMouseEnterRef = useLiveRef(htmlOnMouseEnter);
    var onMouseDownRef = useLiveRef(htmlOnMouseDown);
    var onKeyDown = useCallback19(function(event) {
      var _onKeyDownRef$current;
      if (event.key === "Escape") {
        var _options$hide;
        (_options$hide = options.hide) === null || _options$hide === void 0 ? void 0 : _options$hide.call(options);
      } else if (!disabled) {
        var first = options.first && function() {
          return setTimeout(options.first);
        };
        var last = options.last && function() {
          return setTimeout(options.last);
        };
        var keyMap = {
          Enter: first,
          " ": first,
          ArrowUp: (dir === "top" || dir === "bottom") && last,
          ArrowRight: dir === "right" && first,
          ArrowDown: (dir === "bottom" || dir === "top") && first,
          ArrowLeft: dir === "left" && first
        };
        var action = keyMap[event.key];
        if (action) {
          var _options$show;
          event.preventDefault();
          event.stopPropagation();
          (_options$show = options.show) === null || _options$show === void 0 ? void 0 : _options$show.call(options);
          action();
          return;
        }
      }
      (_onKeyDownRef$current = onKeyDownRef.current) === null || _onKeyDownRef$current === void 0 ? void 0 : _onKeyDownRef$current.call(onKeyDownRef, event);
    }, [disabled, options.hide, options.first, options.last, dir, options.show]);
    var onMouseEnter = useCallback19(function(event) {
      var _onMouseEnterRef$curr;
      (_onMouseEnterRef$curr = onMouseEnterRef.current) === null || _onMouseEnterRef$curr === void 0 ? void 0 : _onMouseEnterRef$curr.call(onMouseEnterRef, event);
      if (event.defaultPrevented)
        return;
      if (!parent)
        return;
      var element = event.currentTarget;
      if (parentIsMenuBar) {
        if (findVisibleSubmenu(parent.children)) {
          element.focus();
        }
      } else {
        setTimeout(function() {
          if (hasFocusWithin(element)) {
            var _options$show2;
            (_options$show2 = options.show) === null || _options$show2 === void 0 ? void 0 : _options$show2.call(options);
          }
        }, 200);
      }
    }, [parent, parentIsMenuBar, options.show]);
    var onMouseDown = useCallback19(function(event) {
      var _onMouseDownRef$curre;
      hasPressedMouse.current = true;
      (_onMouseDownRef$curre = onMouseDownRef.current) === null || _onMouseDownRef$curre === void 0 ? void 0 : _onMouseDownRef$curre.call(onMouseDownRef, event);
    }, []);
    var onFocus = useCallback19(function(event) {
      var _onFocusRef$current;
      (_onFocusRef$current = onFocusRef.current) === null || _onFocusRef$current === void 0 ? void 0 : _onFocusRef$current.call(onFocusRef, event);
      if (event.defaultPrevented)
        return;
      if (disabled)
        return;
      if (parentIsMenuBar && !hasPressedMouse.current) {
        var _options$show3;
        (_options$show3 = options.show) === null || _options$show3 === void 0 ? void 0 : _options$show3.call(options);
      }
    }, [parentIsMenuBar, disabled, options.show]);
    var onClick = useCallback19(function(event) {
      var _onClickRef$current;
      (_onClickRef$current = onClickRef.current) === null || _onClickRef$current === void 0 ? void 0 : _onClickRef$current.call(onClickRef, event);
      if (event.defaultPrevented)
        return;
      if (hasParent && !parentIsMenuBar) {
        var _options$show4;
        (_options$show4 = options.show) === null || _options$show4 === void 0 ? void 0 : _options$show4.call(options);
      } else {
        var _options$toggle;
        (_options$toggle = options.toggle) === null || _options$toggle === void 0 ? void 0 : _options$toggle.call(options);
        if (hasPressedMouse.current && !parentIsMenuBar && !options.visible) {
          var _options$move;
          (_options$move = options.move) === null || _options$move === void 0 ? void 0 : _options$move.call(options, null);
        }
      }
      hasPressedMouse.current = false;
    }, [hasParent, parentIsMenuBar, options.show, options.toggle, options.visible, options.move]);
    return _objectSpread23({
      ref: useForkRef(ref, htmlRef),
      "aria-haspopup": "menu",
      onKeyDown,
      onMouseEnter,
      onMouseDown,
      onFocus,
      onClick
    }, htmlProps);
  },
  useComposeOptions: function useComposeOptions(options) {
    return _objectSpread23(_objectSpread23({}, options), {}, {
      toggle: noop
    });
  }
});
var MenuButton = createComponent({
  as: "button",
  memo: true,
  useHook: useMenuButton
});

// ../../../node_modules/reakit/es/Menu/MenuGroup.js
var useMenuGroup = createHook({
  name: "MenuGroup",
  compose: useRole,
  keys: MENU_GROUP_KEYS,
  useProps: function useProps21(_, htmlProps) {
    return _objectSpread23({
      role: "group"
    }, htmlProps);
  }
});
var MenuGroup = createComponent({
  as: "div",
  useHook: useMenuGroup
});

// ../../../node_modules/reakit/es/Menu/MenuItem.js
import {useRef as useRef21, useCallback as useCallback20, useContext as useContext10} from "react";
function getTriangleArea(a, b, c) {
  return Math.abs((a.x * (b.y - c.y) + b.x * (c.y - a.y) + c.x * (a.y - b.y)) / 2);
}
function isPointInTriangle(point, a, b, c) {
  var A = getTriangleArea(a, b, c);
  var A1 = getTriangleArea(point, b, c);
  var A2 = getTriangleArea(a, point, c);
  var A3 = getTriangleArea(a, b, point);
  return A === A1 + A2 + A3;
}
function getSubmenuAnchorPoints(event, visibleSubmenu) {
  var _visibleSubmenu$getBo = visibleSubmenu.getBoundingClientRect(), top2 = _visibleSubmenu$getBo.top, right2 = _visibleSubmenu$getBo.right, bottom2 = _visibleSubmenu$getBo.bottom, left2 = _visibleSubmenu$getBo.left;
  var x = left2 > event.clientX ? left2 : right2;
  return [{
    x,
    y: top2
  }, {
    x,
    y: bottom2
  }];
}
function useTransitToSubmenu(menu, htmlOnMouseEnter) {
  var onMouseEnterRef = useLiveRef(htmlOnMouseEnter);
  var enterPointRef = useRef21(null);
  var submenuTopPointRef = useRef21(null);
  var submenuBottomPointRef = useRef21(null);
  var previousClientX = useRef21(0);
  var previousClientY = useRef21(0);
  var assignSubmenuAnchorPoints = useCallback20(function(event) {
    if (!(menu !== null && menu !== void 0 && menu.children.length))
      return;
    submenuTopPointRef.current = null;
    submenuBottomPointRef.current = null;
    var visibleSubmenu = findVisibleSubmenu(menu.children);
    if (!visibleSubmenu)
      return;
    var _getSubmenuAnchorPoin = getSubmenuAnchorPoints(event, visibleSubmenu);
    submenuTopPointRef.current = _getSubmenuAnchorPoin[0];
    submenuBottomPointRef.current = _getSubmenuAnchorPoin[1];
  }, [menu === null || menu === void 0 ? void 0 : menu.children]);
  var isMouseInTransitToSubmenu = useCallback20(function(event) {
    var isMoving = previousClientX.current !== event.clientX || previousClientY.current !== event.clientY;
    if (event.isTrusted && !isMoving) {
      return true;
    }
    var movementX = Math.abs(previousClientX.current - event.clientX);
    previousClientX.current = event.clientX;
    previousClientY.current = event.clientY;
    var hasAnchorPoints = function hasAnchorPoints2() {
      return submenuTopPointRef.current && submenuBottomPointRef.current;
    };
    if (event.type === "mouseleave" && !hasAnchorPoints()) {
      assignSubmenuAnchorPoints(event);
    }
    if (!hasAnchorPoints())
      return false;
    return movementX && enterPointRef.current && isPointInTriangle({
      x: event.clientX,
      y: event.clientY
    }, enterPointRef.current, submenuTopPointRef.current, submenuBottomPointRef.current);
  }, [assignSubmenuAnchorPoints]);
  var onMouseEnter = useCallback20(function(event) {
    var _onMouseEnterRef$curr;
    (_onMouseEnterRef$curr = onMouseEnterRef.current) === null || _onMouseEnterRef$curr === void 0 ? void 0 : _onMouseEnterRef$curr.call(onMouseEnterRef, event);
    if (event.defaultPrevented)
      return;
    if ((menu === null || menu === void 0 ? void 0 : menu.role) === "menubar")
      return;
    enterPointRef.current = {
      x: event.clientX,
      y: event.clientY
    };
    assignSubmenuAnchorPoints(event);
  }, [menu === null || menu === void 0 ? void 0 : menu.role, assignSubmenuAnchorPoints]);
  return {
    onMouseEnter,
    isMouseInTransitToSubmenu
  };
}
function getMouseDestination(event) {
  var relatedTarget = event.relatedTarget;
  if ((relatedTarget === null || relatedTarget === void 0 ? void 0 : relatedTarget.nodeType) === Node.ELEMENT_NODE) {
    return event.relatedTarget;
  }
  return event.toElement || null;
}
function hoveringInside(event) {
  var nextElement = getMouseDestination(event);
  if (!nextElement)
    return false;
  return contains(event.currentTarget, nextElement);
}
function hoveringExpandedMenu(event, children) {
  if (!(children !== null && children !== void 0 && children.length))
    return false;
  var nextElement = getMouseDestination(event);
  if (!nextElement)
    return false;
  var visibleSubmenu = findVisibleSubmenu(children);
  return visibleSubmenu && contains(visibleSubmenu, nextElement);
}
function hoveringAnotherMenuItem(event, items) {
  var nextElement = getMouseDestination(event);
  if (!nextElement)
    return false;
  return items === null || items === void 0 ? void 0 : items.some(function(item) {
    return item.ref.current && contains(item.ref.current, nextElement);
  });
}
var useMenuItem = createHook({
  name: "MenuItem",
  compose: useCompositeItem,
  keys: MENU_ITEM_KEYS,
  propsAreEqual: function propsAreEqual6(prev, next) {
    var prevPopoverStyles = prev.unstable_popoverStyles, prevArrowStyles = prev.unstable_arrowStyles, prevVisible = prev.visible, prevProps = _objectWithoutPropertiesLoose3(prev, ["unstable_popoverStyles", "unstable_arrowStyles", "visible"]);
    var nextPopoverStyles = next.unstable_popoverStyles, nextArrowStyles = next.unstable_arrowStyles, nextVisible = next.visible, nextProps = _objectWithoutPropertiesLoose3(next, ["unstable_popoverStyles", "unstable_arrowStyles", "visible"]);
    return useCompositeItem.unstable_propsAreEqual(prevProps, nextProps);
  },
  useProps: function useProps22(options, _ref) {
    var htmlOnMouseEnter = _ref.onMouseEnter, htmlOnMouseMove = _ref.onMouseMove, htmlOnMouseLeave = _ref.onMouseLeave, htmlProps = _objectWithoutPropertiesLoose3(_ref, ["onMouseEnter", "onMouseMove", "onMouseLeave"]);
    var menu = useContext10(MenuContext);
    var onMouseMoveRef = useLiveRef(htmlOnMouseMove);
    var onMouseLeaveRef = useLiveRef(htmlOnMouseLeave);
    var _useTransitToSubmenu = useTransitToSubmenu(menu, htmlOnMouseEnter), onMouseEnter = _useTransitToSubmenu.onMouseEnter, isMouseInTransitToSubmenu = _useTransitToSubmenu.isMouseInTransitToSubmenu;
    var onMouseMove = useCallback20(function(event) {
      var _onMouseMoveRef$curre, _options$move;
      (_onMouseMoveRef$curre = onMouseMoveRef.current) === null || _onMouseMoveRef$curre === void 0 ? void 0 : _onMouseMoveRef$curre.call(onMouseMoveRef, event);
      if (event.defaultPrevented)
        return;
      if ((menu === null || menu === void 0 ? void 0 : menu.role) === "menubar")
        return;
      if (isMouseInTransitToSubmenu(event))
        return;
      if (hasFocusWithin(event.currentTarget))
        return;
      (_options$move = options.move) === null || _options$move === void 0 ? void 0 : _options$move.call(options, event.currentTarget.id);
    }, [options.move]);
    var onMouseLeave = useCallback20(function(event) {
      var _onMouseLeaveRef$curr;
      (_onMouseLeaveRef$curr = onMouseLeaveRef.current) === null || _onMouseLeaveRef$curr === void 0 ? void 0 : _onMouseLeaveRef$curr.call(onMouseLeaveRef, event);
      if (event.defaultPrevented)
        return;
      if ((menu === null || menu === void 0 ? void 0 : menu.role) === "menubar")
        return;
      if (hoveringInside(event))
        return;
      if (hoveringExpandedMenu(event, menu === null || menu === void 0 ? void 0 : menu.children))
        return;
      if (!hoveringAnotherMenuItem(event, options.items)) {
        var _options$move2;
        if (isMouseInTransitToSubmenu(event))
          return;
        (_options$move2 = options.move) === null || _options$move2 === void 0 ? void 0 : _options$move2.call(options, null);
      }
    }, [menu === null || menu === void 0 ? void 0 : menu.role, menu === null || menu === void 0 ? void 0 : menu.children, options.items, options.move]);
    return _objectSpread23({
      role: "menuitem",
      onMouseEnter,
      onMouseMove,
      onMouseLeave
    }, htmlProps);
  }
});
var MenuItem = createComponent({
  as: "button",
  memo: true,
  useHook: useMenuItem
});

// ../../../node_modules/reakit/es/Menu/MenuItemCheckbox.js
import {useCallback as useCallback21} from "react";
var useMenuItemCheckbox = createHook({
  name: "MenuItemCheckbox",
  compose: [useMenuItem, useCheckbox],
  keys: MENU_ITEM_CHECKBOX_KEYS,
  propsAreEqual: function propsAreEqual7(prev, next) {
    if (prev.name !== next.name) {
      return useMenuItem.unstable_propsAreEqual(prev, next);
    }
    var prevValues = prev.unstable_values, prevProps = _objectWithoutPropertiesLoose3(prev, ["unstable_values"]);
    var nextValues = next.unstable_values, nextProps = _objectWithoutPropertiesLoose3(next, ["unstable_values"]);
    if (prevValues[next.name] !== nextValues[next.name]) {
      return false;
    }
    return useMenuItem.unstable_propsAreEqual(prevProps, nextProps);
  },
  useOptions: function useOptions12(options) {
    var setState = useCallback21(function(value) {
      return options.unstable_setValue(options.name, value);
    }, [options.unstable_setValue, options.name]);
    return _objectSpread23(_objectSpread23({}, options), {}, {
      state: options.unstable_values[options.name],
      setState
    });
  },
  useProps: function useProps23(options, htmlProps) {
    return _objectSpread23({
      role: "menuitemcheckbox",
      name: options.name
    }, htmlProps);
  }
});
var MenuItemCheckbox = createComponent({
  as: "button",
  memo: true,
  useHook: useMenuItemCheckbox
});

// ../../../node_modules/reakit/es/Menu/MenuItemRadio.js
import {useCallback as useCallback22} from "react";
var useMenuItemRadio = createHook({
  name: "MenuItemRadio",
  compose: [useMenuItem, useRadio],
  keys: MENU_ITEM_RADIO_KEYS,
  propsAreEqual: function propsAreEqual8(prev, next) {
    if (prev.name !== next.name) {
      return useMenuItem.unstable_propsAreEqual(prev, next);
    }
    var prevValues = prev.unstable_values, prevProps = _objectWithoutPropertiesLoose3(prev, ["unstable_values"]);
    var nextValues = next.unstable_values, nextProps = _objectWithoutPropertiesLoose3(next, ["unstable_values"]);
    if (prevValues[next.name] !== nextValues[next.name]) {
      return false;
    }
    return useMenuItem.unstable_propsAreEqual(prevProps, nextProps);
  },
  useOptions: function useOptions13(options) {
    var setState = useCallback22(function(value) {
      return options.unstable_setValue(options.name, value);
    }, [options.unstable_setValue, options.name]);
    return _objectSpread23(_objectSpread23({}, options), {}, {
      unstable_checkOnFocus: false,
      state: options.unstable_values[options.name],
      setState
    });
  },
  useProps: function useProps24(_, htmlProps) {
    return _objectSpread23({
      role: "menuitemradio"
    }, htmlProps);
  }
});
var MenuItemRadio = createComponent({
  as: "button",
  memo: true,
  useHook: useMenuItemRadio
});

// ../../../node_modules/reakit/es/Separator/Separator.js
var SEPARATOR_KEYS = ["orientation"];
var useSeparator = createHook({
  name: "Separator",
  compose: useRole,
  keys: SEPARATOR_KEYS,
  useOptions: function useOptions14(_ref) {
    var _ref$orientation = _ref.orientation, orientation = _ref$orientation === void 0 ? "horizontal" : _ref$orientation, options = _objectWithoutPropertiesLoose3(_ref, ["orientation"]);
    return _objectSpread23({
      orientation
    }, options);
  },
  useProps: function useProps25(options, htmlProps) {
    return _objectSpread23({
      role: "separator",
      "aria-orientation": options.orientation
    }, htmlProps);
  }
});
var Separator = createComponent({
  as: "hr",
  memo: true,
  useHook: useSeparator
});

// ../../../node_modules/reakit/es/Menu/MenuSeparator.js
var useMenuSeparator = createHook({
  name: "MenuSeparator",
  compose: useSeparator,
  keys: MENU_SEPARATOR_KEYS,
  useOptions: function useOptions15(_ref) {
    var _ref$orientation = _ref.orientation, orientation = _ref$orientation === void 0 ? "vertical" : _ref$orientation, options = _objectWithoutPropertiesLoose3(_ref, ["orientation"]);
    return _objectSpread23({
      orientation: orientation === "vertical" ? "horizontal" : "vertical"
    }, options);
  }
});
var MenuSeparator = createComponent({
  as: "hr",
  memo: true,
  useHook: useMenuSeparator
});

// ../../../node_modules/reakit/es/Menu/MenuState.js
import {useContext as useContext11, useEffect as useEffect17} from "react";
function useMenuState(initialState) {
  if (initialState === void 0) {
    initialState = {};
  }
  var parent = useContext11(MenuContext);
  var _useSealedState = useSealedState(initialState), _useSealedState$orien = _useSealedState.orientation, orientation = _useSealedState$orien === void 0 ? "vertical" : _useSealedState$orien, _useSealedState$gutte = _useSealedState.gutter, gutter = _useSealedState$gutte === void 0 ? 0 : _useSealedState$gutte, sealed = _objectWithoutPropertiesLoose3(_useSealedState, ["orientation", "gutter"]);
  var placement = sealed.placement || (parent && parent.orientation === "vertical" ? "right-start" : "bottom-start");
  var menuBar = useMenuBarState(_objectSpread23(_objectSpread23({}, sealed), {}, {
    orientation
  }));
  var popover = usePopoverState(_objectSpread23(_objectSpread23({}, sealed), {}, {
    placement,
    gutter
  }));
  useEffect17(function() {
    if (!popover.visible) {
      menuBar.reset();
    }
  }, [popover.visible, menuBar.reset]);
  return _objectSpread23(_objectSpread23({}, menuBar), popover);
}

// ../../../node_modules/reakit/es/__keys-3c0b2243.js
var TAB_STATE_KEYS = ["baseId", "unstable_idCountRef", "unstable_virtual", "rtl", "orientation", "items", "groups", "currentId", "loop", "wrap", "shift", "unstable_moves", "unstable_hasActiveWidget", "unstable_includesBaseElement", "selectedId", "panels", "manual", "setBaseId", "registerItem", "unregisterItem", "registerGroup", "unregisterGroup", "move", "next", "previous", "up", "down", "first", "last", "sort", "unstable_setVirtual", "setRTL", "setOrientation", "setCurrentId", "setLoop", "setWrap", "setShift", "reset", "unstable_setIncludesBaseElement", "unstable_setHasActiveWidget", "select", "setSelectedId", "registerPanel", "unregisterPanel"];
var TAB_KEYS = TAB_STATE_KEYS;
var TAB_LIST_KEYS = TAB_KEYS;
var TAB_PANEL_KEYS = [].concat(TAB_LIST_KEYS, ["tabId"]);

// ../../../node_modules/reakit/es/Tab/Tab.js
import {useMemo as useMemo7, useCallback as useCallback23} from "react";
function useTabPanelId(options) {
  return useMemo7(function() {
    var _options$panels, _options$panels$find;
    return ((_options$panels = options.panels) === null || _options$panels === void 0 ? void 0 : (_options$panels$find = _options$panels.find(function(panel) {
      return panel.groupId === options.id;
    })) === null || _options$panels$find === void 0 ? void 0 : _options$panels$find.id) || void 0;
  }, [options.panels, options.id]);
}
var useTab = createHook({
  name: "Tab",
  compose: useCompositeItem,
  keys: TAB_KEYS,
  useOptions: function useOptions16(_ref) {
    var _ref$focusable = _ref.focusable, focusable = _ref$focusable === void 0 ? true : _ref$focusable, options = _objectWithoutPropertiesLoose3(_ref, ["focusable"]);
    return _objectSpread23({
      focusable
    }, options);
  },
  useProps: function useProps26(options, _ref2) {
    var htmlOnClick = _ref2.onClick, htmlOnFocus = _ref2.onFocus, htmlProps = _objectWithoutPropertiesLoose3(_ref2, ["onClick", "onFocus"]);
    var selected = options.selectedId === options.id;
    var tabPanelId = useTabPanelId(options);
    var onClickRef = useLiveRef(htmlOnClick);
    var onFocusRef = useLiveRef(htmlOnFocus);
    var onClick = useCallback23(function(event) {
      var _onClickRef$current, _options$select;
      (_onClickRef$current = onClickRef.current) === null || _onClickRef$current === void 0 ? void 0 : _onClickRef$current.call(onClickRef, event);
      if (event.defaultPrevented)
        return;
      if (options.disabled)
        return;
      if (!options.id)
        return;
      if (selected)
        return;
      (_options$select = options.select) === null || _options$select === void 0 ? void 0 : _options$select.call(options, options.id);
    }, [options.disabled, selected, options.select, options.id]);
    var onFocus = useCallback23(function(event) {
      var _onFocusRef$current, _options$select2;
      (_onFocusRef$current = onFocusRef.current) === null || _onFocusRef$current === void 0 ? void 0 : _onFocusRef$current.call(onFocusRef, event);
      if (event.defaultPrevented)
        return;
      if (options.disabled)
        return;
      if (options.manual)
        return;
      if (!options.id)
        return;
      if (selected)
        return;
      (_options$select2 = options.select) === null || _options$select2 === void 0 ? void 0 : _options$select2.call(options, options.id);
    }, [options.id, options.disabled, options.manual, selected, options.select]);
    return _objectSpread23({
      role: "tab",
      "aria-selected": selected,
      "aria-controls": tabPanelId,
      onClick,
      onFocus
    }, htmlProps);
  }
});
var Tab = createComponent({
  as: "button",
  memo: true,
  useHook: useTab
});

// ../../../node_modules/reakit/es/Tab/TabList.js
import "react";
var useTabList = createHook({
  name: "TabList",
  compose: useComposite,
  keys: TAB_LIST_KEYS,
  useProps: function useProps27(options, htmlProps) {
    return _objectSpread23({
      role: "tablist",
      "aria-orientation": options.orientation
    }, htmlProps);
  }
});
var TabList = createComponent({
  as: "div",
  useHook: useTabList,
  useCreateElement: function useCreateElement$16(type, props, children) {
    useWarning(!props["aria-label"] && !props["aria-labelledby"], "You should provide either `aria-label` or `aria-labelledby` props.", "See https://reakit.io/docs/tab");
    return useCreateElement(type, props, children);
  }
});

// ../../../node_modules/reakit/es/Tab/TabPanel.js
import {useRef as useRef22, useEffect as useEffect18} from "react";
function getTabsWithoutPanel(tabs, panels) {
  var panelsTabIds = panels.map(function(panel) {
    return panel.groupId;
  }).filter(Boolean);
  return tabs.filter(function(item) {
    return panelsTabIds.indexOf(item.id || void 0) === -1;
  });
}
function getPanelIndex(panels, panel) {
  var panelsWithoutTabId = panels.filter(function(p) {
    return !p.groupId;
  });
  return panelsWithoutTabId.indexOf(panel);
}
function getTabId(options) {
  var _options$panels, _tabsWithoutPanel$pan;
  var panel = (_options$panels = options.panels) === null || _options$panels === void 0 ? void 0 : _options$panels.find(function(p) {
    return p.id === options.id;
  });
  var tabId = options.tabId || (panel === null || panel === void 0 ? void 0 : panel.groupId);
  if (tabId || !panel || !options.panels || !options.items) {
    return tabId;
  }
  var panelIndex = getPanelIndex(options.panels, panel);
  var tabsWithoutPanel = getTabsWithoutPanel(options.items, options.panels);
  return ((_tabsWithoutPanel$pan = tabsWithoutPanel[panelIndex]) === null || _tabsWithoutPanel$pan === void 0 ? void 0 : _tabsWithoutPanel$pan.id) || void 0;
}
var useTabPanel = createHook({
  name: "TabPanel",
  compose: [unstable_useId, useDisclosureContent],
  keys: TAB_PANEL_KEYS,
  useProps: function useProps28(options, _ref) {
    var htmlRef = _ref.ref, htmlProps = _objectWithoutPropertiesLoose3(_ref, ["ref"]);
    var ref = useRef22(null);
    var tabId = getTabId(options);
    var id = options.id, registerPanel = options.registerPanel, unregisterPanel = options.unregisterPanel;
    useEffect18(function() {
      if (!id)
        return void 0;
      registerPanel === null || registerPanel === void 0 ? void 0 : registerPanel({
        id,
        ref,
        groupId: tabId
      });
      return function() {
        unregisterPanel === null || unregisterPanel === void 0 ? void 0 : unregisterPanel(id);
      };
    }, [tabId, id, registerPanel, unregisterPanel]);
    return _objectSpread23({
      ref: useForkRef(ref, htmlRef),
      role: "tabpanel",
      tabIndex: 0,
      "aria-labelledby": tabId
    }, htmlProps);
  },
  useComposeOptions: function useComposeOptions2(options) {
    var tabId = getTabId(options);
    return _objectSpread23({
      visible: tabId ? options.selectedId === tabId : false
    }, options);
  }
});
var TabPanel = createComponent({
  as: "div",
  useHook: useTabPanel
});

// ../../../node_modules/reakit/es/Tab/TabState.js
import {useState as useState20, useCallback as useCallback24, useEffect as useEffect19} from "react";
function useTabState(initialState) {
  if (initialState === void 0) {
    initialState = {};
  }
  var _useSealedState = useSealedState(initialState), initialSelectedId = _useSealedState.selectedId, _useSealedState$loop = _useSealedState.loop, loop = _useSealedState$loop === void 0 ? true : _useSealedState$loop, _useSealedState$manua = _useSealedState.manual, manual = _useSealedState$manua === void 0 ? false : _useSealedState$manua, sealed = _objectWithoutPropertiesLoose3(_useSealedState, ["selectedId", "loop", "manual"]);
  var composite = useCompositeState(_objectSpread23({
    loop,
    currentId: initialSelectedId
  }, sealed));
  var panels = useCompositeState();
  var _React$useState = useState20(initialSelectedId), selectedId = _React$useState[0], setSelectedId = _React$useState[1];
  var select = useCallback24(function(id) {
    composite.move(id);
    setSelectedId(id);
  }, [composite.move]);
  useEffect19(function() {
    if (selectedId === null)
      return;
    var selectedItem = composite.items.find(function(item) {
      return item.id === selectedId;
    });
    if (selectedItem)
      return;
    if (composite.currentId) {
      setSelectedId(composite.currentId);
    }
  }, [selectedId, composite.items, composite.currentId]);
  return _objectSpread23(_objectSpread23({}, composite), {}, {
    selectedId,
    panels: panels.items,
    manual,
    select,
    setSelectedId,
    registerPanel: useCallback24(function(panel) {
      return panels.registerItem(panel);
    }, [panels.registerItem]),
    unregisterPanel: useCallback24(function(id) {
      return panels.unregisterItem(id);
    }, [panels.unregisterItem])
  });
}

// ../../../node_modules/reakit/es/__keys-d101cb3b.js
var TOOLTIP_STATE_KEYS = ["baseId", "unstable_idCountRef", "visible", "animated", "animating", "setBaseId", "show", "hide", "toggle", "setVisible", "setAnimated", "stopAnimation", "unstable_disclosureRef", "unstable_referenceRef", "unstable_popoverRef", "unstable_arrowRef", "unstable_popoverStyles", "unstable_arrowStyles", "unstable_originalPlacement", "unstable_update", "placement", "place", "unstable_timeout", "unstable_setTimeout"];
var TOOLTIP_KEYS = [].concat(TOOLTIP_STATE_KEYS, ["unstable_portal"]);
var TOOLTIP_ARROW_KEYS = TOOLTIP_STATE_KEYS;
var TOOLTIP_REFERENCE_KEYS = TOOLTIP_ARROW_KEYS;

// ../../../node_modules/reakit/es/__globalState-300469f0.js
var globalState = {
  currentTooltipId: null,
  listeners: new Set(),
  subscribe: function subscribe(listener) {
    var _this = this;
    this.listeners.add(listener);
    return function() {
      _this.listeners.delete(listener);
    };
  },
  show: function show(id) {
    this.currentTooltipId = id;
    this.listeners.forEach(function(listener) {
      return listener(id);
    });
  },
  hide: function hide2(id) {
    if (this.currentTooltipId === id) {
      this.currentTooltipId = null;
      this.listeners.forEach(function(listener) {
        return listener(null);
      });
    }
  }
};

// ../../../node_modules/reakit/es/Tooltip/Tooltip.js
import {useEffect as useEffect20, useCallback as useCallback25, createElement as createElement6} from "react";
import "react-dom";
function globallyHideTooltipOnEscape(event) {
  if (event.defaultPrevented)
    return;
  if (event.key === "Escape") {
    globalState.show(null);
  }
}
var useTooltip = createHook({
  name: "Tooltip",
  compose: useDisclosureContent,
  keys: TOOLTIP_KEYS,
  useOptions: function useOptions17(_ref) {
    var _ref$unstable_portal = _ref.unstable_portal, unstable_portal = _ref$unstable_portal === void 0 ? true : _ref$unstable_portal, options = _objectWithoutPropertiesLoose3(_ref, ["unstable_portal"]);
    return _objectSpread23({
      unstable_portal
    }, options);
  },
  useProps: function useProps29(options, _ref2) {
    var htmlRef = _ref2.ref, htmlStyle = _ref2.style, htmlWrapElement = _ref2.wrapElement, htmlProps = _objectWithoutPropertiesLoose3(_ref2, ["ref", "style", "wrapElement"]);
    useEffect20(function() {
      var _options$unstable_pop;
      var document2 = getDocument((_options$unstable_pop = options.unstable_popoverRef) === null || _options$unstable_pop === void 0 ? void 0 : _options$unstable_pop.current);
      document2.addEventListener("keydown", globallyHideTooltipOnEscape);
    }, []);
    var wrapElement = useCallback25(function(element) {
      if (options.unstable_portal) {
        element = /* @__PURE__ */ createElement6(Portal, null, element);
      }
      if (htmlWrapElement) {
        return htmlWrapElement(element);
      }
      return element;
    }, [options.unstable_portal, htmlWrapElement]);
    return _objectSpread23({
      ref: useForkRef(options.unstable_popoverRef, htmlRef),
      role: "tooltip",
      style: _objectSpread23(_objectSpread23({}, options.unstable_popoverStyles), {}, {
        pointerEvents: "none"
      }, htmlStyle),
      wrapElement
    }, htmlProps);
  }
});
var Tooltip = createComponent({
  as: "div",
  memo: true,
  useHook: useTooltip
});

// ../../../node_modules/reakit/es/Tooltip/TooltipReference.js
import {useCallback as useCallback26} from "react";
var useTooltipReference = createHook({
  name: "TooltipReference",
  compose: useRole,
  keys: TOOLTIP_REFERENCE_KEYS,
  useProps: function useProps30(options, _ref) {
    var htmlRef = _ref.ref, htmlOnFocus = _ref.onFocus, htmlOnBlur = _ref.onBlur, htmlOnMouseEnter = _ref.onMouseEnter, htmlOnMouseLeave = _ref.onMouseLeave, htmlProps = _objectWithoutPropertiesLoose3(_ref, ["ref", "onFocus", "onBlur", "onMouseEnter", "onMouseLeave"]);
    var onFocusRef = useLiveRef(htmlOnFocus);
    var onBlurRef = useLiveRef(htmlOnBlur);
    var onMouseEnterRef = useLiveRef(htmlOnMouseEnter);
    var onMouseLeaveRef = useLiveRef(htmlOnMouseLeave);
    var onFocus = useCallback26(function(event) {
      var _onFocusRef$current, _options$show;
      (_onFocusRef$current = onFocusRef.current) === null || _onFocusRef$current === void 0 ? void 0 : _onFocusRef$current.call(onFocusRef, event);
      if (event.defaultPrevented)
        return;
      (_options$show = options.show) === null || _options$show === void 0 ? void 0 : _options$show.call(options);
    }, [options.show]);
    var onBlur = useCallback26(function(event) {
      var _onBlurRef$current, _options$hide;
      (_onBlurRef$current = onBlurRef.current) === null || _onBlurRef$current === void 0 ? void 0 : _onBlurRef$current.call(onBlurRef, event);
      if (event.defaultPrevented)
        return;
      (_options$hide = options.hide) === null || _options$hide === void 0 ? void 0 : _options$hide.call(options);
    }, [options.hide]);
    var onMouseEnter = useCallback26(function(event) {
      var _onMouseEnterRef$curr, _options$show2;
      (_onMouseEnterRef$curr = onMouseEnterRef.current) === null || _onMouseEnterRef$curr === void 0 ? void 0 : _onMouseEnterRef$curr.call(onMouseEnterRef, event);
      if (event.defaultPrevented)
        return;
      (_options$show2 = options.show) === null || _options$show2 === void 0 ? void 0 : _options$show2.call(options);
    }, [options.show]);
    var onMouseLeave = useCallback26(function(event) {
      var _onMouseLeaveRef$curr, _options$hide2;
      (_onMouseLeaveRef$curr = onMouseLeaveRef.current) === null || _onMouseLeaveRef$curr === void 0 ? void 0 : _onMouseLeaveRef$curr.call(onMouseLeaveRef, event);
      if (event.defaultPrevented)
        return;
      (_options$hide2 = options.hide) === null || _options$hide2 === void 0 ? void 0 : _options$hide2.call(options);
    }, [options.hide]);
    return _objectSpread23({
      ref: useForkRef(options.unstable_referenceRef, htmlRef),
      tabIndex: 0,
      onFocus,
      onBlur,
      onMouseEnter,
      onMouseLeave,
      "aria-describedby": options.baseId
    }, htmlProps);
  }
});
var TooltipReference = createComponent({
  as: "div",
  useHook: useTooltipReference
});

// ../../../node_modules/reakit/es/Tooltip/TooltipState.js
import {useState as useState21, useRef as useRef23, useCallback as useCallback27, useEffect as useEffect21} from "react";
function useTooltipState(initialState) {
  if (initialState === void 0) {
    initialState = {};
  }
  var _useSealedState = useSealedState(initialState), _useSealedState$place = _useSealedState.placement, placement = _useSealedState$place === void 0 ? "top" : _useSealedState$place, _useSealedState$unsta = _useSealedState.unstable_timeout, initialTimeout = _useSealedState$unsta === void 0 ? 0 : _useSealedState$unsta, sealed = _objectWithoutPropertiesLoose3(_useSealedState, ["placement", "unstable_timeout"]);
  var _React$useState = useState21(initialTimeout), timeout = _React$useState[0], setTimeout2 = _React$useState[1];
  var showTimeout = useRef23(null);
  var hideTimeout = useRef23(null);
  var _usePopoverState = usePopoverState(_objectSpread23(_objectSpread23({}, sealed), {}, {
    placement
  })), modal = _usePopoverState.modal, setModal = _usePopoverState.setModal, popover = _objectWithoutPropertiesLoose3(_usePopoverState, ["modal", "setModal"]);
  var clearTimeouts = useCallback27(function() {
    if (showTimeout.current !== null) {
      window.clearTimeout(showTimeout.current);
    }
    if (hideTimeout.current !== null) {
      window.clearTimeout(hideTimeout.current);
    }
  }, []);
  var hide3 = useCallback27(function() {
    clearTimeouts();
    popover.hide();
    hideTimeout.current = window.setTimeout(function() {
      globalState.hide(popover.baseId);
    }, timeout);
  }, [clearTimeouts, popover.hide, timeout, popover.baseId]);
  var show2 = useCallback27(function() {
    clearTimeouts();
    if (!timeout || globalState.currentTooltipId) {
      globalState.show(popover.baseId);
      popover.show();
    } else {
      globalState.show(null);
      showTimeout.current = window.setTimeout(function() {
        globalState.show(popover.baseId);
        popover.show();
      }, timeout);
    }
  }, [clearTimeouts, timeout, popover.show, popover.baseId]);
  useEffect21(function() {
    return globalState.subscribe(function(id) {
      if (id !== popover.baseId) {
        clearTimeouts();
        if (popover.visible) {
          popover.hide();
        }
      }
    });
  }, [popover.baseId, clearTimeouts, popover.visible, popover.hide]);
  useEffect21(function() {
    return function() {
      clearTimeouts();
      globalState.hide(popover.baseId);
    };
  }, [clearTimeouts, popover.baseId]);
  return _objectSpread23(_objectSpread23({}, popover), {}, {
    hide: hide3,
    show: show2,
    unstable_timeout: timeout,
    unstable_setTimeout: setTimeout2
  });
}

// src/Popover/__keys.ts
var POPOVER_STATE_KEYS2 = [
  "baseId",
  "unstable_idCountRef",
  "visible",
  "animated",
  "animating",
  "setBaseId",
  "show",
  "hide",
  "toggle",
  "setVisible",
  "setAnimated",
  "stopAnimation",
  "modal",
  "unstable_disclosureRef",
  "setModal",
  "unstable_referenceRef",
  "unstable_popoverRef",
  "unstable_arrowRef",
  "unstable_popoverStyles",
  "unstable_arrowStyles",
  "unstable_originalPlacement",
  "unstable_update",
  "placement",
  "place"
];
var POPOVER_KEYS2 = POPOVER_STATE_KEYS2;
var POPOVER_ARROW_KEYS2 = [...POPOVER_KEYS2, "size"];

// src/Popover/PopoverArrow.tsx
var PASTE_POPOVER_ARROW_KEYS = [...POPOVER_ARROW_KEYS2, "fill", "stroke"];
var usePopoverArrow = createHook({
  name: "PopoverArrow",
  compose: useBox,
  keys: PASTE_POPOVER_ARROW_KEYS,
  useOptions(_a) {
    var {size = 30, fill, stroke} = _a, options = __rest(_a, ["size", "fill", "stroke"]);
    return __assign({size, fill, stroke}, options);
  },
  useProps(options, _b) {
    var {ref: htmlRef, style: htmlStyle} = _b, htmlProps = __rest(_b, ["ref", "style"]);
    const [placement] = options.placement.split("-");
    const transformMap = {
      top: "rotateZ(180deg)",
      right: "rotateZ(-90deg)",
      bottom: "rotateZ(360deg)",
      left: "rotateZ(90deg)"
    };
    const {unstable_arrowStyles: arrowStyles, fill, stroke} = options;
    const transform = transformMap[placement];
    const children = useMemo8(() => /* @__PURE__ */ createElement7("svg", {
      viewBox: "0 0 30 30",
      style: {transform}
    }, /* @__PURE__ */ createElement7("path", {
      fill,
      stroke,
      d: "M23.7,27.1L17,19.9C16.5,19.3,15.8,19,15,19s-1.6,0.3-2.1,0.9l-6.6,7.2C5.3,28.1,3.4,29,2,29h26\n        C26.7,29,24.6,28.1,23.7,27.1z"
    }), /* @__PURE__ */ createElement7("path", {
      fill,
      d: "M23,27.8c1.1,1.2,3.4,2.2,5,2.2h2H0h2c1.7,0,3.9-1,5-2.2l6.6-7.2c0.7-0.8,2-0.8,2.7,0L23,27.8L23,27.8z"
    })), [transform]);
    return __assign({
      ref: useForkRef(options.unstable_arrowRef, htmlRef),
      style: __assign(__assign(__assign({}, arrowStyles), {
        fontSize: options.size,
        width: "1em",
        height: "1em",
        pointerEvents: "none",
        [placement]: "100%"
      }), htmlStyle),
      children
    }, htmlProps);
  }
});
var PopoverArrow = createComponent({
  as: "div",
  memo: true,
  useHook: usePopoverArrow
});

// src/Tooltip/__keys.ts
var TOOLTIP_STATE_KEYS2 = [
  "baseId",
  "unstable_idCountRef",
  "visible",
  "animated",
  "animating",
  "setBaseId",
  "show",
  "hide",
  "toggle",
  "setVisible",
  "setAnimated",
  "stopAnimation",
  "unstable_disclosureRef",
  "unstable_referenceRef",
  "unstable_popoverRef",
  "unstable_arrowRef",
  "unstable_popoverStyles",
  "unstable_arrowStyles",
  "unstable_originalPlacement",
  "unstable_update",
  "placement",
  "place",
  "unstable_timeout",
  "unstable_setTimeout"
];
var TOOLTIP_KEYS2 = [...TOOLTIP_STATE_KEYS2, "unstable_portal"];
var TOOLTIP_ARROW_KEYS2 = TOOLTIP_STATE_KEYS2;

// src/Tooltip/TooltipArrow.tsx
var useTooltipArrow = createHook({
  name: "TooltipArrow",
  compose: usePopoverArrow,
  keys: TOOLTIP_ARROW_KEYS2,
  useOptions(_a) {
    var {size = 16} = _a, options = __rest(_a, ["size"]);
    return __assign({size}, options);
  }
});
var TooltipArrow = createComponent({
  as: "div",
  memo: true,
  useHook: useTooltipArrow
});
export {
  Composite,
  CompositeGroup,
  CompositeItem,
  Disclosure,
  DisclosureContent,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuItemCheckbox,
  MenuItemRadio,
  MenuSeparator,
  Popover,
  PopoverArrow,
  PopoverDisclosure,
  Portal,
  Tab,
  TabList,
  TabPanel,
  Tooltip,
  TooltipArrow,
  TooltipReference,
  useCompositeState,
  useDisclosureState,
  useMenuState,
  usePopoverState,
  useTabState,
  useTooltipState
};
